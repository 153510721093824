import InputEntity from '@/lib/formFactory/inputEntity';
import ImageInputEntity from '@/lib/formFactory/imageInputEntity';
import InputDatePickerEntity from '@/lib/formFactory/inputDatePickerEntity';
import InputTimePickerEntity from '@/lib/formFactory/inputTimePickerEntity';
import CheckboxEntity from '@/lib/formFactory/checkboxEntity';
import RadioEntity from '@/lib/formFactory/radioEntity';
import InputSuggestionsEntity from '@/lib/formFactory/inputSuggestionsEntity';
import InputSuggestionMultipleEntity from '@/lib/formFactory/inputSuggestionsMultipleEntity';
import { CatalogModelInterface } from '@/interfaces/models/catalog.interface';

export enum AuctionTypeEnum {
    AutoInOrder = 'auto_in_order',
    AutoOneTime = 'auto_one_time',
    Manual = 'manual',
}

export default class EntityModel {
    model: CatalogModelInterface;

    constructor() {
        const nameEntity = new InputEntity('name');
        nameEntity.setTitle('Название каталога');
        nameEntity.setRequired(true);
        nameEntity.initLocalization();

        const dateStartEntity = new InputDatePickerEntity('dateStart');
        dateStartEntity.setRequired(true);

        const timeStartEntity = new InputTimePickerEntity('time');
        dateStartEntity.setRequired(true);

        const countryEntity = new InputSuggestionsEntity('country');
        countryEntity.setTitle('Страна');
        countryEntity.setValidation({
            empty: 'Поле не заполнено',
        });
        countryEntity.setRequired(true);

        const cityEntity = new InputSuggestionsEntity('city');
        cityEntity.setTitle('Город');
        cityEntity.setValidation({
            empty: 'Поле не заполнено',
        });
        cityEntity.setRequired(true);

        const addressEntity = new InputEntity('address');
        addressEntity.setTitle('Адрес');
        addressEntity.setSize(512);
        addressEntity.initLocalization();

        const sellerAuctionHouseEntity = new InputSuggestionsEntity('sellerAuctionHouse');
        sellerAuctionHouseEntity.setTitle('Название аукционного дома');
        sellerAuctionHouseEntity.setValidation({
            empty: 'Поле не заполнено',
        });
        sellerAuctionHouseEntity.setRequired(true);

        const descriptionEntity = new InputEntity('description');
        descriptionEntity.setTitle('Описание');
        descriptionEntity.setSize(512);
        descriptionEntity.initLocalization();

        const slugEntity = new InputEntity('slug');
        slugEntity.setTitle('Slug');

        const photoEntity = new ImageInputEntity('photo');
        photoEntity.setTitle('Заглавное фото');
        photoEntity.setRequired(true);

        const coverPhotoEntity = new ImageInputEntity('coverPhoto');
        coverPhotoEntity.setTitle('Обложка');
        coverPhotoEntity.setRequired(true);

        const isCorrespondenceBiddingEntity = new CheckboxEntity('isCorrespondenceBidding');
        isCorrespondenceBiddingEntity.setList({
            0: {
                id: 1,
                name: 'Заочные торги',
                checked: false,
            },
        });

        const isPostAuctionSaleEnity = new CheckboxEntity('isPostAuctionSaleHours');
        isPostAuctionSaleEnity.setList({
            0: {
                id: 1,
                name: 'Постаукционная продажа лотов',
                checked: false,
            },
        });

        const postAuctionSaleHoursEnity = new InputEntity('postAuctionSaleHours');
        postAuctionSaleHoursEnity.setPlaceholder('Укажите срок торгов');
        postAuctionSaleHoursEnity.setRequired(true);

        const auctionTypeEntity = new RadioEntity('auctionType');
        auctionTypeEntity.setTitle('Вид торгов');
        auctionTypeEntity.setList([
            { id: 0, name: 'Автоматические по порядку', value: AuctionTypeEnum.AutoInOrder },
            { id: 1, name: 'Автоматические одновременно', value: AuctionTypeEnum.AutoOneTime },
            { id: 2, name: 'С ведущим', value: AuctionTypeEnum.Manual },
        ]);
        auctionTypeEntity.setRequired(true);

        const oneLotTradeTimeSecondsInOrderEntity = new InputEntity('oneLotTradeTimeSecondsInOrder');
        oneLotTradeTimeSecondsInOrderEntity.setPlaceholder('');
        oneLotTradeTimeSecondsInOrderEntity.setRequired(true);

        const renewalTimeSecondsInOrderEntity = new InputEntity('renewalTimeSecondsInOrder');
        renewalTimeSecondsInOrderEntity.setPlaceholder('');
        renewalTimeSecondsInOrderEntity.setRequired(true);

        const oneLotTradeTimeSecondsOneTimeEntity = new InputEntity('oneLotTradeTimeSecondsOneTime');
        oneLotTradeTimeSecondsOneTimeEntity.setPlaceholder('');
        oneLotTradeTimeSecondsOneTimeEntity.setRequired(true);

        const renewalTimeSecondsOneTimeEntity = new InputEntity('renewalTimeSecondsOneTime');
        renewalTimeSecondsOneTimeEntity.setPlaceholder('');
        renewalTimeSecondsOneTimeEntity.setRequired(true);

        const currencyEntity = new InputSuggestionMultipleEntity('currency');
        currencyEntity.setTitle('Валюты лотов');
        currencyEntity.setValidation({
            empty: 'Поле не заполнено',
        });

        const exchangeRateTypeEntity = new RadioEntity('exchangeRateType');
        exchangeRateTypeEntity.setList([
            { id: 0, name: 'Индикатив', value: 'indicative' },
            { id: 1, name: 'По курсу ЦБ', value: 'central_bank' },
        ]);

        const streamUrlEntity = new InputEntity('streamUrl');
        streamUrlEntity.setTitle('Ссылка на стрим');

        const lotIntervalEntity = new InputEntity('lotInterval');
        lotIntervalEntity.setTitle('Интервал завершения торгов');

        this.model = {
            name: nameEntity.entity[nameEntity.entityName],
            country: countryEntity.entity[countryEntity.entityName],
            city: cityEntity.entity[cityEntity.entityName],
            address: addressEntity.entity[addressEntity.entityName],
            sellerAuctionHouse: sellerAuctionHouseEntity.entity[sellerAuctionHouseEntity.entityName],
            description: descriptionEntity.entity[descriptionEntity.entityName],
            slug: slugEntity.entity[slugEntity.entityName],
            photo: photoEntity.entity[photoEntity.entityName],
            coverPhoto: coverPhotoEntity.entity[coverPhotoEntity.entityName],
            dateTimeFinishTrad: '',
            dateTimePosting: '',
            dateStart: dateStartEntity.entity[dateStartEntity.entityName],
            timeStart: timeStartEntity.entity[timeStartEntity.entityName],
            isCorrespondenceBidding: isCorrespondenceBiddingEntity.entity[isCorrespondenceBiddingEntity.entityName],
            isPostAuctionSale: isPostAuctionSaleEnity.entity[isPostAuctionSaleEnity.entityName],
            postAuctionSaleHours: postAuctionSaleHoursEnity.entity[postAuctionSaleHoursEnity.entityName],
            auctionType: auctionTypeEntity.entity[auctionTypeEntity.entityName],
            currency: currencyEntity.entity[currencyEntity.entityName],
            exchangeRateType: exchangeRateTypeEntity.entity[exchangeRateTypeEntity.entityName],
            streamUrl: streamUrlEntity.entity[streamUrlEntity.entityName],
            oneLotTradeTimeSeconds: {
                auto_in_order:
                    oneLotTradeTimeSecondsInOrderEntity.entity[oneLotTradeTimeSecondsInOrderEntity.entityName],
                auto_one_time:
                    oneLotTradeTimeSecondsOneTimeEntity.entity[oneLotTradeTimeSecondsOneTimeEntity.entityName],
                disabled: false,
            },
            renewalTimeSeconds: {
                auto_in_order: renewalTimeSecondsInOrderEntity.entity[renewalTimeSecondsInOrderEntity.entityName],
                auto_one_time: renewalTimeSecondsOneTimeEntity.entity[renewalTimeSecondsOneTimeEntity.entityName],
                disabled: false,
            },
            status: '',
            lotInterval: lotIntervalEntity.entity[lotIntervalEntity.entityName],
            exchangeRates: {
                values: {},
                disabled: false,
            },
        };
    }
}
