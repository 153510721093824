import { InputSuggestionMultiple, InputSuggestionMultipleValidation } from './inputSuggestionMultiple.interface';
export default class InputSuggestionMultipleEntity {
    defaultValue: InputSuggestionMultiple;
    entityName: string;
    entity: { [key: string]: InputSuggestionMultiple };

    constructor(name: string) {
        this.defaultValue = {
            key: name,
            type: 'input-suggestion',
            title: '',
            name: name,
            placeholder: '',
            value: '',
            selected: [],
            current: {},
            hintsList: [],
            required: true,
            size: 200,
            disabled: false,
            error: {
                class: '',
                message: '',
            },
            validation: {
                empty: 'Поле не заполнено',
            },
            autocomplete: 'chrome-off',
        };
        this.entityName = name;
        this.entity = { [this.entityName]: this.defaultValue };
    }

    setType(type: string): void {
        this.entity[this.entityName]['type'] = type;
    }

    setTitle(title: string): void {
        this.entity[this.entityName]['title'] = title;
    }

    setPlaceholder(placeholder: string): void {
        this.entity[this.entityName]['placeholder'] = placeholder;
    }

    setRequired(required: boolean): void {
        this.entity[this.entityName]['required'] = required;
    }

    setValidation(validation: InputSuggestionMultipleValidation): void {
        this.entity[this.entityName]['validation'] = validation;
    }

    setActionSearch(actionsSearch: string): void {
        this.entity[this.entityName]['actionSearch'] = actionsSearch;
    }

    setDisabled(disabled: boolean): void {
        this.entity[this.entityName]['disabled'] = disabled;
    }
}
