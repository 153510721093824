import { Module, Action, Mutation, getModule } from 'vuex-module-decorators';
import EntityBaseModule from '@/admin/store/entity';
import EntityModel from './entityModel';
import { modalParams } from './entityModalParams';

import store from '@/admin/store';
import { getAuctionHouseCard, saveCard, createCard } from '@/admin/api/auctionHouse';
import ResponseHandlerModule from '@/admin/store/modules/responseHandler';
import {
    clearPhone,
    formatCreatedAt,
    formatNumberSpaced,
    formatPhone,
    formatRemoveSpaces,
    getApiErrorMessage,
    imageFileToBase64,
    imageUrlToFile,
    showError,
} from '@/lib/utils/Utils';
import { TableApiInterface, TableInterface, TableRows } from '@/lib/layouts/page/table.interface';
import RedirectHandlerModule from '@/admin/store/modules/redirectHandler';
import { getCountriesList } from '@/admin/api/country';
import { getSettingAuction, SettingsSectionInterface } from '@/admin/api/settingAuction';
import { BidStepsFromStartPrice } from '@/interfaces/settingsAuctions';

import SiteModule from '@/admin/store/site';
import { prepareLocalization, processLocalizationFromBackend } from '@/lib/utils/localization';
import { AuctionHouseModelInterface } from '@/interfaces/models/auctionHouse.interface';
import { InputSuggestionItem } from '@/lib/formFactory/inputSuggestion.interface';
import { Input } from '@/lib/formFactory/input.interface';

export const MODULE_NAME = 'auctionHouse';

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class AuctionEHouseEntityModule extends EntityBaseModule {
    cardId: number | null = null;
    model: AuctionHouseModelInterface;
    modalParams: any;
    auctionHouseName = '';
    titleReturnData = {
        edit: {
            icon: 'store',
            title: this.auctionHouseName,
        },
        create: {
            icon: '',
            title: 'Добавление АД',
        },
    };
    selectDefault = { id: 0, value: '' };
    tableTitlesDict: { [key: string]: string } = {
        from: 'От',
        input_to: 'До',
        input_plainStep: 'Фикс',
        input_percentStep: '%',
        _actions: 'add',
    };
    tableTitlesNames = [
        'id',
        'from',
        'input_to',
        'is_input_plainStep',
        'input_plainStep',
        'is_input_percentStep',
        'input_percentStep',
        '_actions',
    ];
    table: TableInterface | null = null;

    constructor(module: AuctionEHouseEntityModule) {
        super(module);

        const entityModel = new EntityModel();
        this.model = entityModel.model;
        this.modalParams = modalParams;
    }

    @Mutation
    SET_CARD_ID(id: number | null) {
        this.cardId = id;
    }

    @Mutation
    SET_AUCTION_HOUSE_NAME(name: string) {
        this.auctionHouseName = name;
        this.titleReturnData.edit.title = name;
    }

    @Mutation
    SET_EMAIL(email: string) {
        this.model.email.value = email;
    }

    @Mutation
    SET_PHONE(phone: string) {
        this.model.phone.value = phone;
    }

    @Mutation
    SET_SITE(phone: string) {
        this.model.site.value = phone;
    }

    @Mutation
    SET_SORT_ORDER(sort: string) {
        this.model.sort.value = sort;
    }

    @Mutation
    SET_COMMISSION_SITE(value: string) {
        this.model.commissionSite.value = value;
    }

    @Mutation
    SET_COMMISSION_AUCTION_HOUSE(value: string) {
        this.model.commissionAuctionHouse.value = value;
    }

    @Mutation
    SET_STATUS(selected: { id: string; value: string }) {
        this.model.status.current = selected;
    }

    @Mutation
    SET_MODAL_IS_SHOW(params: { key: string; bool: boolean }) {
        this.modalParams[params.key].isShow = params.bool;
    }

    @Mutation
    SET_PHOTO(url: string) {
        this.model.photo.value = url;
    }

    @Mutation
    SET_PHOTO_FILE(file: File | null) {
        this.model.photo.file = file;
    }

    @Mutation
    SET_PHOTO_PREVIEW(base64String: string) {
        this.model.photo.preview = base64String;
    }

    @Mutation
    SET_IS_POSTAUCTION_SALE(bool: boolean) {
        this.model.isPostauctionSale.list[0].checked = bool;
    }

    @Mutation
    SET_POSTAUCTION_SALE_OF_LOTS_HOURS(value: string) {
        this.model.postAuctionSaleOfLotsHours.value = value;
    }

    @Mutation
    SET_IS_SALE_HOURS_REQUIRED(bool: boolean) {
        this.model.postAuctionSaleOfLotsHours.required = bool;
    }

    @Mutation
    SET_AUCTION_PUBLISH_PRICE(value: string) {
        this.model.auctionPublishPrice.value = value;
    }

    @Mutation
    SET_NEWBIE_MONEY_LIMIT_TYPE(value: string) {
        this.model.newbieMoneyLimitType.value = value;
    }

    @Mutation
    SET_NEWBIE_MONEY_LIMIT(value: string) {
        this.model.newbieMoneyLimit.value = value;
    }

    @Mutation
    SET_TABLE(table: TableInterface) {
        this.table = table;
    }

    @Mutation
    SET_TABLE_ROWS(rows: TableRows) {
        if (this.table) {
            this.table.rows = rows;
        }
    }

    @Mutation
    SET_NEWBIE_AUTO_APPROVE(bool: boolean) {
        this.model.newbieAutoApprove.list[0].checked = bool;
    }

    @Action({ rawError: true })
    async initForm(auctionHouseId: string | null) {
        await this.initCurrencies();

        if (!auctionHouseId) {
            this.initTable();
            this.SET_IS_POSTAUCTION_SALE(true);

            const result = await getSettingAuction('?section=CommonAuction&name=postAuctionSaleOfLotsHours');
            this.SET_POSTAUCTION_SALE_OF_LOTS_HOURS(result[0].value as string);
        }
    }

    @Action({ rawError: true })
    async initCardNameById(auctionHouseId: string) {
        const result = await getAuctionHouseCard(auctionHouseId);

        this.context.commit('SET_AUCTION_HOUSE_NAME', result.name);
    }

    @Action({ rawError: true })
    async initCardById(auctionHouseId: number | string) {
        try {
            this.updateIsLoading(true);
            await this.initForm(String(auctionHouseId));
            const result = await getAuctionHouseCard(auctionHouseId);

            await this.setInfo(result);
            this.updateIsLoading(false);
        } catch (error) {
            showError(getApiErrorMessage(error));
        }
    }

    @Action({ rawError: true })
    async setInfo(data: any) {
        if (!data) {
            return;
        }

        this.context.commit('SET_CARD_ID', data.id);
        this.context.commit('SET_AUCTION_HOUSE_NAME', data.name);
        this.context.commit('SET_ADDRESS', data.address ? data.address : '');
        this.context.commit('SET_NAME', data.name);
        this.context.commit('SET_SITE', data.site ? data.site : '');
        this.context.commit('SET_PHONE', data.phone ? formatPhone(data.phone) : '');
        this.context.commit('SET_EMAIL', data.email ? data.email : '');
        this.context.commit('SET_SORT_ORDER', data.sort ? data.sort : '');
        this.context.commit('SET_COMMISSION_SITE', data.commissionSite ? data.commissionSite : 0);
        this.context.commit(
            'SET_COMMISSION_AUCTION_HOUSE',
            data.commissionAuctionHouse ? data.commissionAuctionHouse : '',
        );
        this.context.commit('SET_PHOTO', data.photo ? data.photo : '');
        this.context.commit('SET_CREATED_AT', data.createdAt ? formatCreatedAt(data.createdAt) : '');
        this.context.commit('SET_AUCTION_PUBLISH_PRICE', data.auctionPublishPrice ? data.auctionPublishPrice : 0);
        this.context.commit('SET_IS_POSTAUCTION_SALE', data.isPostauctionSale ?? false);
        this.context.commit(
            'SET_POSTAUCTION_SALE_OF_LOTS_HOURS',
            data.postAuctionSaleOfLotsHours ? data.postAuctionSaleOfLotsHours : '',
        );

        if (data.city) {
            const country = data.city.country;

            this.updateSuggestionsSelectedByType({
                selected: data.city.country?.id ? { id: country.id, value: country.name } : this.selectDefault,
                type: 'country',
            });

            this.updateSuggestionsSelectedByType({
                selected: data.city.id ? { id: data.city.id, value: data.city.name } : this.selectDefault,
                type: 'city',
            });
        }

        if (data.status) {
            this.SET_STATUS({ id: data.status, value: '' });
        }

        if (data.stepsFromOriginalCost?.length > 0) {
            this.context.commit('SET_TABLE', await this.prepareTable(data.stepsFromOriginalCost));
            this.updateTableRows(this.table?.rows);
        }

        if (data.newbieMoneyLimit) {
            this.SET_NEWBIE_MONEY_LIMIT(data.newbieMoneyLimit);
            this.SET_NEWBIE_MONEY_LIMIT_TYPE('custom');
        }

        this.updateIsSaleHoursRequired();

        if (!data.stepsFromOriginalCost) {
            this.initTable();
        }

        this.SET_BALANCE(data.balance);
        this.SET_AVG_LOT_VALUE(data.avgLotValue ?? 0);
        this.SET_AVG_RECEIVED_FROM_LOT(data.avgReceivedFromLot ?? 0);
        this.SET_CATALOGS_PLACED(data.catalogsPlaced ?? 0);
        this.SET_LOTS_PLACED(data.lotsPlaced ?? 0);
        this.SET_TOTAL_VALUE_LOTS(data.totalValueLots ?? 0);
        this.SET_SOLD_LOTS(data.soldLots ?? 0);

        this.SET_TOP_SELLER(data.topSeller);
        this.SET_NEWBIE_AUTO_APPROVE(data.newbieAutoApprove);

        Object.keys(data)
            .filter((key) => key.indexOf('bill') === 0 && key !== 'billLogo')
            .forEach((key) => {
                this.SET_MODEL_FIELD_VALUE({ field: key, value: data[key] });
            });
        this.SET_MODEL_IMAGE_FIELD_URL({ field: 'billLogo', url: data.billLogo ?? '' });

        this.SET_LOCALIZATION_BY_FIELD({
            fieldName: 'name',
            data: processLocalizationFromBackend(data),
        });

        this.SET_LOCALIZATION_BY_FIELD({
            fieldName: 'address',
            data: processLocalizationFromBackend(data, 'address'),
        });

        this.SET_LOCALIZATION_BY_FIELD({
            fieldName: 'terms',
            data: processLocalizationFromBackend(data, 'terms'),
        });

        this.SET_LOCALIZATION_BY_FIELD({
            fieldName: 'regulations',
            data: processLocalizationFromBackend(data, 'regulations'),
        });

        this.SET_SLUG(data.slug || '');
    }

    @Action({ rawError: true })
    getTableTitles() {
        const hiddenColumns = ['id', 'is_input_plainStep', 'is_input_percentStep'];
        const tableTitles = this.tableTitlesNames.map((name) => {
            return {
                id: name,
                name: this.tableTitlesDict[name] ?? name,
                visible: hiddenColumns.includes(name) ? false : true,
            };
        });

        return { hiddenColumns, tableTitles };
    }

    @Action({ rawError: true })
    async initTable() {
        const result: SettingsSectionInterface[] = await getSettingAuction(
            '?section=CommonAuction&name=bidStepsFromStartPrice',
        );
        console.info('initTable', result);

        const bidStepsSettings = typeof result[0].value === 'string' ? JSON.parse(result[0].value) : result[0].value;

        const rows = bidStepsSettings?.length
            ? bidStepsSettings.map((step: BidStepsFromStartPrice, index: number) => {
                  return {
                      id: step.id,
                      from: formatNumberSpaced(step.from) || 0,
                      input_to: formatNumberSpaced(step.to) || '',
                      is_input_plainStep: step.fix.checked,
                      input_plainStep: formatNumberSpaced(step.fix.value) || 0,
                      is_input_percentStep: step.percent.checked,
                      input_percentStep: formatNumberSpaced(step.percent.value) || 0,
                      _actions: index !== 0 ? ['delete'] : [],
                  };
              })
            : [
                  {
                      id: 0,
                      from: 0,
                      input_to: 1,
                      is_input_plainStep: true,
                      input_plainStep: 0,
                      is_input_percentStep: false,
                      input_percentStep: 0,
                      _actions: [],
                  },
              ];

        const { hiddenColumns, tableTitles } = await this.getTableTitles();

        const table = {
            titles: tableTitles,
            rows: rows,
            sortFields: [],
            pagination: null,
            totalItems: 1,
            filters: {},
            hiddenColumns: hiddenColumns,
        } as TableInterface;

        this.context.commit('SET_TABLE', table);
    }

    @Action({ rawError: true })
    async prepareTable(data: { from?: number; to: number; plainStep?: number; percentStep?: number }[]) {
        const { hiddenColumns, tableTitles } = await this.getTableTitles();

        const rows: any = data.map((item, index: number) => {
            const fromValue = item.from ? item.from : 0;
            const row = {
                id: index,
                from: index === 0 ? { value: fromValue } : fromValue,
                input_to: item.to ? item.to : '',
                is_input_plainStep: item.plainStep ? true : false,
                input_plainStep: item.plainStep ? item.plainStep : 0,
                is_input_percentStep: item.percentStep ? true : false,
                input_percentStep: item.percentStep ? item.percentStep : 0,
                _actions: index !== 0 ? ['delete'] : [],
            };

            return row;
        });

        return {
            titles: tableTitles,
            rows: rows,
            sortFields: [],
            pagination: null,
            totalItems: data.length,
            filters: {},
            hiddenColumns: hiddenColumns,
        } as TableInterface;
    }

    @Action({ rawError: true })
    deleteStep(id: number) {
        if (!this.table?.rows) {
            return;
        }

        let index = 0;
        let deletedStepFromValue: any = 0;
        const rows: any[] = [];
        Object.values(this.table.rows).forEach((row, rowIndex) => {
            if (rowIndex === id) {
                deletedStepFromValue = row.from;

                return;
            }

            if (deletedStepFromValue) {
                row.from = deletedStepFromValue;
                deletedStepFromValue = 0;
            }

            row.id = index;
            rows.push(row);
            index++;
        });

        this.updateTableRows(rows);
    }

    @Action({ rawError: true })
    addStep() {
        if (!this.table?.rows) {
            return;
        }

        let rowsArray = Object.values(this.table.rows);
        const lastRow = rowsArray[rowsArray.length - 1];

        if (lastRow && lastRow.input_to === '') {
            lastRow['input_to'] = +lastRow['from'] + 1 || 1;
            rowsArray[rowsArray.length - 1] = lastRow;
        }

        rowsArray = [
            ...rowsArray,
            {
                id: (lastRow.id as number) + 1,
                from: 0,
                input_to: '',
                is_input_plainStep: true,
                input_plainStep: 0,
                is_input_percentStep: false,
                input_percentStep: 0,
                _actions: ['delete'],
            },
        ];

        this.updateTableRows(rowsArray);
    }

    @Action({ rawError: true })
    updateTableRows(rows: any[] | undefined) {
        if (rows == null) {
            return;
        }

        let prevRow: any;
        const rowsArray = rows.map((row, index, tableRows) => {
            let fromValue = +formatRemoveSpaces(String(typeof row.from === 'object' ? row.from.value : row.from));
            let toValue = +formatRemoveSpaces(String(row.input_to));

            if (prevRow) {
                fromValue = +prevRow.input_to + 1;
            }

            if (index === 0) {
                if (fromValue >= toValue) {
                    toValue = fromValue + 1;
                }

                prevRow = {
                    ...row,
                    from: fromValue,
                    input_to: toValue,
                };

                return {
                    ...row,
                    from: { value: formatNumberSpaced(fromValue) },
                    input_to: formatNumberSpaced(toValue),
                };
            }

            const isLastRow = index === tableRows.length - 1;
            if (!isLastRow) {
                toValue = fromValue >= toValue ? fromValue + 1 : toValue;
            }

            row['_actions'] = index === 0 ? [] : ['delete'];

            prevRow = {
                ...row,
                from: fromValue,
                input_to: toValue,
            };

            return {
                ...row,
                from: formatNumberSpaced(fromValue),
                input_to: formatNumberSpaced(toValue),
            };
        });

        this.context.commit('SET_TABLE_ROWS', rowsArray);
    }

    @Action({ rawError: true })
    updateName(params: string) {
        if (params !== '') {
            this.context.commit('RESET_ERROR_BY_FIELD', 'name');
        }
        this.context.commit('SET_NAME', params);
    }

    @Action({ rawError: true })
    updateSite(site: string) {
        this.context.commit('SET_SITE', site);
    }

    @Action({ rawError: true })
    updatePhone(phone: string) {
        this.context.commit('SET_PHONE', phone);
    }

    @Action({ rawError: true })
    updateEmail(email: string) {
        this.context.commit('SET_EMAIL', email);
    }

    @Action({ rawError: true })
    updateStatus(selected: { id: number; value: string }) {
        this.context.commit('SET_STATUS', selected);
    }

    @Action({ rawError: true })
    updateSortOrder(sort: string) {
        this.context.commit('SET_SORT_ORDER', sort);
    }

    @Action({ rawError: true })
    updateCommissionSite(value: string) {
        this.RESET_ERROR_BY_FIELD('commissionSite');
        this.context.commit('SET_COMMISSION_SITE', value);
    }

    @Action({ rawError: true })
    updateCommissionAuctionHouse(value: string) {
        this.RESET_ERROR_BY_FIELD('commissionAuctionHouse');
        this.context.commit('SET_COMMISSION_AUCTION_HOUSE', value);
    }

    @Action({ rawError: true })
    toggleModal(params: { key: string; bool: boolean }) {
        this.context.commit('SET_MODAL_IS_SHOW', params);
    }

    @Action({ rawError: true })
    async updatePhotoFile(file: File | string) {
        const toFile = typeof file === 'string' ? await imageUrlToFile(file as string) : file;

        this.context.commit('SET_PHOTO_FILE', toFile);

        const preview = await imageFileToBase64(toFile);

        this.context.commit('SET_PHOTO_PREVIEW', preview);
    }

    @Action({ rawError: true })
    updateSlug(slug: string) {
        this.SET_SLUG(slug);
    }

    @Action({ rawError: true })
    removeImagePreview() {
        this.context.commit('SET_PHOTO_FILE', null);
        this.context.commit('SET_PHOTO_PREVIEW', '');
    }

    @Action({ rawError: true })
    removeImage() {
        this.context.commit('SET_PHOTO', '');
    }

    @Action({ rawError: true })
    updateIsPostAuctionSale(bool: boolean) {
        this.context.commit('SET_IS_POSTAUCTION_SALE', bool);
        this.updateIsSaleHoursRequired();
    }

    @Action({ rawError: true })
    updatePostAuctionSaleOfLotsHours(value: string) {
        this.RESET_ERROR_BY_FIELD('postAuctionSaleOfLotsHours');
        this.context.commit('SET_POSTAUCTION_SALE_OF_LOTS_HOURS', value);
    }

    @Action({ rawError: true })
    updateIsSaleHoursRequired() {
        this.SET_IS_SALE_HOURS_REQUIRED(this.model.isPostauctionSale.list[0].checked);
    }

    @Action({ rawError: true })
    updateNewbieMoneyLimitType(value: string) {
        this.context.commit('SET_NEWBIE_MONEY_LIMIT_TYPE', value);
    }

    @Action({ rawError: true })
    updateNewbieMoneyLimit(value: string) {
        this.context.commit('SET_NEWBIE_MONEY_LIMIT', value);
    }

    @Action({ rawError: true })
    updateAuctionPublishPrice(value: string) {
        this.RESET_ERROR_BY_FIELD('auctionPublishPrice');
        this.context.commit('SET_AUCTION_PUBLISH_PRICE', value);
    }

    @Action({ rawError: true })
    updateNewbieAutoApprove(bool: boolean | null) {
        this.context.commit('SET_NEWBIE_AUTO_APPROVE', bool);
    }

    @Action({ rawError: true })
    updateStepType(params: { id: number; value: string }) {
        if (!this.table?.rows) {
            return;
        }

        const rows = this.table?.rows
            ? Object.values(this.table?.rows).map((row, index) => {
                  if (index !== params.id) {
                      return row;
                  }

                  if (params.value === 'plainStep') {
                      row['is_input_plainStep'] = true;
                      row['is_input_percentStep'] = false;
                  }

                  if (params.value === 'percentStep') {
                      row['is_input_percentStep'] = true;
                      row['is_input_plainStep'] = false;
                  }

                  return row;
              })
            : [];

        if (!rows) {
            return;
        }

        this.updateTableRows(rows);
    }

    @Action({ rawError: true })
    updateStepValue(params: { id: number; value: number }) {
        if (!this.table?.rows) {
            return;
        }

        const rows = this.table?.rows
            ? Object.values(this.table?.rows).map((row, index) => {
                  if (index !== params.id) {
                      return row;
                  }

                  if (row['is_input_plainStep'] === true) {
                      row['input_plainStep'] = formatNumberSpaced(params.value);
                  }

                  if (row['is_input_percentStep'] === true) {
                      row['input_percentStep'] = formatNumberSpaced(params.value);
                  }

                  return row;
              })
            : [];

        if (!rows) {
            return;
        }

        this.updateTableRows(rows);
    }

    @Action({ rawError: true })
    updateToValue(params: { id: number; value: string }) {
        if (!this.table?.rows) {
            return;
        }

        const rows = [...this.table.rows].map((row) => {
            if (row.id !== params.id) {
                return row;
            }

            row.input_to = params.value.length ? formatNumberSpaced(params.value) : params.value;

            return row;
        });

        if (!rows.length) {
            return;
        }

        this.SET_TABLE_ROWS(rows);
    }

    @Action({ rawError: true })
    updateFromValue(params: { id: number; value: string }) {
        if (!this.table?.rows) {
            return;
        }

        const rows = [...this.table.rows].map((row) => {
            if (row.id !== params.id) {
                return row;
            }

            (row.from as { value: string }).value = params.value.length
                ? formatNumberSpaced(params.value)
                : params.value;

            return row;
        });

        if (!rows.length) {
            return;
        }

        this.SET_TABLE_ROWS(rows);
    }

    @Action({ rawError: true })
    updateFromValueChange(params: { id: number; value: number }) {
        if (!this.table?.rows) {
            return;
        }

        const rows = [...this.table.rows].map((row) => {
            const result = {
                ...row,
                from: {
                    value: params.id === row.id ? params.value : row.input_to,
                },
            };

            return result;
        });

        if (!rows.length) {
            return;
        }

        this.updateTableRows(rows);
    }

    @Action({ rawError: true })
    updateToValueChange(params: { id: number; value: number }) {
        if (!this.table?.rows) {
            return;
        }

        const rows = [...this.table.rows].map((row) => {
            const result = {
                ...row,
                input_to: params.id === row.id ? params.value : row.input_to,
            };

            return result;
        });

        if (!rows.length) {
            return;
        }

        this.updateTableRows(rows);
    }

    @Action({ rawError: true })
    async initCountry() {
        const list: TableApiInterface = await getCountriesList(
            1,
            '',
            'filters[0][id]=withCities&filters[0][value]=true',
            '&items=999',
        );
        const newList = Object.values(list.rows).map((item) => {
            return {
                id: item.id,
                value: item.name,
            };
        });

        return newList;
    }

    @Action({ rawError: true })
    async initCurrencies() {
        this.context.commit('SET_NEWBIE_MONEY_LIMIT_TYPE', 'default');
    }

    @Action({ rawError: true })
    async sendEdit() {
        const isError = await this.validate();

        if (isError === true) {
            ResponseHandlerModule.showNotify({ message: 'Заполните обязательные поля', type: 'fail' });

            return;
        }

        SiteModule.SET_IS_BLOCK(true);

        try {
            const formData = await this.prepareRequestData();

            let result: any;

            if (this.cardId != null) {
                result = await saveCard(this.cardId, formData);
            }

            if (!result.message) {
                ResponseHandlerModule.showNotify({ message: 'Изменения сохранены', type: 'ok' });
                SiteModule.SET_IS_BLOCK(false);

                this.SET_MODEL_IMAGE_FIELD_URL({ field: 'billLogo', url: result.billLogo });
                this.SET_MODEL_IMAGE_FIELD_FILE({ field: 'billLogo', file: null });
                this.SET_MODEL_IMAGE_FIELD_PREVIEW({ field: 'billLogo', base64String: '' });

                this.SET_MODEL_IMAGE_FIELD_URL({ field: 'photo', url: result.photo });
                this.SET_MODEL_IMAGE_FIELD_FILE({ field: 'photo', file: null });
                this.SET_MODEL_IMAGE_FIELD_PREVIEW({ field: 'photo', base64String: '' });

                return;
            }

            SiteModule.SET_IS_BLOCK(false);
            ResponseHandlerModule.showNotify({ message: result.message, type: 'fail' });
        } catch (error) {
            SiteModule.SET_IS_BLOCK(false);
            ResponseHandlerModule.showNotify({ message: getApiErrorMessage(error), type: 'fail' });
        }
    }

    @Action({ rawError: true })
    async sendAdd(redirect: any = null) {
        const isError = await this.validate();

        if (isError === true) {
            ResponseHandlerModule.showNotify({ message: 'Заполните обязательные поля', type: 'fail' });

            return;
        }

        SiteModule.SET_IS_BLOCK(true);

        try {
            const formData = await this.prepareRequestData();
            const result = await createCard(formData);

            if (!result.message) {
                SiteModule.SET_IS_BLOCK(false);
                ResponseHandlerModule.showNotify({ message: 'Изменения сохранены', type: 'ok' });
                RedirectHandlerModule.changeUrl(redirect);

                return;
            }

            ResponseHandlerModule.showNotify({ message: result.message, type: 'fail' });
            SiteModule.SET_IS_BLOCK(false);
        } catch (error) {
            ResponseHandlerModule.showNotify({ message: await getApiErrorMessage(error), type: 'fail' });
            SiteModule.SET_IS_BLOCK(false);
        }
    }

    @Action({ rawError: true })
    prepareRequestData() {
        let data: Record<string, string | number | File> = {
            name: this.model.name.localization?.ru || '',
            status: this.model.status.current.id,
            topSeller: this.model.topSeller.list[0].checked ? 1 : 0,
            newbieAutoApprove: +this.model.newbieAutoApprove.list[0].checked,
            slug: this.model.slug.value,
        };

        if ((this.model.city.current as InputSuggestionItem).id) {
            data.city = (this.model.city.current as InputSuggestionItem).id;
        }

        data = {
            ...data,
            ...prepareLocalization(this.model.name),
            ...prepareLocalization(this.model.address, 'address'),
            ...prepareLocalization(this.model.regulations, 'regulations'),
            ...prepareLocalization(this.model.terms, 'terms'),
        };

        if (this.model.regulations.localization?.ru) {
            data['regulations'] = this.model.regulations.localization.ru;
        }

        if (this.model.terms.localization?.ru) {
            data['terms'] = this.model.terms.localization.ru;
        }

        if (this.model.address.localization?.ru) {
            data['address'] = this.model.address.localization.ru;
        }

        if (this.model.site.value) {
            data['site'] = this.model.site.value;
        }

        if (this.model.email.value) {
            data['email'] = this.model.email.value;
        }

        if (this.model.phone.value) {
            data['phone'] = clearPhone(this.model.phone.value);
        }

        if (this.model.sort.value) {
            data['sort'] = +this.model.sort.value;
        }


        if (this.model.commissionSite.value || Number(this.model.commissionSite.value) === 0) {
            data['commissionSite'] = Number(this.model.commissionSite.value);
        }

        if (this.model.commissionAuctionHouse.value) {
            data['commissionAuctionHouse'] = Number(this.model.commissionAuctionHouse.value);
        }

        if (this.model.postAuctionSaleOfLotsHours.value) {
            data['postAuctionSaleOfLotsHours'] = +this.model.postAuctionSaleOfLotsHours.value;
        }

        data['isPostauctionSale'] = Number(this.model.isPostauctionSale.list[0].checked);

        if (this.model.newbieMoneyLimitType.value === 'custom') {
            data['newbieMoneyLimit'] = +this.model.newbieMoneyLimit.value;
        }

        if (this.model.auctionPublishPrice.value || Number(this.model.auctionPublishPrice.value) === 0) {
            data['auctionPublishPrice'] = Number(this.model.auctionPublishPrice.value);
        }

        const photo = this.model.photo.file ? this.model.photo.file : null;
        if (photo) {
            data['photo'] = photo;
        }

        if (this.table?.rows) {
            Object.values(this.table.rows).forEach((row, index) => {
                const fromValue = typeof row.from === 'object' ? (row.from as { value: string }).value : row.from;
                const stepType = row['is_input_plainStep'] ? 'plainStep' : 'percentStep';
                data[`stepsFromOriginalCost[${index}][${stepType}]`] = +formatRemoveSpaces(
                    row[`input_${stepType}`] as string,
                );
                data[`stepsFromOriginalCost[${index}][from]`] = +formatRemoveSpaces(fromValue as string) || 0;
                if (this.table && index !== Object.values(this.table.rows).length - 1) {
                    data[`stepsFromOriginalCost[${index}][to]`] = row['input_to']
                        ? +formatRemoveSpaces(row['input_to'] as string)
                        : 0;
                }
            });
        }

        Object.keys(this.model)
            .filter((key) => key.indexOf('bill') === 0 && key !== 'billLogo')
            .forEach((key) => {
                const typedKey = key as keyof AuctionHouseModelInterface;
                const typedField = this.model[typedKey] as Input;

                if (typedField?.value?.length) {
                    data[key] = typedField?.value;
                }
            });

        const billLogo = this.model.billLogo.file ?? null;
        if (billLogo) {
            data['billLogo'] = billLogo;
        }

        return data;
    }

    @Action({ rawError: true })
    async validate() {
        let fieldsWithError: string[] = [];
        let requiredFieldsNames: string[] = [];

        this.context.commit('RESET_ERROR_BY_FIELD', 'name');
        this.context.commit('RESET_ERROR_BY_FIELD', 'city');
        this.context.commit('RESET_ERROR_BY_FIELD', 'postAuctionSaleOfLotsHours');

        this.setValidateState({ index: 'name', field: this.model.name });
        this.setValidateSelect({ index: 'city', field: this.model.city });
        // this.setValidateState({ index: 'auctionPublishPrice', field: this.model.auctionPublishPrice });
        // this.setValidateState({ index: 'commissionSite', field: this.model.commissionSite });
        this.setValidateState({ index: 'commissionAuctionHouse', field: this.model.commissionAuctionHouse });

        if (this.model.postAuctionSaleOfLotsHours.required) {
            this.setValidateState({
                index: 'postAuctionSaleOfLotsHours',
                field: this.model.postAuctionSaleOfLotsHours,
            });
        }

        requiredFieldsNames = Object.entries(this.model)
            .filter((entry: any) => entry[1] != null && entry[1].required === true)
            .map((entry: any) => entry[0]);

        fieldsWithError = await this.getFieldsWithError(requiredFieldsNames);

        return fieldsWithError.length > 0 ? true : false;
    }

    @Action({ rawError: true })
    reset() {
        this.context.commit('SET_IS_LOADING', true);
        this.context.commit('SET_CREATED_AT', '');
        this.context.commit('SET_CARD_ID', null);
        this.context.commit('SET_ADDRESS', '');
        this.context.commit('SET_NAME', '');
        this.context.commit('SET_SITE', '');
        this.context.commit('SET_PHONE', '');
        this.context.commit('SET_EMAIL', '');
        this.context.commit('SET_SORT_ORDER', '');
        this.context.commit('SET_COMMISSION_SITE', '');
        this.context.commit('SET_COMMISSION_AUCTION_HOUSE', '');
        this.context.commit('SET_STATUS', this.selectDefault);
        this.context.commit('SET_PHOTO', '');
        this.context.commit('SET_PHOTO_FILE', null);
        this.context.commit('SET_PHOTO_PREVIEW', '');
        this.context.commit('SET_POSTAUCTION_SALE_OF_LOTS_HOURS', '');
        this.context.commit('SET_NEWBIE_MONEY_LIMIT_TYPE', '');
        this.context.commit('SET_NEWBIE_MONEY_LIMIT', '');
        this.context.commit('SET_AUCTION_PUBLISH_PRICE', '');
        this.context.commit('SET_NEWBIE_AUTO_APPROVE', false);

        this.resetSuggestionsStateByType('country');
        this.resetSuggestionsStateByType('city');

        this.SET_IS_POSTAUCTION_SALE(false);
        this.SET_IS_SALE_HOURS_REQUIRED(false);

        this.SET_BALANCE(null);
        this.SET_AVG_LOT_VALUE(null);
        this.SET_AVG_RECEIVED_FROM_LOT(null);
        this.SET_CATALOGS_PLACED(null);
        this.SET_LOTS_PLACED(null);
        this.SET_TOTAL_VALUE_LOTS(null);
        this.SET_SOLD_LOTS(null);

        this.SET_TOP_SELLER(false);
        this.SET_SLUG('');
        SiteModule.SET_IS_BLOCK(false);

        Object.keys(this.model)
            .filter((key) => key.indexOf('bill') === 0 && key !== 'billLogo')
            .forEach((key) => {
                this.SET_MODEL_FIELD_VALUE({ field: key, value: '' });
            });
        this.SET_MODEL_IMAGE_FIELD_URL({ field: 'billLogo', url: '' });
        this.SET_MODEL_IMAGE_FIELD_FILE({ field: 'billLogo', file: null });
        this.SET_MODEL_IMAGE_FIELD_PREVIEW({ field: 'billLogo', base64String: '' });

        this.RESET_LOCALIZATION_BY_FIELD();
        this.RESET_LOCALIZATION_BY_FIELD('address');
        this.RESET_LOCALIZATION_BY_FIELD('terms');
        this.RESET_LOCALIZATION_BY_FIELD('regulations');

        this.resetErrorState();
    }

    @Action({ rawError: true })
    resetErrorState() {
        this.context.commit('RESET_ERROR_BY_FIELD', 'name');
        this.context.commit('RESET_ERROR_BY_FIELD', 'city');
        this.RESET_ERROR_BY_FIELD('postAuctionSaleOfLotsHours');
    }

    @Action({ rawError: true })
    resetAuctionHouseName() {
        this.context.commit('SET_AUCTION_HOUSE_NAME', '');
    }
}

export default getModule(AuctionEHouseEntityModule);
