/*
 *
 * */

import { Input } from '@/lib/formFactory/input.interface';
export default class InputEntity {
    defaultValue: Input;
    entityName: string;
    entity: any;

    constructor(name: string) {
        this.defaultValue = {
            key: name,
            type: 'text',
            title: '',
            placeholder: '',
            value: '',
            required: false,
            disabled: false,
            size: 250,
            autocomplete: 'chrome-off',
            mask: {
                regex: '',
                placeholder: '',
                minChars: 1,
                maxChars: 0,
            },
            error: {
                class: '',
                message: '',
            },
            validation: {
                empty: 'Поле не заполнено',
                regex: '',
            },
        };
        this.entityName = name;
        this.entity = { [this.entityName]: this.defaultValue };
    }

    setType(type: string) {
        this.entity[this.entityName]['type'] = type;
    }

    setTitle(title: string) {
        this.entity[this.entityName]['title'] = title;
    }

    initLocalization(): void {
        this.entity[this.entityName]['localization'] = {
            ru: '',
            en: '',
        };
    }

    setSubTitle(title: string) {
        this.entity[this.entityName]['subTitle'] = title;
    }

    setPlaceholder(placeholder: string) {
        this.entity[this.entityName]['placeholder'] = placeholder;
    }

    setRequired(required: boolean) {
        this.entity[this.entityName]['required'] = required;
    }

    setMask(mask: {
        regex?: string;
        mask?: string;
        alias?: string;
        inputFormat?: string;
        clearIncomplete?: boolean;
        placeholder?: string;
        minChars?: number;
        maxChars?: number;
        showMaskOnHover?: boolean;
        greedy?: boolean;
        autoUnmask?: boolean;
        definitions?: {
            [key: string]: {
                validator: string;
                casing: string;
            };
        };
    }) {
        this.entity[this.entityName]['mask'] = mask;
    }

    setValidation(validation: any) {
        this.entity[this.entityName]['validation'] = validation;
    }

    setSize(size: number) {
        this.entity[this.entityName]['size'] = size;
    }

    setValue(value: string) {
        this.entity[this.entityName]['value'] = value;
    }
}
