export const tabsNav = {
    card: {
        id: 'card',
        name: 'Информация',
        template: 'lot',
        pathName: 'lot',
        icon: '',
        iconSize: '16',
    },
    bids: {
        id: 'bids',
        name: 'Ставки',
        template: 'lot_bids',
        pathName: 'lot_bids',
        icon: '',
        iconSize: '16',
    },
};
