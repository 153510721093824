/*
 *
 * */

import { Checkbox, CheckboxList } from '@/lib/formFactory/checkbox.interface';
export default class CheckboxEntity {
    defaultValue: Checkbox;
    entityName: string;
    entity: any;

    constructor(name: string) {
        this.defaultValue = {
            key: name,
            name: name,
            type: 'checkbox',
            disabled: false,
            list: {
                0: {
                    id: 1,
                    name: 'Активен',
                    checked: true,
                },
            },
            handler: '',
        };
        this.entityName = name;
        this.entity = { [this.entityName]: this.defaultValue };
    }

    setTitle(title: string) {
        this.entity[this.entityName]['title'] = title;
    }

    setName(name: string) {
        this.entity[this.entityName]['name'] = name;
    }

    setList(list: CheckboxList) {
        this.entity[this.entityName]['list'] = list;
    }

    setAction(handler: string) {
        this.entity[this.entityName]['handler'] = handler;
    }
}
