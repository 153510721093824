import axios from 'axios';
import { API_URL } from '@/constants';

// TODO:: change domain
const TEST_DOMAIN = API_URL || 'http://app.artebido.demo.code-pilots.ru';
const baseUrl =
    location.origin.indexOf('localhost') !== -1 || location.origin.indexOf('artebido.dev') !== -1
        ? TEST_DOMAIN
        : location.origin;

const INSTANCE = axios.create({
    baseURL: baseUrl,
});

export default INSTANCE;
