import { AxiosResponse } from 'axios';
import INSTANCE from '@/config/axios-config';
import userModule from '@/admin/store/user';

/**
 * Send request with Form data.
 *
 * @param url
 * @param formData
 * @param method
 */
/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export const formRequestBase = <T = any>(
    url: string,
    formData: { [key: string]: string | Blob },
    method: 'POST' | 'PUT' | 'PATCH' = 'POST',
): Promise<AxiosResponse<T>> => {
    const data = new FormData();
    Object.keys(formData).forEach((key) => {
        data.set(key, formData[key]);
    });

    return INSTANCE.request<T>({
        url,
        method,
        data,
        headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${userModule.token}`,
        },
    });
};

/**
 * Send POST request without Form data.
 *
 * @param url
 * @param formData
 * @param method
 */
/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export const postRequestBase = <T = any>(
    url: string,
    method: 'POST' | 'PUT' | 'PATCH' = 'POST',
): Promise<AxiosResponse<T>> => {
    return INSTANCE.request<T>({
        url,
        method,
        headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${userModule.token}`,
        },
    });
};

/**
 * Send request with Form data.
 *
 * @param url
 * @param method
 */
/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export const deleteRequestBase = <T = any>(url: string): Promise<AxiosResponse<T>> => {
    return INSTANCE.request<T>({
        url,
        method: 'DELETE',
        headers: {
            Authorization: `Bearer ${userModule.token}`,
        },
    });
};

/**
 * Send request GET request.
 *
 * @param url
 * @param method
 */
/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export const getRequestBase = <T = any>(url: string): Promise<AxiosResponse<T>> => {
    return INSTANCE.request<T>({
        url,
        method: 'GET',
        headers: {
            // 'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${userModule.token}`,
        },
    });
};
