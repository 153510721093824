/**
 * Guard check if user have access rights to block (menu item).
 *
 * @author Artem Bakulin <dekkyartem@gmail.com>
 */

import { NavigationGuard, Route } from 'vue-router';

import userModule from '@/admin/store/user';

const guard: NavigationGuard = (to: Route, _, next): void => {
    if (to?.meta?.isBlock && !userModule.isBlockAllowed(to.name)) {
        next({ name: to.name ?? undefined });
    } else {
        next();
    }
};

export default guard;
