/**
 * Axios initialization for application.
 *
 */

import axios, { AxiosError } from 'axios';
import siteModule from '@/admin/store/site';

const axiosInstance = axios.create({
    baseURL: process.env.VUE_APP_API_ADMIN_URL ?? '/api',
    withCredentials: true,
});

axiosInstance.interceptors.request.use(undefined, (error: AxiosError) => {
    siteModule.fetchApiRequestError(error);

    return Promise.reject(error);
});

axiosInstance.interceptors.response.use(undefined, (error: AxiosError) => {
    siteModule.fetchApiResponseError(error);

    return Promise.reject(error);
});

export default axiosInstance;
