import CurrencyModule from '@/admin/store/currency';
import { CurrencyApiInterface } from '@/interfaces/catalog.interface';

/**
 * Filters CurrencyModule.currencies to exclude RUB currency
 * @returns {Array}
 */
export const getCurrenciesWithoutRuble = (): CurrencyApiInterface[] => {
    return CurrencyModule.currencies.filter((currency) => currency.code !== 'RUB');
};
