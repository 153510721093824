<template>
    <layout class="cp-page__wrapper">
        <div class="cp-main-inner__wrapper">
            <div class="cp-page-not-found cp-text-light cp-padding__t cp-padding__l cp-padding__r cp-padding__b">
                <div class="cp-page-not-found__inner">
                    <div class="cp-page-not-found__title">404</div>
                    <div>Страница не найдена</div>
                </div>
            </div>
        </div>
    </layout>
</template>

<script>
import Layout from '@/admin/layouts/Default.vue';

export default {
    name: 'App',
    components: {
        Layout,
    },
};
</script>

<style lang="scss">
@import '@/admin/assets/scss/templates/page-not-found.scss';
</style>
