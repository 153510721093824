import 'normalize.css';
import '@/assets/css/base.css';
import userModule from '@/admin/store/user';

import 'reflect-metadata';
import Vue from 'vue';
import { sync } from 'vuex-router-sync';
import VueAxios from 'vue-axios';
import siteModule from '@/admin/store/site';
import App from './App.vue';
import router from './admin/router';
import store from './admin/store';
import axios from './axios';
import vuetify from '@/plugins/vuetify';

import ResponseHandler from '@/components/base/ResponseHandler.vue';
Vue.component('ResponseHandler', ResponseHandler);

const main = async () => {
    Vue.config.productionTip = false;
    Vue.use(VueAxios, axios);

    userModule.setToken(
        window.localStorage.tokenAdmin && window.localStorage.tokenAdmin != undefined
            ? window.localStorage.tokenAdmin
            : '',
    );
    await siteModule.init();

    sync(store, router);

    new Vue({
        router,
        store,
        vuetify,
        render: (h) => h(App),
    }).$mount('#app');
};

main();
