import API from '@/admin/config/api-routers';
import { makeGetRequest, makeFormRequest } from '@/api/helpers';

export interface CountryCardApiResponseInterface {
    createdAt: string;
    id: number;
    iso: string | null;
    name: string;
    phoneCode: string | null;
    phoneMask: string | null;
    sort: number | null;
}

export const getAllCountries = async () => {
    const result = await makeGetRequest(API.country.getAll);

    return result.data.result.table;
};

export const getCountriesList = async (pageCurrent: number, sort: string, filter: string, items: number | string) => {
    const result = await makeGetRequest(API.country.getList + `?page=${pageCurrent}&items=${items}&${sort}${filter}`);

    return result.data.result.table;
};

export const getPhoneCodesList = async () => {
    const result = await makeGetRequest(API.country.getList + `?page=1&items=1000`);

    return result.data.result.table;
};

export const getCountryCard = async (cardId: number | string): Promise<CountryCardApiResponseInterface> => {
    const result = await makeGetRequest(API.country.card + `${cardId}/`);

    if (result.data.result.localization) {
        result.data.result.item['localization'] = result.data.result.localization;
    }

    return result.data.result.item;
};

export const editCountryCard = async (cardId: number | string, data: any): Promise<CountryCardApiResponseInterface> => {
    const result = await makeFormRequest(API.country.card + `${cardId}/`, data);

    return result.data.result.item;
};

export const createCountryCard = async (data: any): Promise<CountryCardApiResponseInterface> => {
    const result = await makeFormRequest(API.country.create, data);

    return result.data.result.item;
};
