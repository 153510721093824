/**
 * Guard check for auction house pages.
 *
 */

import { NavigationGuard, Route } from 'vue-router';

import SellersPainterEntity from '@/admin/store/sellers/painters/entity';

const AUTHOR_ROUTES = [
    'painter',
    'painter_lots',
    'painter_works',
    'painter_work_create',
    'painter_work',
    'painter_orders',
    'painter_users',
    'painter_balance',
];

const isAuthorRouteName = (routeName: string | undefined | null): boolean => {
    return AUTHOR_ROUTES.includes(routeName || '');
};

const initAuthorEdit = async (route: Route) => {
    await SellersPainterEntity.reset();
    await SellersPainterEntity.initForm(+route.params.painterId);

    SellersPainterEntity.initCardById(route.params.painterId);
};

const initAuthorCreate = async () => {
    await SellersPainterEntity.reset();
    SellersPainterEntity.initForm();
    SellersPainterEntity.updateIsLoading(false);
};

const guard: NavigationGuard = async (to: Route, from: Route, next) => {
    if (
        to.name === 'painter' ||
        (isAuthorRouteName(from.name) && !isAuthorRouteName(from.name) && !SellersPainterEntity.cardId)
    ) {
        initAuthorEdit(to);

        next();
    } else if (to.name === 'painter_create') {
        initAuthorCreate();

        next();
    } else if (isAuthorRouteName(String(from.name)) && !isAuthorRouteName(String(to.name))) {
        SellersPainterEntity.reset();
        next();
    } else {
        next();
    }
};

export default guard;
