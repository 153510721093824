import API from '@/admin/config/api-routers';
import { makeGetRequest, makeFormRequest } from '@/api/helpers';

export interface HierarchyCategory {
    [key: string]: number | string | boolean | HierarchyCategory[];
    id: number;
    name: string;
    forPainters: boolean;
    categories: HierarchyCategory[];
}

export const getCategoryAll = async () => {
    const result = await makeGetRequest(API.category.getAll);

    return result.data.result.table;
};

export const getCategoryList = async (pageCurrent: number, sort: string, filter: string, items: number | string) => {
    const itemsFilter = items ? `&items=${items}` : '';
    const result = await makeGetRequest(API.category.getList + `?page=${pageCurrent}${itemsFilter}&${sort}${filter}`);

    return result.data.result.table;
};

export const getCategoryHierarchyList = async (
    pageCurrent: number,
    sort: string,
    filter: string,
    items?: number | string,
): Promise<HierarchyCategory[]> => {
    const itemsFilter = items ? `&items=${items}` : '';
    const result = await makeGetRequest(
        `${API.category.getNewList}?page=${pageCurrent}${itemsFilter}&${sort}${filter}`
    );

    return result.data.result.categories;
};

export const getCategoryCard = async (cardId: number | string) => {
    const result = await makeGetRequest(API.category.card + `${cardId}/`);

    if (result.data.result.localization) {
        result.data.result.item['localization'] = result.data.result.localization;
    }

    return result.data.result.item;
};

export const editCategoryCard = async (cardId: number | string, data: any) => {
    const result = await makeFormRequest(API.category.card + `${cardId}/`, data);

    return result;
};

export const createCategoryCard = async (data: any) => {
    const result = await makeFormRequest(API.category.create, data);

    return result;
};
