/**
 * Guard check for auction house pages.
 *
 */

import { NavigationGuard, Route } from 'vue-router';

import GalleryEntity from '@/admin/store/sellers/galleries/entity';

const GALLERY_ROUTES = ['gallery', 'gallery_lots', 'gallery_orders', 'gallery_users', 'gallery_balance'];

const isGalleryRelatedRouteName = (routeName: string | undefined | null): boolean => {
    return Boolean(routeName && GALLERY_ROUTES.includes(routeName || ''));
};

const initGalleryEditCard = async (route: Route) => {
    await GalleryEntity.reset();

    await GalleryEntity.initForm(+route.params.galleryId);
    GalleryEntity.initCardById(route.params.galleryId);
};

const initGalleyCreateCard = async (route: Route) => {
    resetGalleryStore();

    await GalleryEntity.initForm(+route.params.galleryId);
};

const resetGalleryStore = () => {
    GalleryEntity.resetGalleryName();
    GalleryEntity.reset();
};

const guard: NavigationGuard = async (to: Route, from: Route, next) => {
    if (
        to.name === 'gallery' &&
        (!isGalleryRelatedRouteName(from.name) || (isGalleryRelatedRouteName(from.name) && !GalleryEntity.cardId))
    ) {
        initGalleryEditCard(to);

        next();
    } else if (to.name === 'gallery_create') {
        initGalleyCreateCard(to);

        next();
    } else if (isGalleryRelatedRouteName(from.name) && !isGalleryRelatedRouteName(to.name)) {
        resetGalleryStore();

        next();
    } else {
        next();
    }
};

export default guard;
