import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Home from '@/admin/views/home/Index.vue';
import PageNotFound from '@/admin/views/PageNotFound.vue';
import authGuard from './auth.guard';
import blockGuard from './block.guard';
import auctionHouseGuard from './auctionHouse.guard';
import accessGuard from './access.guard';
import catalogGuard from './catalog.guard';
import galleryGuard from './gallery.guard';
import lotGuard from './lot.guard';
import authorGuard from './author.guard';

Vue.use(VueRouter);

const routes: RouteConfig[] = [
    {
        path: '/',
        name: 'home',
        component: Home,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/auth',
        name: 'login',
        meta: {
            requiresAuth: false,
        },
        component: () => import('../views/login/Index.vue'),
    },
    {
        path: '/sellers/auction_houses',
        name: 'auction_houses',
        component: () => import('../views/auctionHouse/Index.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/sellers/auction_house/create/',
        name: 'auction_house_add',
        component: () => import('../views/auctionHouse/Main.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/sellers/auction_house/:auctionHouseId',
        name: 'auction_house',
        component: () => import('../views/auctionHouse/Main.vue'),
        meta: {
            requiresAuth: true,
        },
        children: [
            {
                path: 'catalogs',
                name: 'auction_house_catalogs',
                component: () => import('../views/auctionHouse/Main.vue'),
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: 'catalog/:catalogId',
                name: 'auction_house_catalog',
                component: () => import('../views/auctionHouse/Main.vue'),
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: 'lots',
                name: 'auction_house_lots',
                component: () => import('../views/auctionHouse/Main.vue'),
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: 'users',
                name: 'auction_house_users',
                component: () => import('../views/auctionHouse/Main.vue'),
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: 'orders',
                name: 'auction_house_orders',
                component: () => import('../views/auctionHouse/Main.vue'),
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: 'balance',
                name: 'auction_house_balance',
                component: () => import('../views/auctionHouse/Main.vue'),
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: 'approved_users',
                name: 'auction_house_approved_users',
                component: () => import('../views/auctionHouse/Main.vue'),
                meta: {
                    requiresAuth: true,
                },
                children: [
                    {
                        path: ':userId',
                        name: 'auction_house_approved_user',
                        component: () => import('../views/auctionHouse/Main.vue'),
                        meta: {
                            requiresAuth: true,
                        },
                    },
                    {
                        path: ':userId/requests',
                        name: 'auction_house_approved_user_requests',
                        component: () => import('../views/auctionHouse/Main.vue'),
                        meta: {
                            requiresAuth: true,
                        },
                    },
                ],
            },
        ],
    },
    {
        path: '/sellers/galleries',
        name: 'galleries',
        component: () => import('../views/gallery/Index.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/sellers/gallery/:galleryId',
        name: 'gallery',
        component: () => import('../views/gallery/Main.vue'),
        meta: {
            requiresAuth: true,
        },
        children: [
            {
                path: 'lots',
                name: 'gallery_lots',
                component: () => import('../views/gallery/Main.vue'),
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: 'orders',
                name: 'gallery_orders',
                component: () => import('../views/gallery/Main.vue'),
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: 'users',
                name: 'gallery_users',
                component: () => import('../views/gallery/Main.vue'),
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: 'balance',
                name: 'gallery_balance',
                component: () => import('../views/gallery/Main.vue'),
                meta: {
                    requiresAuth: true,
                },
            },
        ],
    },
    {
        path: '/sellers/gallery/create',
        name: 'gallery_create',
        component: () => import('../views/gallery/Main.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/content',
        name: 'content',
        component: () => import('../views/content/Index.vue'),
        meta: {
            requiresAuth: true,
        },
        children: [
            {
                path: 'news',
                name: 'content_news',
                component: () => import('../views/content/Index.vue'),
                meta: {
                    requiresAuth: true,
                },
                children: [
                    {
                        path: 'create',
                        name: 'content_news_create',
                        component: () => import('../views/content/Index.vue'),
                        meta: {
                            requiresAuth: true,
                        },
                    },
                    {
                        path: ':newsId',
                        name: 'content_news_card',
                        component: () => import('../views/content/Index.vue'),
                        meta: {
                            requiresAuth: true,
                        },
                    },
                ],
            },
            {
                path: 'main_page',
                name: 'content_main_page',
                component: () => import('../views/content/Index.vue'),
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: 'slider_card/create',
                name: 'slider_content_card_create',
                component: () => import('../views/content/Index.vue'),
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: 'slider_card/:cardId',
                name: 'slider_content_card',
                component: () => import('../views/content/Index.vue'),
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: 'about',
                name: 'content_about',
                component: () => import('../views/content/Index.vue'),
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: 'rules',
                name: 'content_rules',
                component: () => import('../views/content/Index.vue'),
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: 'faq',
                name: 'content_faq',
                component: () => import('../views/content/Index.vue'),
                meta: {
                    requiresAuth: true,
                },
                children: [
                    {
                        path: 'create',
                        name: 'content_faq_create',
                        component: () => import('../views/content/Index.vue'),
                        meta: {
                            requiresAuth: true,
                        },
                    },
                    {
                        path: ':faqId',
                        name: 'content_faq_card',
                        component: () => import('../views/content/Index.vue'),
                        meta: {
                            requiresAuth: true,
                        },
                    },
                ],
            },
            {
                path: 'policy',
                name: 'content_policy',
                component: () => import('../views/content/Index.vue'),
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: 'terms',
                name: 'content_terms',
                component: () => import('../views/content/Index.vue'),
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: 'offer',
                name: 'content_offer',
                component: () => import('../views/content/Index.vue'),
                meta: {
                    requiresAuth: true,
                },
            },
        ],
    },
    {
        path: '/settings/auctions',
        name: 'settings_auctions',
        component: () => import('../views/settings/Index.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/settings/cities',
        name: 'settings_cities',
        component: () => import('../views/settings/Index.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/settings/city/:cityId/:countryId?',
        name: 'settings_city',
        component: () => import('../views/settings/Index.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/settings/city/create/:countryId?',
        name: 'settings_city_create',
        component: () => import('../views/settings/Index.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/settings/countries',
        name: 'settings_countries',
        component: () => import('../views/settings/Index.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/settings/country/:countryId',
        name: 'settings_country',
        component: () => import('../views/settings/Index.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/settings/country/create',
        name: 'settings_country_create',
        component: () => import('../views/settings/Index.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/settings/categories',
        name: 'settings_categories',
        component: () => import('../views/settings/Index.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/settings/category/:categoryId',
        name: 'settings_category',
        component: () => import('../views/settings/Index.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/settings/category/create',
        name: 'settings_category_create',
        component: () => import('../views/settings/Index.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/settings/specialties',
        name: 'settings_specialties',
        component: () => import('../views/settings/Index.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/settings/specialty/create',
        name: 'settings_specialty_create',
        component: () => import('../views/settings/Index.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/settings/specialty/:specialtyId',
        name: 'settings_specialty',
        component: () => import('../views/settings/Index.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/settings/colors',
        name: 'settings_colors',
        component: () => import('../views/settings/Index.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/settings/currencies',
        name: 'settings_currencies',
        component: () => import('../views/settings/Index.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/settings/import',
        name: 'settings_import',
        component: () => import('../views/settings/Index.vue'),
        meta: {
            requiresAuth: true,
        },
        children: [
            {
                name: 'settings_import_card',
                path: ':importId',
                component: () => import('../views/settings/Index.vue'),
                meta: {
                    requiresAuth: true,
                },
            },
        ],
    },
    {
        path: '/settings/main_page',
        name: 'settings_main_page',
        component: () => import('../views/settings/Index.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/sellers/painters/',
        name: 'painters',
        component: () => import('../views/painter/Index.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/sellers/painter/:painterId',
        name: 'painter',
        component: () => import('../views/painter/Main.vue'),
        meta: {
            requiresAuth: true,
        },
        children: [
            {
                path: 'lots',
                name: 'painter_lots',
                component: () => import('../views/painter/Main.vue'),
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: 'works',
                name: 'painter_works',
                component: () => import('../views/painter/Main.vue'),
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: 'work/create',
                name: 'painter_work_create',
                component: () => import('../views/painter/Main.vue'),
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: 'work/:workId',
                name: 'painter_work',
                component: () => import('../views/painter/Main.vue'),
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: 'orders',
                name: 'painter_orders',
                component: () => import('../views/painter/Main.vue'),
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: 'users',
                name: 'painter_users',
                component: () => import('../views/painter/Main.vue'),
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: 'balance',
                name: 'painter_balance',
                component: () => import('../views/painter/Main.vue'),
                meta: {
                    requiresAuth: true,
                },
            },
        ],
    },
    {
        path: '/sellers/painter/create',
        name: 'painter_create',
        component: () => import('../views/painter/Main.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/users/',
        name: 'users',
        component: () => import('../views/users/Index.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/users/user/create',
        name: 'user_create',
        component: () => import('../views/users/Main.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/users/user/:userId',
        name: 'user',
        component: () => import('../views/users/Main.vue'),
        meta: {
            requiresAuth: true,
        },
        children: [
            {
                path: 'balance',
                name: 'user_balance',
                component: () => import('../views/users/Main.vue'),
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: 'logs',
                name: 'user_logs',
                component: () => import('../views/users/Main.vue'),
                meta: {
                    requiresAuth: true,
                },
            },
        ],
    },
    {
        path: '/auctions',
        name: 'auctions',
        component: () => import('../views/auctions/Index.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/auctions/auction/create/:auctionHouseId?',
        name: 'auction_create',
        component: () => import('../views/auctions/Index.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/auctions/auction/:catalogId',
        name: 'auction',
        component: () => import('../views/auctions/Index.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/auctions/lots',
        name: 'lots',
        component: () => import('../views/auctions/Index.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/auctions/lot/:sellerType/create/:catalogId?',
        name: 'lot_create',
        component: () => import('../views/auctions/Index.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/auctions/lot/:sellerType/:lotId',
        name: 'lot',
        component: () => import('../views/auctions/Index.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/auctions/lot/:sellerType/:lotId/bids',
        name: 'lot_bids',
        component: () => import('../views/auctions/Index.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/auctions/auction/:catalogId/lots',
        name: 'auction_lots',
        component: () => import('../views/auctions/Index.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/auctions/auction/:catalogId/bidding',
        name: 'auction_bidding',
        component: () => import('../views/auctions/Index.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/auctions/orders',
        name: 'orders',
        component: () => import('../views/auctions/Index.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/auctions/order/:orderId',
        name: 'order',
        component: () => import('../views/auctions/Index.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/finance/',
        name: 'finance',
        component: () => import('../views/finance/Index.vue'),
        meta: {
            requiresAuth: true,
        },
        children: [
            {
                path: 'customer',
                name: 'finance_customer',
                component: () => import('../views/finance/Index.vue'),
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: 'seller',
                name: 'finance_seller',
                component: () => import('../views/finance/Index.vue'),
                meta: {
                    requiresAuth: true,
                },
            },
        ],
    },
    {
        path: '/questions/',
        name: 'questions',
        component: () => import('../views/questions/Index.vue'),
        meta: {
            requiresAuth: true,
        },
        children: [
            {
                path: 'auction_houses/',
                name: 'questions_auction_houses',
                component: () => import('../views/questions/Index.vue'),
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: 'galleries/',
                name: 'questions_galleries',
                component: () => import('../views/questions/Index.vue'),
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: 'painters/',
                name: 'questions_painters',
                component: () => import('../views/questions/Index.vue'),
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: 'sell/',
                name: 'questions_sell',
                component: () => import('../views/questions/Index.vue'),
                meta: {
                    requiresAuth: true,
                },
            },
        ],
    },
    {
        path: '/tariffs/',
        name: 'tariffs',
        component: () => import('../views/tariffs/Index.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/tariffs/:id',
        name: 'tariffs_edit',
        component: () => import('../views/tariffs/Edit.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/seo/',
        name: 'seo',
        component: () => import('../views/seo/Index.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/mailing/',
        name: 'mailing',
        component: () => import('../views/mailing/Index.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/mailing/:id',
        name: 'mailing_edit',
        component: () => import('../views/mailing/Edit.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/content/',
        name: 'content',
        component: PageNotFound,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/404',
        name: 'not_found',
        component: PageNotFound,
    },
    {
        path: '/*',
        name: 'not_found',
        component: PageNotFound,
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(__, _, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { x: 0, y: 0 };
        }
    },
});

router.beforeEach(authGuard);
router.beforeEach(accessGuard);
router.beforeEach(blockGuard);
router.beforeEach(auctionHouseGuard);
router.beforeEach(catalogGuard);
router.beforeEach(galleryGuard);
router.beforeEach(lotGuard);
router.beforeEach(authorGuard);

export default router;
