import API from '@/admin/config/api-routers';
import { makeGetRequest, makeFormRequest, makePostRequest } from '@/api/helpers';
import { CatalogApiInterface } from '@/interfaces/catalog.interface';
import { TableApiInterface } from '@/lib/layouts/page/table.interface';

export const getCatalogAuctionAll = async (): Promise<CatalogApiInterface> => {
    const result = await makeGetRequest(API.catalogAuction.getAll);

    return result.data.result.table;
};

export const getCatalogAuctionList = async (
    pageCurrent: number,
    sort: string,
    filter: string,
    items: number | string,
): Promise<TableApiInterface> => {
    const itemsFilter = items ? `&items=${items}` : '';
    const result = await makeGetRequest(
        API.catalogAuction.getList + `?page=${pageCurrent}${itemsFilter}&${sort}${filter}`,
    );

    return result.data.result.table;
};

export const getCatalogAuctionCard = async (cardId: number): Promise<CatalogApiInterface> => {
    const result = await makeGetRequest(API.catalogAuction.card + `${cardId}/`);

    if (result.data.result.localization) {
        result.data.result.item['localization'] = result.data.result.localization;
    }

    return result.data.result.item;
};

export const editCatalogAuctionCard = async (cardId: number, data: any): Promise<CatalogApiInterface> => {
    const result = await makeFormRequest(API.catalogAuction.card + `${cardId}/`, data);

    return result.data.result.item;
};

export const createCatalogAuctionCard = async (data: any): Promise<CatalogApiInterface> => {
    const result = await makeFormRequest(API.catalogAuction.create, data);

    return result.data.result.item;
};

export const getCatalogAuctionStatuses = async (): Promise<Array<{ id: string; name: string }>> => {
    const result = await makeGetRequest(API.catalogAuction.statuses);

    return result.data.result.table;
};

export const cloneCatalog = async (catalogId: string): Promise<CatalogApiInterface> => {
    const result = await makePostRequest(API.catalogAuction.clone + `${catalogId}/`);

    return result.data.result.item;
};

export const unpublishCatalog = async (catalogId: string): Promise<CatalogApiInterface> => {
    const result = await makePostRequest(`${API.catalogAuction.card}${catalogId}/withdraw_from_publication/`);

    return result.data.result.item;
};
