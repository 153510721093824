/**
 * Helpers for work with API requests.
 *
 */

import { getRequestBase, formRequestBase, deleteRequestBase, postRequestBase } from './helpers-core';
import userModule from '@/admin/store/user';
import RedirectHandlerModule from '@/admin/store/modules/redirectHandler';

export interface FormDataObject {
    [key: string]: string | Blob;
}
/**
 * Send request with Form data.
 *
 * @param url
 * @param formData
 * @param method
 */
/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export const makeFormRequest = async (
    url: string,
    formData: { [key: string]: string | Blob },
    method?: 'POST' | 'PUT' | 'PATCH',
): Promise<any> => {
    try {
        const result = await formRequestBase(url, formData, method);

        if (result?.data?.result?.item && result?.data?.result?.localization) {
            result.data.result.item.localization = result.data.result.localization;
        }

        return result;
    } catch (error: any) {
        if (error?.response?.status === 401) {
            userModule.clearUserData();

            throw error;
        }

        throw error;
    }
};

/**
 * Send request with Form data.
 *
 * @param url
 * @param formData
 * @param method
 */
/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export const makePostRequest = async (url: string, method?: 'POST' | 'PUT' | 'PATCH'): Promise<any> => {
    try {
        const result = await postRequestBase(url, method);

        return result;
    } catch (error: any) {
        if (error?.response?.status === 401) {
            userModule.clearUserData();

            return;
        }

        throw error;
    }
};

/**
 * Send request with Form data.
 *
 * @param url
 * @param method
 */
/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export const makeDeleteRequest = async (url: string): Promise<any> => {
    try {
        const result = await deleteRequestBase(url);

        return result;
    } catch (error: any) {
        if (error?.response?.status === 401) {
            userModule.clearUserData();

            throw error;
        }

        throw error;
    }
};

/**
 * Send request GET request.
 *
 * @param url
 * @param method
 */
/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export const makeGetRequest = async (url: string): Promise<any> => {
    try {
        const result = await getRequestBase(url);

        if (result?.data?.result?.item && result?.data?.result?.localization) {
            result.data.result.item.localization = result.data.result.localization;
        }

        return result;
    } catch (error: any) {
        if (error?.response?.status === 401) {
            userModule.clearUserData();

            throw error;
        }

        if (error?.response?.status === 404) {
            RedirectHandlerModule.changeUrl({ name: 'not_found' } as any);

            throw new Error();
        }

        throw error;
    }
};
