/**
 * API for work with users data.
 *
 */

import { UserResponse } from '@/interfaces/admin.interface';
import { makeFormRequest, makeDeleteRequest } from '@/api/helpers';
import API from '@/admin/config/api-routers';

/**
 * Admin user authorization.
 *
 * @param login
 * @param password
 */
export const login = async (login: string, password: string): Promise<UserResponse> => {
    const result = await makeFormRequest(API.auth.login, { login, password });

    return result.data;
};

/**
 * Admin user logout.
 */
export const logout = async (): Promise<void> => {
    await makeDeleteRequest(API.auth.logout);
};
