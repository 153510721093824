/**
 * Entity base module.
 *
 */

import { VuexModule, Mutation, Action } from 'vuex-module-decorators';
import { Input } from '@/lib/formFactory/input.interface';
import { clearPhone, imageFileToBase64 } from '@/lib/utils/Utils';
import moment from 'moment';
import { MultiSelect } from '@/lib/formFactory/multiSelect.interface';
import { InputSuggestionItem, InputSuggestionList } from '@/lib/formFactory/inputSuggestion.interface';
import { getTreeCategoryListSelected, getTreeCategoryListUpdated, processListForSelect } from '@/lib/utils/form';
import { getCitiesList } from '../api/city';
import { getCountriesList, getPhoneCodesList } from '../api/country';
import { InputDatePicker } from '@/lib/formFactory/inputDatePicker.interface';
import {
    InputSuggestionMultipleItem,
    InputSuggestionMultipleList,
} from '@/lib/formFactory/inputSuggestionMultiple.interface';
import { HierarchyCategorySelectItem } from '@/lib/utils/form';
import { Localization, LocalizationParamsStore, Languages } from '@/interfaces/localization';
import { languagesCodes } from '@/constants';

export default abstract class EntityBaseModule extends VuexModule {
    id = '';
    isLoading = true;
    model: any;
    codes = [];

    @Mutation
    SET_IS_LOADING(isLoading: boolean) {
        this.isLoading = isLoading;
    }

    @Mutation
    SET_CODES(codes: any) {
        this.codes = codes;
    }

    @Mutation
    SET_FIELD_DISABLED_BY_TYPE(params: { type: string; disabled: boolean }) {
        if (!this.model[params.type] || this.model[params.type].disabled == null) {
            return;
        }

        this.model[params.type].disabled = params.disabled;
    }

    @Mutation
    SET_ID(id: string) {
        this.id = id;
    }

    @Mutation
    SET_IS_ACTIVE(bool: boolean) {
        this.model.isActive.list[0].checked = bool;
    }

    @Mutation
    SET_TOP_SELLER(bool: boolean) {
        this.model.topSeller.list[0].checked = bool;
    }

    @Mutation
    SET_PUBLISHED(bool: boolean) {
        this.model.published.list[0].checked = bool;
    }

    @Mutation
    SET_NAME(name: string) {
        this.model.name.value = name;
    }

    @Mutation
    SET_LAST_NAME(lastName: string) {
        this.model.lastName.value = lastName;
    }

    @Mutation
    SET_FIRST_NAME(firstName: string) {
        this.model.firstName.value = firstName;
    }

    @Mutation
    SET_MIDDLE_NAME(middleName: string) {
        this.model.middleName.value = middleName;
    }

    @Mutation
    SET_PHONE(phone: string) {
        this.model.phone.value = phone;
    }

    @Mutation
    SET_EMAIL(email: string) {
        this.model.email.value = email;
    }

    @Mutation
    SET_COMMENT(comment: string) {
        this.model.comment.value = comment;
    }

    @Mutation
    SET_VACANCIES(vacancies: string[]) {
        this.model.vacancies = vacancies;
    }

    @Mutation
    SET_PARTY(party: string) {
        this.model.party.value = party;
    }

    @Mutation
    SET_BIRTHDAY(birthday: string) {
        this.model.birthday.value = birthday;
    }

    @Mutation
    SET_LEGAL_FORM(selected: {}) {
        this.model.legalForm.current = selected;
    }

    @Mutation
    SET_REGIONS(list: []) {
        this.model.region.list = list;
    }

    @Mutation
    SET_REGION(selected: {}) {
        this.model.region.current = selected;
    }

    @Mutation
    SET_CUSTOMER(selected: {}) {
        this.model.customer.current = selected;
    }

    @Mutation
    SET_CUSTOMERS(list: []) {
        this.model.customer.list = list;
    }

    @Mutation
    SET_SUGGESTIONS_SELECTED_BY_TYPE(params: { selected: InputSuggestionItem; type: string }) {
        this.model[params.type].current = params.selected;
    }

    @Mutation
    SET_SUGGESTIONS_VALUE_BY_TYPE(params: { value: string; type: string }) {
        this.model[params.type].value = params.value;
    }

    @Mutation
    SET_SUGGESTIONS_HINTS_LIST_BY_TYPE(params: { list: InputSuggestionList; type: string }) {
        this.model[params.type].hintsList = params.list;
    }

    @Mutation
    UPDATE_PHONE(params: { value: string; index: string }) {
        for (const phone in this.model.marketPhones) {
            if (this.model.marketPhones[phone].id === params.index) {
                this.model.marketPhones[phone].value = params.value;

                return;
            }
        }
    }

    @Mutation
    UPDATE_POSITION(selected: {}) {
        this.model.positions.list = selected;
    }

    @Mutation
    UPDATE_POSITION_HINTS(list: {}) {
        this.model.positions.hintsList = list;
    }

    @Mutation
    SET_COMPETENCE_LIST(list: {}) {
        this.model.competence.list = list;
    }

    @Mutation
    SET_COMPETENCE_VALUE(value: string) {
        this.model.competence.value = value;
    }

    @Mutation
    SET_CODE(code: string) {
        this.model.code.value = code;
    }

    @Mutation
    UPDATE_ADDRESS_HINTS(address: any) {
        this.model.address.hintsList = address;
    }

    @Mutation
    SET_ADDRESS(address: any) {
        this.model.address.value = address.name;
        this.model.address.current = address;
    }

    @Mutation
    SET_ADDRESS_COORDINATES(coordinates: {}) {
        this.model.addressCoordinates.value = coordinates;
    }

    @Mutation
    UPDATE_ASSISTANT_HINTS(assistant: any) {
        this.model.assistant.hintsList = assistant;
    }

    @Mutation
    SET_ASSISTANT(assistant: any) {
        this.model.assistant.value = assistant.name;
        this.model.assistant.current = assistant;
    }

    @Mutation
    SET_INN(inn: string) {
        this.model.inn.value = inn;
    }

    @Mutation
    SET_KPP(kpp: string) {
        this.model.kpp.value = kpp;
    }

    @Mutation
    SET_BANK(bank: string) {
        this.model.bank.value = bank;
    }

    @Mutation
    SET_PAYMENT_ACCOUNT(value: string) {
        this.model.paymentAccount.value = value;
    }

    @Mutation
    SET_KS(ks: string) {
        this.model.ks.value = ks;
    }

    @Mutation
    SET_BIC(bic: string) {
        this.model.bic.value = bic;
    }

    @Mutation
    SET_BIC_SELECTED(bic: any) {
        this.model.bic.value = bic.value;
        this.model.bic.current = bic;
    }

    @Mutation
    UPDATE_BIC_HINTS(banks: any) {
        this.model.bic.hintsList = banks;
    }

    @Mutation
    SET_CREATED_AT(date: string) {
        this.model.createdAt = date;
    }

    @Mutation
    SET_DATE_POSTING(date: string) {
        this.model.datetimePosting = date;
    }

    @Mutation
    SET_CREATED_BY(params: { link: string; name: string }) {
        this.model.createdBy = params;
    }

    @Mutation
    SET_UPDATED_AT(date: string) {
        this.model.updatedAt = date;
    }

    @Mutation
    SET_UUID(value: string) {
        this.model.uuid = value;
        this.model.link = `${location.origin}/employee/${value}`;
    }

    @Mutation
    SET_AMOUNT(amount: string | number) {
        this.model.amount.value = amount;
    }

    @Mutation
    SET_WEEKS(weeks: string | number) {
        this.model.weeks.value = weeks;
    }

    @Mutation
    SET_SALARY(salary: string) {
        this.model.salary.value = salary;
    }

    @Mutation
    SET_PHOTO(url: string) {
        this.model.photo.value = url;
    }

    @Mutation
    SET_PHOTO_FILE(file: File | null) {
        this.model.photo.file = file;
    }

    @Mutation
    SET_PHOTO_PREVIEW(base64String: string) {
        this.model.photo.preview = base64String;
    }

    @Mutation
    SET_SLUG(slug: string): void {
        this.model.slug.value = slug;
    }

    @Mutation
    SET_MODEL_IMAGE_FIELD_URL(params: { field: string; url: string }): void {
        try {
            this.model[params.field].value = params.url;
        } catch (error) {
            console.warn(`SET_MODEL_IMAGE_FIELD_PHOTO: ${params.field} store field not found`);
        }
    }

    @Mutation
    SET_MODEL_IMAGE_FIELD_FILE(params: { field: string; file: File | null }): void {
        try {
            this.model[params.field].file = params.file;
        } catch (error) {
            console.warn(`SET_MODEL_IMAGE_FIELD_FILE: ${params.field} store field not found`);
        }
    }

    @Mutation
    SET_MODEL_IMAGE_FIELD_PREVIEW(params: { field: string; base64String: string }): void {
        try {
            this.model[params.field].preview = params.base64String;
        } catch (error) {
            console.warn(`SET_MODEL_IMAGE_FIELD_PREVIEW: ${params.field} store field not found`);
        }
    }

    @Mutation
    SET_DESCRIPTION(value: string) {
        this.model.description.value = value;
    }

    @Mutation
    SET_EXTERNAL_SALARY(salary: string) {
        this.model.externalSalary.value = salary;
    }

    @Mutation
    SET_ERROR_BY_FIELD(entity: { field: string; message: string }) {
        this.model[entity.field].error.class = 'cp-error';
        this.model[entity.field].error.message = entity.message;
    }

    @Mutation
    SET_SORT_ORDER(sort: number | string) {
        this.model.sort.value = sort;
    }

    @Mutation
    RESET_ERROR_BY_FIELD(field: string) {
        try {
            this.model[field].error.class = '';
            this.model[field].error.message = '';
        } catch (error) {
            console.warn(`RESET_ERROR_BY_FIELD: ${field} store field not found`);
        }
    }

    @Mutation
    SET_MODEL_FIELD_REQUIRED(field: string, bool: boolean) {
        this.model[field].required = bool;
    }

    @Mutation
    SET_MODEL_FIELD_VALUE(params: { field: string; value?: string }): void {
        try {
            this.model[params.field].value = params.value ?? '';
        } catch (error) {
            console.warn(`SET_MODEL_FIELD_VALUE: ${params.field} store field not found`);
            throw error;
        }
    }

    @Mutation
    SET_BALANCE(balance: number | null) {
        this.model.balance = typeof balance === 'number' ? `${balance} ₽` : balance;
    }

    @Mutation
    SET_AVG_LOT_VALUE(value: number | null) {
        this.model.balanceDetails.avgLotValue.value = typeof value === 'number' ? `${value} ₽` : value;
    }

    @Mutation
    SET_AVG_RECEIVED_FROM_LOT(value: number | null) {
        this.model.balanceDetails.avgReceivedFromLot.value = typeof value === 'number' ? `${value} ₽` : value;
    }

    @Mutation
    SET_CATALOGS_PLACED(value: number | null) {
        this.model.balanceDetails.catalogsPlaced.value = value;
    }

    @Mutation
    SET_LOTS_PLACED(value: number | null) {
        this.model.balanceDetails.lotsPlaced.value = value;
    }

    @Mutation
    SET_TOTAL_VALUE_LOTS(value: number | null) {
        this.model.balanceDetails.totalValueLots.value = typeof value === 'number' ? `${value} ₽` : value;
    }

    @Mutation
    SET_SOLD_LOTS(value: number | null) {
        this.model.balanceDetails.soldLots.value = value;
    }

    @Mutation
    SET_FIELD_VALUE_BY_NAME(params: { name: string; value: string }): void {
        try {
            this.model[params.name].value = params.value;
        } catch (error) {
            console.error(error, params);
        }
    }

    @Mutation
    SET_SUGGESTIONS_MULTIPLE_LIST_BY_TYPE(params: { type: string; list: InputSuggestionList }): void {
        try {
            this.model[params.type].hintsList = params.list;
        } catch (error) {
            console.error(error, params);
        }
    }

    @Mutation
    SET_SUGGESTIONS_MULTIPLE_SELECTED_LIST_BY_TYPE(params: { type: string; list: number[] }): void {
        try {
            this.model[params.type].selected = params.list;
        } catch (error) {
            console.error(error, params);
        }
    }

    @Mutation
    SET_LOCALIZATION_BY_FIELD(params: { fieldName: string; data: Localization }): void {
        this.model[params.fieldName].localization = params.data;
    }

    @Mutation
    RESET_LOCALIZATION_BY_FIELD(fieldName = 'name'): void {
        const languages: Languages = {};

        for (const key of languagesCodes) {
            languages[key] = '';
        }

        this.model[fieldName].localization = languages;
    }

    @Mutation
    SET_LOCALIZATION_LANG_VALUE_BY_FIELD(params: LocalizationParamsStore): void {
        try {
            this.model[params.field].localization[params.lang] = params.value;

            if (params.lang === 'ru' && this.model[params.field]?.error) {
                this.model[params.field].error.class = '';
                this.model[params.field].error.message = '';
            }
        } catch (error) {
            console.error(error, params);
        }
    }

    @Action({ rawError: true })
    updateLocalizationField(params: LocalizationParamsStore): void {
        this.context.commit('SET_LOCALIZATION_LANG_VALUE_BY_FIELD', params);
    }

    @Action({ rawError: true })
    updateModelFieldValue(params: { field: string; value: string }): void {
        this.context.commit('SET_MODEL_FIELD_VALUE', params);
    }

    @Action({ rawError: true })
    async updateImageFileByType(params: { type: string; file: File | null }): Promise<void> {
        this.context.commit('SET_MODEL_IMAGE_FIELD_FILE', { field: params.type, file: params.file });

        let base64String = '';

        if (params.file) {
            base64String = (await imageFileToBase64(params.file)) as string;
        }

        this.context.commit('SET_MODEL_IMAGE_FIELD_PREVIEW', { field: params.type, base64String });
    }

    @Action({ rawError: true })
    updateSuggestionsSelectedByType(params: { selected: InputSuggestionItem; type: string }) {
        this.context.commit('RESET_ERROR_BY_FIELD', params.type);
        this.context.commit('SET_SUGGESTIONS_VALUE_BY_TYPE', {
            value: params.selected.value || '',
            type: params.type,
        });
        this.context.commit(
            'SET_SUGGESTIONS_SELECTED_BY_TYPE',
            params.selected.id ? params : { ...params, id: 0, value: '' },
        );
    }

    @Action({ rawError: true })
    updateSuggestionsMultipleSelectedByType(params: { selected: InputSuggestionMultipleItem; type: string }): void {
        this.context.commit('RESET_ERROR_BY_FIELD', params.type);

        if (!params.selected.id) {
            return;
        }

        const list = this.model[params.type].hintsList.map((item: InputSuggestionMultipleItem) =>
            item.id === params.selected.id ? params.selected : item,
        );

        this.context.dispatch('updateSuggestionsMultipleHintsListByType', { list, type: params.type });
    }

    @Action({ rawError: true })
    updateCategoriesList(params: { selected: HierarchyCategorySelectItem; type: string; isSelect?: boolean }): void {
        const listKey = params.isSelect ? 'list' : 'hintsList';
        this.context.commit('RESET_ERROR_BY_FIELD', params.type);

        if (!params.selected.id) {
            return;
        }

        const list = getTreeCategoryListUpdated(this.model[params.type][listKey], params.selected);

        this.context.commit('SET_SUGGESTIONS_MULTIPLE_SELECTED_LIST_BY_TYPE', {
            type: params.type,
            list: getTreeCategoryListSelected(list),
        });
        this.context.commit('SET_SUGGESTIONS_MULTIPLE_LIST_BY_TYPE', { type: params.type, list });
    }

    @Action({ rawError: true })
    updateSuggestionsMultipleHintsListByType(params: { list: InputSuggestionMultipleList; type: string }): void {
        this.context.commit('SET_SUGGESTIONS_MULTIPLE_SELECTED_LIST_BY_TYPE', {
            type: params.type,
            list: params.list
                .filter((item: InputSuggestionMultipleItem) => item.selected)
                .map((item: InputSuggestionMultipleItem) => item.id),
        });
        this.context.commit('SET_SUGGESTIONS_MULTIPLE_LIST_BY_TYPE', { type: params.type, list: params.list });
    }

    @Action({ rawError: true })
    async updateSuggestionsSearchByType(params: { search: string; type: string; filterName?: string }) {
        this.context.dispatch(this.model[params.type].actionSearch, params.search, { root: true });
    }

    @Action({ rawError: true })
    async updateSelectedCountry(selected: InputSuggestionItem) {
        this.context.commit('SET_SUGGESTIONS_VALUE_BY_TYPE', { value: selected.value, type: 'country' });
        this.context.commit('SET_SUGGESTIONS_SELECTED_BY_TYPE', { selected, type: 'country' });

        this.context.commit('SET_SUGGESTIONS_VALUE_BY_TYPE', { value: '', type: 'city' });
        this.context.commit('SET_SUGGESTIONS_SELECTED_BY_TYPE', { selected: { id: 0, value: '' }, type: 'city' });

        const table = await getCitiesList(
            1,
            'sort[0][id]=name&sort[0][value]=ASC',
            `&filters[0][id]=country&filters[0][value]=${selected.id}`,
            50,
        );
        this.context.commit('SET_SUGGESTIONS_HINTS_LIST_BY_TYPE', {
            list: await processListForSelect(table.rows),
            type: 'city',
        });
    }

    @Action({ rawError: true })
    async searchCountry(search: string) {
        this.context.commit('SET_SUGGESTIONS_VALUE_BY_TYPE', { value: search, type: 'country' });
        const table = await getCountriesList(
            1,
            'sort[0][id]=name&sort[0][value]=ASC',
            `&filters[0][id]=name&filters[0][value]=${search}`,
            50,
        );
        this.context.commit('SET_SUGGESTIONS_HINTS_LIST_BY_TYPE', {
            list: await processListForSelect(table.rows),
            type: 'country',
        });
    }

    @Action({ rawError: true })
    async getPhoneCodes() {
        let codes = await getPhoneCodesList();

        const codesNumbersSet = new Set();
        codes = codes.rows
            .filter((country: { phoneCode: string }) => {
                if (country.phoneCode && !codesNumbersSet.has(country.phoneCode)) {
                    codesNumbersSet.add(country.phoneCode);

                    return true;
                }

                return false;
            })
            .map((country: { phoneMask: string; phoneCode: string; id: string }) => ({
                id: country.id,
                code: country.phoneCode,
                mask: country.phoneMask,
            }));
        this.context.commit('SET_CODES', codes);
    }

    @Action({ rawError: true })
    resetSuggestionsStateByType(type: string) {
        this.context.commit('SET_SUGGESTIONS_VALUE_BY_TYPE', { value: '', type });
        this.context.commit('SET_SUGGESTIONS_SELECTED_BY_TYPE', { selected: { id: 0, value: '' }, type });
        this.context.commit('SET_SUGGESTIONS_HINTS_LIST_BY_TYPE', { list: [], type });
    }

    @Action({ rawError: true })
    async searchCity(search: string) {
        this.context.commit('SET_SUGGESTIONS_VALUE_BY_TYPE', { value: search, type: 'city' });
        let filter = `&filters[0][id]=name&filters[0][value]=${search}`;
        filter += this.model.country.current.id
            ? `&filters[1][id]=country&filters[1][value]=${this.model.country.current.id}`
            : '';
        const table = await getCitiesList(1, 'sort[0][id]=name&sort[0][value]=ASC', filter, 50);
        this.context.commit('SET_SUGGESTIONS_HINTS_LIST_BY_TYPE', {
            list: await processListForSelect(table.rows),
            type: 'city',
        });
    }

    @Action({ rawError: true })
    updateName(name: string) {
        this.context.commit('SET_NAME', name);

        if (this.model.name.required) {
            this.context.dispatch('setValidateState', { index: 'name', field: this.model.name });
        }
    }

    @Action({ rawError: true })
    updateSalary(salary: string) {
        this.context.commit('SET_SALARY', salary);

        if (this.model.salary.required) {
            this.context.dispatch('setValidateState', { index: 'salary', field: this.model.salary });
        }
    }

    @Action({ rawError: true })
    updateExternalSalary(salary: string) {
        this.context.commit('SET_EXTERNAL_SALARY', salary);

        if (this.model.externalSalary.required) {
            this.context.dispatch('setValidateState', { index: 'externalSalary', field: this.model.externalSalary });
        }
    }

    @Action({ rawError: true })
    updateLastName(lastName: string) {
        this.context.commit('SET_LAST_NAME', lastName);

        if (this.model.lastName.required) {
            this.context.dispatch('setValidateState', { index: 'lastName', field: this.model.lastName });
        }
    }

    @Action({ rawError: true })
    updateFirstName(firstName: string) {
        this.context.commit('SET_FIRST_NAME', firstName);

        if (this.model.firstName.required) {
            this.context.dispatch('setValidateState', { index: 'firstName', field: this.model.firstName });
        }
    }

    @Action({ rawError: true })
    updateMiddleName(middleName: string) {
        this.context.commit('SET_MIDDLE_NAME', middleName);
    }

    @Action({ rawError: true })
    updateBirthday(birthday: string) {
        this.context.commit('SET_BIRTHDAY', birthday);
    }

    @Action({ rawError: true })
    updatePhone(phone: any) {
        this.context.commit('SET_PHONE', phone);

        if (this.model.phone.required) {
            this.context.dispatch('setValidateState', { index: 'phone', field: this.model.phone });
        }
    }

    @Action({ rawError: true })
    updateEmail(email: string) {
        this.context.commit('SET_EMAIL', email);
    }

    @Action({ rawError: true })
    updateComment(comment: string) {
        this.context.commit('SET_COMMENT', comment);
    }

    @Action({ rawError: true })
    updateVacancies(vacancies: string) {
        this.context.commit('SET_VACANCIES', vacancies);
    }

    @Action({ rawError: true })
    updateParty(party: string) {
        this.context.commit('SET_PARTY', party);
    }

    @Action({ rawError: true })
    updateRegionsList(list: {}) {
        this.context.commit('SET_REGIONS', list);
    }

    @Action({ rawError: true })
    updateRegion(selected: {}) {
        this.context.commit('SET_REGION', selected);
    }

    @Action({ rawError: true })
    updateInn(inn: string) {
        this.context.commit('SET_INN', inn);
    }

    @Action({ rawError: true })
    updateKpp(kpp: string) {
        this.context.commit('SET_KPP', kpp);
    }

    @Action({ rawError: true })
    updateBank(bank: string) {
        this.context.commit('SET_BANK', bank);
    }

    @Action({ rawError: true })
    updatePaymentAccount(value: string) {
        this.context.commit('SET_PAYMENT_ACCOUNT', value);
    }

    @Action({ rawError: true })
    updateKS(ks: string) {
        this.context.commit('SET_KS', ks);
    }

    @Action({ rawError: true })
    updateBic(bic: string) {
        this.context.commit('SET_BIC', bic);
    }

    @Action({ rawError: true })
    updateIsLoading(isLoading: boolean) {
        this.context.commit('SET_IS_LOADING', isLoading);
    }

    @Action({ rawError: true })
    updateIsActive(bool: boolean | null) {
        this.context.commit('SET_IS_ACTIVE', bool);
    }

    @Action({ rawError: true })
    updateTopSeller(bool: boolean | null) {
        this.context.commit('SET_TOP_SELLER', bool);
    }

    @Action({ rawError: true })
    updatePublished(bool: boolean | null) {
        this.context.commit('SET_PUBLISHED', bool);
    }

    @Action({ rawError: true })
    updateAmount(amount: string | number) {
        this.context.commit('SET_AMOUNT', amount);
    }

    @Action({ rawError: true })
    updateWeeks(weeks: string | number) {
        this.context.commit('SET_WEEKS', weeks);
    }

    @Action({ rawError: true })
    updateDescription(value: string) {
        this.context.commit('SET_DESCRIPTION', value);
    }

    @Action({ rawError: true })
    async updatePhotoFile(file: File) {
        this.context.commit('SET_PHOTO_FILE', file);

        const preview = await imageFileToBase64(file);
        this.context.commit('SET_PHOTO_PREVIEW', preview);
    }

    @Action({ rawError: true })
    async updateModelImageFieldFile(params: { field: string; file: File }): Promise<void> {
        this.context.commit('SET_MODEL_IMAGE_FIELD_FILE', { field: params.field, file: params.file });

        const preview = await imageFileToBase64(params.file);
        this.context.commit('SET_MODEL_IMAGE_FIELD_PREVIEW', { field: params.field, base64String: preview });
    }

    @Action({ rawError: true })
    removeImagePreview() {
        this.context.commit('SET_PHOTO_FILE', null);
        this.context.commit('SET_PHOTO_PREVIEW', '');
    }

    @Action({ rawError: true })
    removeImage() {
        this.context.commit('SET_PHOTO', '');
    }

    @Action({ rawError: true })
    async updatePhotoFileByType(params: { file: File; type: string }) {
        const TYPE = params.type.toUpperCase();
        this.context.commit(`SET_${TYPE}_PHOTO_FILE`, params.file);

        const preview = await imageFileToBase64(params.file);
        this.context.commit(`SET_${TYPE}_PHOTO_PREVIEW`, preview);
    }

    @Action({ rawError: true })
    removeImagePreviewByType(type: string) {
        this.context.commit('SET_MODEL_IMAGE_FIELD_FILE', { field: type, file: null });
        this.context.commit('SET_MODEL_IMAGE_FIELD_PREVIEW', { field: type, base64String: '' });
    }

    @Action({ rawError: true })
    removeImageByType(type: string) {
        this.context.commit(`SET_${type.toUpperCase()}_PHOTO`, '');
    }

    @Action({ rawError: true })
    processDataForMultiSelect(params: { data: any; key: string; handlerList: string; handlerValue: string }) {
        type dataItem = {
            createdAt: string;
            id: number;
            isActive: boolean | null;
            name: string;
            updatedAt: string;
        };

        let value = '';
        const values: string[] = [];
        const multiSelectList = (this.model[params.key] as MultiSelect).list;
        const resultList = Object.values(multiSelectList).map(
            (resultItem: { id: number; name: string; selected: boolean }) => {
                const match = params.data.filter((item: dataItem) => item.id === resultItem.id)[0];
                if (match) {
                    resultItem.selected = true;
                    values.push(resultItem.name);
                }

                return resultItem;
            },
        );

        value = values.length > 0 ? values.join(', ') : '';

        this.context.commit(params.handlerList, resultList);
        this.context.commit(params.handlerValue, value);
    }

    @Action({ rawError: true })
    getRequiredFields() {
        return Object.keys(this.model).filter((key) => this.model[key]?.required === true);
    }

    @Action({ rawError: true })
    async getFieldsWithError(fields?: any[]) {
        fields = fields ?? (await this.context.dispatch('getRequiredFields'));

        if (!fields) {
            throw new Error("value of 'fields' is undefined");
        }

        return fields.filter((field) => this.model[field]?.error?.class === 'cp-error');
    }

    @Action({ rawError: true })
    setValidateEmail(entity: { index: string; field: any }) {
        const substring = entity.field.value.substring(entity.field.value.indexOf('@'));
        const dotIndex = substring.indexOf('.');

        if (!entity.field.value) {
            this.context.commit('SET_ERROR_BY_FIELD', { field: entity.index, message: entity.field.validation.empty });
        } else if (
            entity.field.value &&
            entity.field.value.length > 0 &&
            (dotIndex === -1 || dotIndex === substring.length - 1)
        ) {
            this.context.commit('SET_ERROR_BY_FIELD', { field: entity.index, message: entity.field.validation.regex });
        } else if (entity.field.value) {
            this.context.commit('RESET_ERROR_BY_FIELD', entity.index);
        }
    }

    @Action({ rawError: true })
    setValidateState(entity: { index: string; field: Input | InputDatePicker }): void {
        const entityFieldInput = entity.field as Input;
        if (entityFieldInput.localization && !entityFieldInput.localization.ru) {
            this.context.commit('SET_ERROR_BY_FIELD', { field: entity.index, message: entity.field.validation.empty });

            return;
        }

        if (!entity.field.value && !entityFieldInput.localization) {
            this.context.commit('SET_ERROR_BY_FIELD', { field: entity.index, message: entity.field.validation.empty });

            return;
        }

        this.context.commit('RESET_ERROR_BY_FIELD', entity.index);
    }

    @Action({ rawError: true })
    setValidatePhone(params: { index: string; field: any }) {
        if (!params.field.value) {
            this.context.commit('SET_ERROR_BY_FIELD', { field: params.index, message: params.field.validation.empty });
        } else if (params.field.mask.minChars && clearPhone(params.field.value).length !== params.field.mask.minChars) {
            this.context.commit('SET_ERROR_BY_FIELD', { field: params.index, message: params.field.validation.length });
        } else if (params.field.mask.placeholder && params.field.value.indexOf(params.field.mask.placeholder) !== -1) {
            this.context.commit('SET_ERROR_BY_FIELD', { field: params.index, message: params.field.validation.length });
        } else {
            this.context.commit('RESET_ERROR_BY_FIELD', params.index);
        }
    }

    @Action({ rawError: true })
    setValidateBirthday(params: { index: string; field: any }) {
        this.context.commit('RESET_ERROR_BY_FIELD', params.index);
        if (!params.field.value) {
            this.context.commit('SET_ERROR_BY_FIELD', { field: params.index, message: params.field.validation.empty });

            return;
        }
        if (params.field.value.match(/\d{2}\.\d{2}\.\d{4}/) === null) {
            this.context.commit('SET_ERROR_BY_FIELD', { field: params.index, message: params.field.validation.regex });

            return;
        }

        const ageLimitMin = moment().startOf('day').subtract(18, 'year');
        const ageLimitMax = moment().startOf('day').subtract(100, 'year');

        const birthday = moment(params.field.value, 'DD.MM.YYYY');
        const isValid = birthday.isSameOrBefore(ageLimitMin) && birthday.isSameOrAfter(ageLimitMax);

        if (isValid === false) {
            this.context.commit('SET_ERROR_BY_FIELD', { field: params.index, message: params.field.validation.limit });
        }
        if (isValid === true) {
            this.context.commit('RESET_ERROR_BY_FIELD', params.index);
        }
    }

    @Action({ rawError: true })
    setValidateSearchInput(params: { index: string; field: any }) {
        if (Object.values(params.field.list).length === 0) {
            this.context.commit('SET_ERROR_BY_FIELD', { field: params.index, message: params.field.validation.empty });
        } else if (Object.values(params.field.list).length > 0) {
            this.context.commit('RESET_ERROR_BY_FIELD', params.index);
        }
    }

    @Action({ rawError: true })
    setValidatePaymentAccount(params: { index: string; field: any }) {
        if (!params.field.value) {
            this.context.commit('SET_ERROR_BY_FIELD', { field: params.index, message: params.field.validation.empty });

            return;
        }
        if (params.field.value[0] !== '4') {
            this.context.commit('SET_ERROR_BY_FIELD', { field: params.index, message: params.field.validation.regex });

            return;
        }
        if (params.field.value.length !== params.field.mask.minChars) {
            this.context.commit('SET_ERROR_BY_FIELD', { field: params.index, message: params.field.validation.length });

            return;
        }

        this.context.commit('RESET_ERROR_BY_FIELD', params.index);
    }

    @Action({ rawError: true })
    setValidateSelect(entity: { index: string; field: any }) {
        if (!entity.field.current.value || entity.field.current.value.length === 0) {
            this.context.commit('SET_ERROR_BY_FIELD', { field: entity.index, message: entity.field.validation.empty });
        } else {
            this.context.commit('RESET_ERROR_BY_FIELD', entity.index);
        }
    }

    @Action({ rawError: true })
    setValidateMultiSuggestions(entity: { index: string; field: any }): any {
        if (!entity.field.hintsList.filter((category: InputSuggestionMultipleItem) => category.selected).length) {
            this.context.commit('SET_ERROR_BY_FIELD', { field: entity.index, message: entity.field.validation.empty });
        } else {
            this.context.commit('RESET_ERROR_BY_FIELD', entity.index);
        }
    }

    @Action({ rawError: true })
    setValidateRadio(entity: { index: string; field: any }) {
        if (!entity.field.value || entity.field.value.length === 0) {
            this.context.commit('SET_ERROR_BY_FIELD', { field: entity.index, message: entity.field.validation.empty });
        } else {
            this.context.commit('RESET_ERROR_BY_FIELD', entity.index);
        }
    }

    @Action({ rawError: true })
    setValidateTimezone(entity: { index: string; field: any }) {
        if (!entity.field.value) {
            this.context.commit('SET_ERROR_BY_FIELD', { field: entity.index, message: entity.field.validation.empty });
        } else if (!entity.field.value.match(/^(?:Z|[+-](?:2[0-3]|[01][0-9]):[0-5][0-9])$/)?.length) {
            this.context.commit('SET_ERROR_BY_FIELD', { field: entity.index, message: entity.field.validation.regex });
        } else {
            this.context.commit('RESET_ERROR_BY_FIELD', entity.index);
        }
    }

    @Action({ rawError: true })
    setValidateImageCollection(entity: { index: string; field: any }) {
        if (!entity.field.list.length) {
            this.context.commit('SET_ERROR_BY_FIELD', { field: entity.index, message: entity.field.validation.empty });
        } else {
            this.context.commit('RESET_ERROR_BY_FIELD', entity.index);
        }
    }
}
