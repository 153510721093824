<template>
    <div class="mr-side-menu__wrapper hide cp-fixed">
        <div class="mr-side-menu__scroll-wrapper">
            <div class="mr-side-menu-top__wrapper">
                <nav class="mr-side-menu-list__wrapper">
                    <slot v-for="(menuItem, index) in menuItems">
                        <router-link
                            v-if="
                                accessByMenuItemName[menuItem.path] &&
                                accessByMenuItemName[menuItem.path].read.includes(role)
                            "
                            :key="index"
                            :to="{ name: menuItem.path, params: menuItem.params || {} }"
                            class="mr-side-menu-item__wrapper"
                            :class="{ 'router-link-exact-active': currentRoot.indexOf(menuItem.code) !== -1 }"
                        >
                            <div
                                v-if="menuItem.hint.length > 0"
                                class="mr-side-menu-item__hint"
                                :class="menuItem.hintClass"
                            >
                                {{ menuItem.hint }}
                            </div>

                            <span class="mr-nav-icons__wrapper">
                                <icon-font
                                    :class="index"
                                    class="mr-nav-icons"
                                    :size="menuItem.iconSize"
                                    :icon="menuItem.class"
                                    :special="menuItem.specialIcon"
                                />
                            </span>

                            <span class="mr-side-menu-item">{{ menuItem.name }}</span>
                        </router-link>
                    </slot>
                </nav>

                <div class="cp-user-info__wrapper">
                    <div class="cp-user-info">
                        <div class="cp-user-info--icon">
                            <div class="cp-user-info--icon__wrapper" @click="logOut">
                                <icon-font icon="logout" size="19" class="" />

                                <span class="cp-user-name__wrapper cp-side-menu--hide">
                                    <span class="cp-user-name">{{ fullName }}</span>
                                    <span>{{ humanRole }}</span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import IconFont from '@/components/icons/IconFont.vue';

import AuthModule from '@/admin/store/auth';
import menuModule from '@/admin/store/menu';

import RoleModule from '@/admin/store/role';

export default {
    name: 'SideMenu',
    components: { IconFont },
    props: {
        menuItems: {
            type: Object,
            default() {
                return {
                    main: {
                        name: 'Главная',
                        path: 'home',
                        code: 'COMMON',
                        isActive: false,
                        class: 'home',
                    },
                    users: {
                        name: 'Пользователи',
                        path: 'users',
                        code: 'USER',
                        isActive: false,
                        class: 'users',
                    },
                };
            },
        },
    },
    data() {
        return {
            isShow: true,
        };
    },
    computed: {
        ...mapState({
            fullName: (state) => state.user.name,
            humanRole: (state) => state.user.humanRole,
            role: (state) => state.user.role,
            accessByMenuItemName: (state) => state.role.accessByMenuItemName,
        }),
        currentRoot() {
            const paths = this.$route.path.split('/');

            return paths[1];
        },
    },
    methods: {
        async logOut() {
            await AuthModule.logoutRequest();
            window.location.reload();
        },
        showChildMenu() {
            this.$el.querySelector('.cp-side-menu-item-children__wrapper').classList.add('cp-show');
        },
        hideChildMenu() {
            this.$el.querySelector('.cp-side-menu-item-children__wrapper').classList.remove('cp-show');
        },
    },
};
</script>
