import InputEntity from '@/lib/formFactory/inputEntity';
import SelectEntity from '@/lib/formFactory/selectEntity';
import CheckboxEntity from '@/lib/formFactory/checkboxEntity';
import RadioEntity from '@/lib/formFactory/radioEntity';
import ImageCollectionEntity from '@/lib/formFactory/imageCollectionEntity';
import InputSuggestionsEntity from '@/lib/formFactory/inputSuggestionsEntity';
import { LotModelInterface } from '@/interfaces/models/lot.interface';
import InputSuggestionMultipleEntity from '@/lib/formFactory/inputSuggestionsMultipleEntity';

export default class EntityModel {
    model: LotModelInterface;

    constructor() {
        const nameEntity = new InputEntity('name');
        nameEntity.setTitle('Название лота');
        nameEntity.setRequired(true);
        nameEntity.initLocalization();

        const descriptionEntity = new InputEntity('description');
        descriptionEntity.setTitle('Описание');
        descriptionEntity.initLocalization();

        const categoryEntity = new InputSuggestionMultipleEntity('category');
        categoryEntity.setTitle('Категория');
        categoryEntity.setRequired(true);

        const categorySelectEntity = new SelectEntity('category');
        categorySelectEntity.setTitle('Категория');
        categorySelectEntity.setRequired(true);

        const auctionEntity = new InputSuggestionsEntity('catalogAuction');
        auctionEntity.setTitle('Каталог');
        auctionEntity.setRequired(true);

        const auctionHouseEntity = new InputSuggestionsEntity('auctionHouse');
        auctionHouseEntity.setTitle('Аукционный дом');
        auctionHouseEntity.setRequired(true);

        const painterEntity = new InputSuggestionsEntity('painter');
        painterEntity.setTitle('Автор');
        painterEntity.setRequired(true);

        const galleryEntity = new InputSuggestionsEntity('gallery');
        galleryEntity.setTitle('Название галереи');
        galleryEntity.setRequired(true);

        const sortEntity = new InputEntity('sort');
        sortEntity.setTitle('Порядок сортировки');

        const lotNumberEntity = new InputEntity('lotNumber');
        lotNumberEntity.setTitle('Номер лота');
        lotNumberEntity.setRequired(true);

        const isRightToFollowEntity = new CheckboxEntity('isRightToFollow');
        isRightToFollowEntity.setName('Право следования');
        isRightToFollowEntity.setList({
            0: {
                id: 1,
                name: 'Право следования',
                checked: false,
            },
        });

        const currencyEntity = new SelectEntity('currency');
        currencyEntity.setTitle('Валюта лота');

        const exchangeRateTypeEntity = new RadioEntity('exchangeRateType');
        exchangeRateTypeEntity.setList([
            { id: 0, name: 'Индикатив', value: 'indicative' },
            { id: 1, name: 'По курсу ЦБ', value: 'central_bank' },
        ]);

        const buyNowPriceTypeEntity = new RadioEntity('buyNowPriceType');
        buyNowPriceTypeEntity.setList([
            { id: 0, name: 'Равен стартовой цене', value: 'start' },
            { id: 1, name: 'Равен резервной цене', value: 'reserve' },
            { id: 2, name: 'Указать вручную', value: 'manual' },
        ]);
        buyNowPriceTypeEntity.setRequired(true);

        const isPostauctionSaleEntity = new CheckboxEntity('isPostauctionSale');
        isPostauctionSaleEntity.setName('Возможна остаукционная продажа');
        isPostauctionSaleEntity.setList({
            0: {
                id: 1,
                name: 'Возможна постаукционная продажа',
                checked: false,
            },
        });

        const isPriceReserveEntity = new CheckboxEntity('isPriceReserve');
        isPriceReserveEntity.setName('Резервная цена');
        isPriceReserveEntity.setList({
            0: {
                id: 1,
                name: 'Резервная цена',
                checked: false,
            },
        });

        const sizesEntity = new InputEntity('sizes');
        sizesEntity.setTitle('Размеры');

        const yearEntity = new InputEntity('year');
        yearEntity.setTitle('Время создания');
        yearEntity.initLocalization();

        const materialEntity = new InputEntity('material');
        materialEntity.setTitle('Материал, состав');
        materialEntity.setSubTitle('Например, для картин - Масло, Холст');
        materialEntity.initLocalization();

        const pricesEstimateMinEntity = new InputEntity('pricesEstimateMin');
        pricesEstimateMinEntity.setTitle('мин.');

        const pricesEstimateMaxEntity = new InputEntity('pricesEstimateMin');
        pricesEstimateMaxEntity.setTitle('макс.');

        const authorEntity = new InputSuggestionsEntity('author');
        authorEntity.setTitle('Начните вводить имя автора');

        const photosEntity = new ImageCollectionEntity('photos');
        photosEntity.setTitle('Фото');
        photosEntity.setRequired(true);

        const hideImageEntity = new CheckboxEntity('hideImage');
        hideImageEntity.setList({
            0: {
                id: 1,
                name: 'После торгов скрыть изображения',
                checked: false,
            },
        });

        const hidePriceEntity = new CheckboxEntity('hidePrice');
        hidePriceEntity.setList({
            0: {
                id: 1,
                name: 'После торгов скрыть цену',
                checked: false,
            },
        });

        const slugEntity = new InputEntity('slug');
        slugEntity.setTitle('Slug');

        const allowedCurrenciesEntity = new InputSuggestionMultipleEntity('allowedCurrencies');
        allowedCurrenciesEntity.setTitle('Валюты');

        const startPriceEntity = new InputEntity('startPrice');
        startPriceEntity.setTitle('Стартовая цена');

        const maxPriceEntity = new InputEntity('maxPrice');
        maxPriceEntity.setTitle('Максимальная цена');

        const priceBuyNowEntity = new InputEntity('priceBuyNow');
        priceBuyNowEntity.setTitle('Цена');

        const painterStartPriceEntity = new InputEntity('painterStartPrice');

        const painterMaxPriceEntity = new InputEntity('painterMaxPrice');

        const painterBuyNowPriceEntity = new InputEntity('painterStartPrice');

        const priceMaxEntity = new InputEntity('priceMax');
        const priceMinEntity = new InputEntity('priceMin');
        const priceReserveEntity = new InputEntity('priceReserve');
        const priceSellEntity = new InputEntity('priceSell');
        const priceStartEntity = new InputEntity('priceStart');

        this.model = {
            name: nameEntity.entity[nameEntity.entityName],
            description: descriptionEntity.entity[descriptionEntity.entityName],
            category: categoryEntity.entity[categoryEntity.entityName],
            categorySelect: categorySelectEntity.entity[categorySelectEntity.entityName],
            auction: auctionEntity.entity[auctionEntity.entityName],
            auctionHouse: auctionHouseEntity.entity[auctionHouseEntity.entityName],
            sellerPainter: painterEntity.entity[painterEntity.entityName],
            sellerGallery: galleryEntity.entity[galleryEntity.entityName],
            sort: sortEntity.entity[sortEntity.entityName],
            lotNumber: lotNumberEntity.entity[lotNumberEntity.entityName],
            isRightToFollow: isRightToFollowEntity.entity[isRightToFollowEntity.entityName],
            slug: slugEntity.entity[slugEntity.entityName],
            createdAt: '',
            datetimePosting: '',
            dateTimeStartTrad: '',
            dateTimeFinishTrad: '',
            currency: currencyEntity.entity[currencyEntity.entityName],
            exchangeRateType: exchangeRateTypeEntity.entity[exchangeRateTypeEntity.entityName],
            isPostauctionSale: isPostauctionSaleEntity.entity[isPostauctionSaleEntity.entityName],
            isPriceReserve: isPriceReserveEntity.entity[isPriceReserveEntity.entityName],
            bidStep: '',
            sizes: sizesEntity.entity[sizesEntity.entityName],
            year: yearEntity.entity[yearEntity.entityName],
            material: materialEntity.entity[materialEntity.entityName],
            pricesEstimateMin: pricesEstimateMinEntity.entity[pricesEstimateMinEntity.entityName],
            pricesEstimateMax: pricesEstimateMaxEntity.entity[pricesEstimateMaxEntity.entityName],
            priceMax: priceMaxEntity.entity[priceMaxEntity.entityName],
            priceMin: priceMinEntity.entity[priceMinEntity.entityName],
            priceReserve: priceReserveEntity.entity[priceReserveEntity.entityName],
            priceBuyNow: priceBuyNowEntity.entity[priceBuyNowEntity.entityName],
            priceSell: priceSellEntity.entity[priceSellEntity.entityName],
            priceStart: priceStartEntity.entity[priceStartEntity.entityName],
            buyNowPriceType: buyNowPriceTypeEntity.entity[buyNowPriceTypeEntity.entityName],
            photos: photosEntity.entity[photosEntity.entityName],
            author: authorEntity.entity[authorEntity.entityName],
            commissionAcquiring: 0,
            commissionAuctionHouse: 0,
            commissionFinancialServices: 0,
            commissionRightToFollow: 0,
            commissionSite: 0,
            userWinner: null,
            status: null,
            statusName: null,
            bidsCount: 0,
            orderId: 0,
            hideImage: hideImageEntity.entity[hideImageEntity.entityName],
            hidePrice: hidePriceEntity.entity[hidePriceEntity.entityName],
            exchangeRates: {
                values: {},
                disabled: false,
            },
            allowedCurrencies: allowedCurrenciesEntity.entity[allowedCurrenciesEntity.entityName],
            startPrice: startPriceEntity.entity[startPriceEntity.entityName],
            maxPrice: maxPriceEntity.entity[maxPriceEntity.entityName],
            painterStartPrice: painterStartPriceEntity.entity[painterStartPriceEntity.entityName],
            painterMaxPrice: painterMaxPriceEntity.entity[painterMaxPriceEntity.entityName],
            painterBuyNowPrice: painterBuyNowPriceEntity.entity[painterBuyNowPriceEntity.entityName],
        };
    }
}
