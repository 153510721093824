/*
 *
 * */

import { SelectEntityInterface } from '@/interfaces/entities/selectEntity';
import { Select, SelectList, SelectItem } from '@/lib/formFactory/select.interface';

export default class SelectEntity implements SelectEntityInterface {
    defaultValue: Select;
    entityName: string;
    entity: any;

    constructor(name: string) {
        this.defaultValue = {
            key: name,
            type: 'select',
            title: '',
            list: [],
            current: {
                id: '',
                value: '',
            },
            required: false,
            disabled: false,
            error: {
                class: '',
                message: '',
            },
            validation: {
                empty: '',
                regex: '',
            },
        };
        this.entityName = name;
        this.entity = { [this.entityName]: this.defaultValue };
    }

    setTitle(title: string) {
        this.entity[this.entityName]['title'] = title;
    }

    setList(list: SelectList) {
        this.entity[this.entityName]['list'] = list;
    }

    setCurrent(selected: SelectItem) {
        this.entity[this.entityName]['current'] = selected;
    }

    setAction(action: string) {
        this.entity[this.entityName]['action'] = action;
    }

    setClearAction(action: string) {
        this.entity[this.entityName]['clearAction'] = action;
    }

    setRequired(bool: boolean) {
        this.entity[this.entityName]['required'] = bool;
    }

    setValidation(validation: any) {
        this.entity[this.entityName]['validation'] = validation;
    }

    setDisabled(bool: boolean) {
        this.entity[this.entityName]['disabled'] = bool;
    }
}
