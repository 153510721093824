
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import AppMenu from '@/admin/layouts/partials/AppMenu.vue';
import menuModule from '@/admin/store/menu';
import redirectModule from '@/admin/store/modules/redirectHandler';

@Component({ components: { AppMenu } })
export default class DefaultLayout extends Vue {
    @Prop({ required: false }) readonly width?: number | string;

    isMenuOpen = false;

    get menuItems() {
        return menuModule.menuItems;
    }
    get redirectUrl() {
        return redirectModule.url;
    }
    @Watch('redirectUrl')
    onRedirect(): void {
        redirectModule.redirect(this.$router);
    }
}
