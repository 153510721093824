export default {
    averageCostOfSold: {
        title: 'Средняя стоимость проданных лотов',
        value: 0,
        text: '₽',
    },
    purchasedCount: {
        title: 'Соотношение покупок и продаж за 6 мес.',
        value: 0,
        text: 'куплено',
    },
    soldCount: {
        title: '',
        value: 0,
        text: 'продано',
    },
};
