import { Module, getModule, Action, Mutation } from 'vuex-module-decorators';
import EntityBaseModule from '../../entity';
import store from '@/admin/store';
import {
    getCatalogAuctionCard,
    editCatalogAuctionCard,
    createCatalogAuctionCard,
    cloneCatalog,
    unpublishCatalog,
} from '@/admin/api/catalogAuction';
import EntityModel, { AuctionTypeEnum } from './entityCommonModel';
import { getCitiesList } from '@/admin/api/city';
import { formatCreatedAt, formatDateToDefault, formatDefaultDateTo, formatNumberSpaced, formatRemoveSpaces, getApiErrorMessage, showError } from '@/lib/utils/Utils';
import catalogsAuctionsEntity from './entity';

import ResponseHandlerModule from '@/admin/store/modules/responseHandler';
import RedirectHandlerModule from '@/admin/store/modules/redirectHandler';
import { RadioItem } from '@/lib/formFactory/radio.interface';
import { processListForSelect, processListForSuggestionsMultiple } from '@/lib/utils/form';
import { InputSuggestionItem } from '@/lib/formFactory/inputSuggestion.interface';
import { getCountriesList } from '@/admin/api/country';
import { getAuctionHouseCard, getAuctionHouseList } from '@/admin/api/auctionHouse';

import SiteModule from '@/admin/store/site';
import CurrencyModule from '@/admin/store/currency';
import { getSettingAuction } from '@/admin/api/settingAuction';
import responseHandler from '@/admin/store/modules/responseHandler';
import { prepareLocalization, processLocalizationFromBackend } from '@/lib/utils/localization';
import { InputSuggestionMultipleItem } from '@/lib/formFactory/inputSuggestionMultiple.interface';
import { CatalogModelInterface, LotTimeSecondsInterface } from '@/interfaces/models/catalog.interface';
import { Input } from '@/lib/formFactory/input.interface';
import { getCurrenciesWithoutRuble } from '@/lib/utils/currency';

const MODULE_NAME = 'catalogsAuctionsCommon';

const currenciesReducer = (accum: number[], current: InputSuggestionMultipleItem) => {
    if (current.selected) {
        accum.push(current.id as number);
    }

    return accum;
};

export enum CatalogStatusEnum {
    DRAFT = 'draft',
    PUBLISHED = 'published',
    RUN = 'run',
    POST_SALE = 'post_sale',
    STATUS_COMPLETED = 'completed',
    REMOVED_FROM_PUBLICATION = 'removed_from_publication',
    DELETED = 'deleted',
}

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class CatalogsAuctionsCommonEntityModule extends EntityBaseModule {
    cardId: number | null = null;
    model: CatalogModelInterface;
    selectDefault = { id: 0, value: '' };
    titleReturnData = {
        edit: {
            icon: 'book',
            title: '',
        },
        create: {
            icon: '',
            title: 'Добавление Каталога',
        },
    };
    requestSort = 'sort[0][id]=name&sort[0][value]=ASC';
    isAllowRemove = false;
    isAllowEdit = false;
    isAllowPublish = false;
    isAllowUnpublish = false;
    isAllowClone = false;

    constructor(module: CatalogsAuctionsCommonEntityModule) {
        super(module);

        const entityModel = new EntityModel();
        this.model = entityModel.model;
    }

    @Mutation
    SET_IS_ALLOW_REMOVE(bool: boolean) {
        this.isAllowRemove = bool;
    }

    @Mutation
    SET_IS_ALLOW_EDIT(bool: boolean) {
        this.isAllowEdit = bool;
    }

    @Mutation
    SET_IS_ALLOW_PUBLISH(bool: boolean) {
        this.isAllowPublish = bool;
    }

    @Mutation
    SET_IS_ALLOW_UNPUBLISH(bool: boolean) {
        this.isAllowUnpublish = bool;
    }

    @Mutation
    SET_IS_ALLOW_CLONE(bool: boolean) {
        this.isAllowClone = bool;
    }

    @Mutation
    SET_CARD_ID(id: number | null) {
        this.cardId = id;
    }

    @Mutation
    SET_NAME(name: string) {
        this.model.name.value = name;
    }

    @Mutation
    SET_AUCTION_NAME(name: string) {
        this.titleReturnData.edit.title = name;
    }

    @Mutation
    SET_ADDRESS(address: string) {
        this.model.address.value = address;
    }

    @Mutation
    SET_DATE_POSTING(value: string) {
        this.model.dateTimePosting = value;
    }

    @Mutation
    SET_DATE_FINISH(value: string) {
        this.model.dateTimeFinishTrad = value;
    }

    @Mutation
    SET_DATE_START(value: string) {
        this.model.dateStart.value = value;
    }

    @Mutation
    SET_TIME_START(value: string) {
        const splitted = value.split(':');

        this.model.timeStart.value = value;
        this.model.timeStart.hour = splitted[0];
        this.model.timeStart.minute = splitted[1];
    }

    @Mutation
    SET_TIME_HOUR(hour: number) {
        this.model.timeStart.hour = hour.toString();
        this.model.timeStart.value = `${hour}:00`;
    }

    @Mutation
    SET_TIME_MINUTE(minute: string) {
        this.model.timeStart.minute = minute;
        this.model.timeStart.value = `${this.model.timeStart.hour}:${minute}`;
    }

    @Mutation
    SET_IS_POST_AUCTION_SALE(bool: boolean) {
        this.model.isPostAuctionSale.list[0].checked = bool;
    }

    @Mutation
    SET_POST_AUCTION_SALE_HOURS(value: string) {
        this.model.postAuctionSaleHours.value = value;
    }

    @Mutation
    SET_IS_CORRESPONDENCE_BIDDING(bool: boolean) {
        this.model.isCorrespondenceBidding.list[0].checked = bool;
    }

    @Mutation
    SET_AUCTION_TYPE(value: string) {
        this.model.auctionType.value = value;
    }

    @Mutation
    SET_CURRENCY_LIST(list: InputSuggestionMultipleItem[]) {
        this.model.currency.hintsList = list;
    }

    @Mutation
    SET_EXCHANGE_RATE_TYPE(value: string) {
        this.model.exchangeRateType.value = value;
    }

    @Mutation
    SET_IS_SALE_HOURS_REQUIRED(bool: boolean) {
        this.model.postAuctionSaleHours.required = bool;
    }

    @Mutation
    SET_STREAM_URL(url: string) {
        this.model.streamUrl.value = url;
    }

    @Mutation
    SET_ONE_LOT_TRADE_TIME_SECONDS_BY_TYPE(params: { type: keyof LotTimeSecondsInterface; value: string | number }) {
        (this.model.oneLotTradeTimeSeconds[params.type] as Input).value = String(params.value);
    }

    @Mutation
    SET_RENEWAL_TIME_SECONDS_BY_TYPE(params: { type: keyof LotTimeSecondsInterface; value: string | number }) {
        (this.model.renewalTimeSeconds[params.type] as Input).value = String(params.value);
    }

    @Mutation
    SET_STATUS(status: CatalogStatusEnum) {
        this.model.status = status;
    }

    @Mutation
    SET_EXCHANGE_RATES_VALUE(params: { id: number; value: string }) {
        this.model.exchangeRates.values[params.id].value = params.value;
    }

    @Mutation
    ADD_EXCHANGE_RATE_FIELD(params: { id: number; code: string; symbol: string; value?: string }) {
        this.model.exchangeRates.values[params.id] = {
            id: params.id,
            code: params.code,
            symbol: params.symbol,
            value: params.value || '0',
        };
    }

    @Mutation
    DELETE_EXCHANGE_RATE_FIELD(id?: number) {
        if (!id) {
            this.model.exchangeRates.values = {};

            return;
        }

        delete this.model.exchangeRates.values[id];
    }

    @Action({ rawError: true })
    updateStatus(status: CatalogStatusEnum) {
        this.SET_STATUS(status);

        if (status === CatalogStatusEnum.DRAFT) {
            this.SET_IS_ALLOW_REMOVE(true);
            this.SET_IS_ALLOW_EDIT(true);
            this.SET_IS_ALLOW_PUBLISH(true);
            this.SET_IS_ALLOW_UNPUBLISH(false);
            this.SET_IS_ALLOW_CLONE(false);
        } else if (status === CatalogStatusEnum.PUBLISHED) {
            this.SET_IS_ALLOW_REMOVE(false);
            this.SET_IS_ALLOW_EDIT(true);
            this.SET_IS_ALLOW_PUBLISH(false);
            this.SET_IS_ALLOW_UNPUBLISH(true);
            this.SET_IS_ALLOW_CLONE(false);
        } else if (status === CatalogStatusEnum.RUN) {
            this.SET_IS_ALLOW_REMOVE(false);
            this.SET_IS_ALLOW_EDIT(true);
            this.SET_IS_ALLOW_PUBLISH(false);
            this.SET_IS_ALLOW_UNPUBLISH(false);
            this.SET_IS_ALLOW_CLONE(false);
        } else if (status === CatalogStatusEnum.POST_SALE) {
            this.SET_IS_ALLOW_REMOVE(false);
            this.SET_IS_ALLOW_EDIT(true);
            this.SET_IS_ALLOW_PUBLISH(false);
            this.SET_IS_ALLOW_UNPUBLISH(true);
            this.SET_IS_ALLOW_CLONE(false);
        } else if (status === CatalogStatusEnum.STATUS_COMPLETED) {
            this.SET_IS_ALLOW_REMOVE(false);
            this.SET_IS_ALLOW_EDIT(true);
            this.SET_IS_ALLOW_PUBLISH(false);
            this.SET_IS_ALLOW_UNPUBLISH(true);
            this.SET_IS_ALLOW_CLONE(true);
        } else if (status === CatalogStatusEnum.REMOVED_FROM_PUBLICATION) {
            this.SET_IS_ALLOW_REMOVE(false);
            this.SET_IS_ALLOW_EDIT(true);
            this.SET_IS_ALLOW_PUBLISH(false);
            this.SET_IS_ALLOW_UNPUBLISH(false);
            this.SET_IS_ALLOW_CLONE(true);
        } else {
            this.SET_IS_ALLOW_REMOVE(false);
            this.SET_IS_ALLOW_EDIT(false);
            this.SET_IS_ALLOW_PUBLISH(false);
            this.SET_IS_ALLOW_UNPUBLISH(false);
            this.SET_IS_ALLOW_CLONE(false);
        }

        if (![CatalogStatusEnum.DRAFT, CatalogStatusEnum.DELETED].includes(status)) {
            this.toggleFieldsDisabled(true);

            return;
        }

        this.toggleFieldsDisabled(false);
    }

    @Action({ rawError: true })
    toggleFieldsDisabled(disabled: boolean) {
        Object.keys(this.model).forEach((key) => {
            const typedKey = key as keyof CatalogModelInterface;
            const typedField = this.model[typedKey] as Input;
            if (this.model[typedKey] == null) {
                return;
            }

            if (['name', 'photo', 'description'].includes(key)) {
                return;
            }

            if (typeof typedField === 'object' && 'disabled' in typedField && typedField?.disabled == null) {
                return;
            }

            this.SET_FIELD_DISABLED_BY_TYPE({ type: key, disabled });
        });
    }

    @Action({ rawError: true })
    async initAuctionHouse(auctionHouseId: string) {
        if (Number.isNaN(+auctionHouseId)) {
            this.SET_IS_LOADING(false);

            return;
        }

        await this.updateAuctionDataByAuctionHouse(+auctionHouseId);

        this.SET_FIELD_DISABLED_BY_TYPE({ type: 'sellerAuctionHouse', disabled: true });
        this.SET_IS_LOADING(false);
    }

    @Action({ rawError: true })
    async initForm() {
        this.SET_CURRENCY_LIST(processListForSuggestionsMultiple(getCurrenciesWithoutRuble()));
        this.SET_AUCTION_TYPE('manual');

        const timeAutoTrade = await getSettingAuction('?section=CommonAuction&name=timeAutoTrade');
        this.SET_ONE_LOT_TRADE_TIME_SECONDS_BY_TYPE({
            type: 'auto_in_order',
            value: String(timeAutoTrade[0]?.value) ?? '60',
        });
        this.SET_ONE_LOT_TRADE_TIME_SECONDS_BY_TYPE({ type: 'auto_one_time', value: '5' });
        this.SET_RENEWAL_TIME_SECONDS_BY_TYPE({ type: 'auto_in_order', value: '15' });
        this.SET_RENEWAL_TIME_SECONDS_BY_TYPE({ type: 'auto_one_time', value: '5' });
    }

    @Action({ rawError: true })
    async initCardById(auctionId: number) {
        try {
            this.SET_IS_LOADING(true);
            const result = await getCatalogAuctionCard(auctionId);

            this.setInfo(result);
            this.SET_IS_LOADING(false);
        } catch (error) {
            this.SET_IS_LOADING(false);
            showError(getApiErrorMessage(error));
        }
    }

    @Action({ rawError: true })
    async setInfo(data: any) {
        if (!data) {
            return;
        }

        this.context.commit('SET_CARD_ID', data.id);
        catalogsAuctionsEntity.updateTablsLotsParams({ auctionId: data.id });

        this.context.commit('SET_AUCTION_NAME', data.name);
        this.updateStatus(data.status);
        this.SET_MODEL_FIELD_VALUE({ field: 'slug', value: data.slug || '' });
        this.SET_SUGGESTIONS_SELECTED_BY_TYPE(
            data.city?.country
                ? {
                      selected: { id: data.city.country.id, value: data.city.country.name },
                      type: 'country',
                  }
                : {
                      selected: this.selectDefault,
                      type: 'country',
                  },
        );
        this.SET_SUGGESTIONS_VALUE_BY_TYPE({ value: data.city?.country?.name || '', type: 'country' });
        this.SET_SUGGESTIONS_SELECTED_BY_TYPE(
            data.city?.id
                ? {
                      selected: { id: data.city.id, value: data.city.name },
                      type: 'city',
                  }
                : {
                      selected: this.selectDefault,
                      type: 'city',
                  },
        );
        this.SET_SUGGESTIONS_VALUE_BY_TYPE({ value: data.city?.name || '', type: 'city' });

        this.SET_SUGGESTIONS_SELECTED_BY_TYPE({
            selected: data.sellerAuctionHouse?.id
                ? { id: data.sellerAuctionHouse.id, value: data.sellerAuctionHouse.name }
                : this.selectDefault,
            type: 'sellerAuctionHouse',
        });
        this.SET_SUGGESTIONS_VALUE_BY_TYPE({ value: data.sellerAuctionHouse?.name || '', type: 'sellerAuctionHouse' });

        this.SET_DATE_POSTING(data.dateTimePosting ? formatCreatedAt(data.dateTimePosting) : '');
        this.SET_DATE_FINISH(data.dateTimeFinishTrad ? formatCreatedAt(data.dateTimeFinishTrad) : '');
        this.SET_DATE_START(data.dateTimeStartTrad ? formatDefaultDateTo(data.dateTimeStartTrad, 'DD/MM/YY') : '');
        this.SET_TIME_START(data.dateTimeStartTrad ? formatDefaultDateTo(data.dateTimeStartTrad, 'HH:mm') : '');

        this.SET_MODEL_FIELD_VALUE({ field: 'photo', value: data.photo || '' });
        this.SET_MODEL_IMAGE_FIELD_FILE({ field: 'photo', file: null });
        this.SET_MODEL_IMAGE_FIELD_PREVIEW({ field: 'photo', base64String: '' });

        this.SET_MODEL_FIELD_VALUE({ field: 'coverPhoto', value: data.coverImage || '' });
        this.SET_MODEL_IMAGE_FIELD_FILE({ field: 'coverPhoto', file: null });
        this.SET_MODEL_IMAGE_FIELD_PREVIEW({ field: 'coverPhoto', base64String: '' });

        this.SET_AUCTION_TYPE(data.auctionType ? data.auctionType : this.model.auctionType.list[0].value);
        this.SET_EXCHANGE_RATE_TYPE(
            data.exchangeRateType ? data.exchangeRateType : this.model.exchangeRateType.list[0].value,
        );

        if (data.exchangeRates?.length) {
            data.exchangeRates.forEach((rate: { currency: string; course: number }) => {
                const matchedCurrency = CurrencyModule.currencies.find((currency) => currency.code === rate.currency);

                if (!matchedCurrency) {
                    return;
                }

                this.ADD_EXCHANGE_RATE_FIELD({
                    id: matchedCurrency.id,
                    code: matchedCurrency.code,
                    symbol: matchedCurrency.symbol,
                    value: String(rate.course),
                });
            });
        }

        this.SET_IS_POST_AUCTION_SALE(data.isPostauctionSale ?? false);
        this.SET_POST_AUCTION_SALE_HOURS(data.postAuctionSaleOfLotsHours ?? 0);

        this.SET_IS_CORRESPONDENCE_BIDDING(data.isCorrespondenceBidding ?? false);

        this.updateIsSaleHoursRequired();

        this.SET_STREAM_URL(data.streamUrl ?? '');

        if (data.auctionType === AuctionTypeEnum.AutoInOrder && data.oneLotTradeTimeSeconds) {
            this.SET_ONE_LOT_TRADE_TIME_SECONDS_BY_TYPE({
                type: AuctionTypeEnum.AutoInOrder,
                value: data.oneLotTradeTimeSeconds,
            });
        }

        if (data.auctionType === AuctionTypeEnum.AutoInOrder && data.renewalTimeSeconds) {
            this.SET_RENEWAL_TIME_SECONDS_BY_TYPE({
                type: AuctionTypeEnum.AutoInOrder,
                value: data.renewalTimeSeconds,
            });
        }

        if (data.auctionType === AuctionTypeEnum.AutoOneTime && data.oneLotTradeTimeSeconds) {
            this.SET_ONE_LOT_TRADE_TIME_SECONDS_BY_TYPE({
                type: AuctionTypeEnum.AutoOneTime,
                value: data.oneLotTradeTimeSeconds / 60 / 60 / 24,
            });
        }

        if (data.auctionType === AuctionTypeEnum.AutoOneTime && data.renewalTimeSeconds) {
            this.SET_RENEWAL_TIME_SECONDS_BY_TYPE({
                type: AuctionTypeEnum.AutoOneTime,
                value: data.renewalTimeSeconds / 60,
            });
        }

        this.SET_LOCALIZATION_BY_FIELD({ fieldName: 'name', data: processLocalizationFromBackend(data) });
        this.SET_LOCALIZATION_BY_FIELD({
            fieldName: 'description',
            data: processLocalizationFromBackend(data, 'description'),
        });

        this.SET_LOCALIZATION_BY_FIELD({
            fieldName: 'address',
            data: processLocalizationFromBackend(data, 'address'),
        });

        if (data.lotInterval && !isNaN(data.lotInterval)) {
            const lotIntervalMinutes = Math.round(data.lotInterval / 60);
            this.SET_MODEL_FIELD_VALUE({ field: 'lotInterval', value: formatNumberSpaced(lotIntervalMinutes) || '' });
        }

        if (data.centralBankCurrencies?.length) {
            const hintsListUpdated = this.model.currency.hintsList.map((currencyItem) => {
                const matchedFullCurrency = CurrencyModule.currencies.find(
                    (currency) => currency.id === currencyItem.id,
                );

                return {
                    ...currencyItem,
                    selected: Boolean(data.centralBankCurrencies.includes(matchedFullCurrency?.code)),
                };
            });
            this.SET_CURRENCY_LIST(hintsListUpdated);
        }
    }

    @Action({ rawError: true })
    async updateSelectedCountry(country: InputSuggestionItem) {
        const isPrevent = country.id === (this.model.country.current as InputSuggestionItem).id;

        this.SET_SUGGESTIONS_SELECTED_BY_TYPE({ selected: country, type: 'country' });
        this.SET_SUGGESTIONS_VALUE_BY_TYPE({ value: country.value, type: 'country' });

        if (isPrevent) {
            return;
        }

        this.SET_SUGGESTIONS_HINTS_LIST_BY_TYPE({ list: [], type: 'city' });
        this.SET_SUGGESTIONS_SELECTED_BY_TYPE({ selected: this.selectDefault, type: 'city' });
        this.SET_SUGGESTIONS_VALUE_BY_TYPE({ value: '', type: 'city' });
    }

    @Action({ rawError: true })
    async searchAuctionHouse(search: string) {
        this.SET_SUGGESTIONS_VALUE_BY_TYPE({ value: search, type: 'sellerAuctionHouse' });
        const table = await getAuctionHouseList(
            1,
            this.requestSort,
            `&filters[0][id]=name&filters[0][value]=${search}`,
            50,
        );
        this.SET_SUGGESTIONS_HINTS_LIST_BY_TYPE({
            list: await processListForSelect(table.rows),
            type: 'sellerAuctionHouse',
        });
    }

    @Action({ rawError: true })
    async searchCountry(search: string) {
        this.SET_SUGGESTIONS_VALUE_BY_TYPE({ value: search, type: 'country' });
        const table = await getCountriesList(
            1,
            this.requestSort,
            `&filters[0][id]=name&filters[0][value]=${search}`,
            50,
        );
        this.SET_SUGGESTIONS_HINTS_LIST_BY_TYPE({ list: await processListForSelect(table.rows), type: 'country' });
    }

    @Action({ rawError: true })
    async searchCity(search: string) {
        this.SET_SUGGESTIONS_VALUE_BY_TYPE({ value: search, type: 'city' });

        const countryId = (this.model.country.current as InputSuggestionItem).id;
        let filter = `&filters[0][id]=name&filters[0][value]=${search}`;
        filter += countryId ? `&filters[1][id]=country&filters[1][value]=${countryId}` : '';
        const table = await getCitiesList(1, this.requestSort, filter, 50);

        this.SET_SUGGESTIONS_HINTS_LIST_BY_TYPE({ list: await processListForSelect(table.rows), type: 'city' });
    }

    @Action({ rawError: true })
    updateAddress(address: string) {
        this.SET_ADDRESS(address);
    }

    @Action({ rawError: true })
    updateDate(date: string) {
        this.RESET_ERROR_BY_FIELD('timeStart');
        this.RESET_ERROR_BY_FIELD('dateStart');
        this.SET_DATE_START(date);

        if (!this.model.timeStart.value) {
            this.SET_TIME_HOUR(18);
            this.SET_TIME_MINUTE('00');
        }
    }

    @Action({ rawError: true })
    updateTime(params: { type: string; value: number | string }) {
        this.RESET_ERROR_BY_FIELD('timeStart');
        if (params.type === 'hour') {
            this.SET_TIME_HOUR(+params.value);

            return;
        }

        this.SET_TIME_MINUTE(String(params.value));
    }

    @Action({ rawError: true })
    updateIsCorrespondenceBidding(bool: boolean) {
        this.SET_IS_CORRESPONDENCE_BIDDING(bool);
    }

    @Action({ rawError: true })
    updateIsPostAuctionSale(bool: boolean) {
        this.SET_IS_POST_AUCTION_SALE(bool);
        this.updateIsSaleHoursRequired();
    }

    @Action({ rawError: true })
    updatePostAuctionSaleHours(value: string) {
        this.RESET_ERROR_BY_FIELD('postAuctionSaleHours');
        this.SET_POST_AUCTION_SALE_HOURS(value);
    }

    @Action({ rawError: true })
    updateAuctionType(value: string) {
        this.RESET_ERROR_BY_FIELD('auctionType');
        this.SET_AUCTION_TYPE(value);
    }

    @Action({ rawError: true })
    updateSelectedCurrency(params: { id: number; selected: boolean; value: string }): void {
        const hintsListUpdated = this.model.currency.hintsList.map((currency) => {
            if (currency.id === params.id) {
                currency.selected = params.selected;
            }

            return currency;
        });
        this.SET_CURRENCY_LIST(hintsListUpdated);
    }

    @Action({ rawError: true })
    updateExchangeRateType(value: string) {
        this.RESET_ERROR_BY_FIELD('exchangeRateType');
        this.SET_EXCHANGE_RATE_TYPE(value);
    }

    @Action({ rawError: true })
    updateExchangeRateField(params: { id: number; value: string }) {
        this.SET_EXCHANGE_RATES_VALUE(params);
    }

    @Action({ rawError: true })
    addExchangeRateField(params: { id: number; code: string; symbol: string }) {
        this.ADD_EXCHANGE_RATE_FIELD(params);
    }

    @Action({ rawError: true })
    deleteExchangeRateField(id: number) {
        this.DELETE_EXCHANGE_RATE_FIELD(id);
    }

    @Action({ rawError: true })
    updateIsSaleHoursRequired() {
        this.SET_IS_SALE_HOURS_REQUIRED(this.model.isPostAuctionSale.list[0].checked);
    }

    @Action({ rawError: true })
    updateStreamUrl(url: string) {
        this.SET_STREAM_URL(url);
    }

    @Action({ rawError: true })
    updateOneLotTradeTimeSeconds(value: string) {
        this.SET_ONE_LOT_TRADE_TIME_SECONDS_BY_TYPE({
            type: this.model.auctionType.value as keyof LotTimeSecondsInterface,
            value,
        });
    }

    @Action({ rawError: true })
    updateRenewalTimeSeconds(value: string) {
        this.SET_RENEWAL_TIME_SECONDS_BY_TYPE({
            type: this.model.auctionType.value as keyof LotTimeSecondsInterface,
            value,
        });
    }

    @Action({ rawError: true })
    async updateAuctionDataByAuctionHouse(auctionHouseId: number) {
        if (!auctionHouseId) {
            return;
        }

        try {
            const auctionHouse = await getAuctionHouseCard(auctionHouseId);
            const typedCurrentCountry = this.model.country.current as InputSuggestionItem;
            const isCountryNeedsUpdate = !typedCurrentCountry.id || !this.model.country.value;
            const isSameCountry = typedCurrentCountry.id === auctionHouse.city.country.id;

            this.updateSuggestionsSelectedByType({
                selected: auctionHouse?.id ? { id: auctionHouse.id, value: auctionHouse.name } : this.selectDefault,
                type: 'sellerAuctionHouse',
            });

            if (isCountryNeedsUpdate) {
                this.updateSuggestionsSelectedByType({
                    selected: {
                        id: auctionHouse.city.country.id,
                        value: auctionHouse.city.country.name,
                    },
                    type: 'country',
                });
            }

            if (isSameCountry || isCountryNeedsUpdate) {
                this.updateSuggestionsSelectedByType({
                    selected: {
                        id: auctionHouse.city?.id || 0,
                        value: auctionHouse.city?.name,
                    },
                    type: 'city',
                });
            }

            if (!this.model.address.value) {
                this.SET_ADDRESS(auctionHouse.address || 0);
            }

            this.SET_IS_POST_AUCTION_SALE(auctionHouse.isPostauctionSale);
            this.SET_POST_AUCTION_SALE_HOURS(auctionHouse.postAuctionSaleOfLotsHours);
        } catch (error) {
            ResponseHandlerModule.showNotify({ message: 'Не удалось получить данные АД', type: 'fail' });
        }
    }

    // @Action({ rawError: true })
    // validate() {
    //     if (this.model.status === CatalogStatusEnum.DRAFT) {
    //         return false;
    //     }

    //     let fieldsWithError: string[] = [];
    //     let requiredFieldsNames: string[] = [];

    //     this.setValidateState({ index: 'name', field: this.model.name });
    //     this.setValidateSelect({ index: 'city', field: this.model.city });
    //     this.setValidateSelect({ index: 'sellerAuctionHouse', field: this.model.sellerAuctionHouse });
    //     this.setValidateSelect({ index: 'currency', field: this.model.currency });
    //     this.setValidateRadio({ index: 'auctionType', field: this.model.auctionType });
    //     this.setValidateRadio({ index: 'exchangeRateType', field: this.model.exchangeRateType });

    //     if (!this.model.dateStart.value) {
    //         this.context.commit('SET_ERROR_BY_FIELD', { field: 'dateStart', message: 'Поле не заполнено' });
    //         this.context.commit('SET_ERROR_BY_FIELD', { field: 'timeStart', message: '' });
    //     }

    //     // if (this.model.isPostAuctionSale.list[0].checked) {
    //     //     this.setValidateState({ index: 'postAuctionSaleHours', field: this.model.postAuctionSaleHours });
    //     // }

    //     if (this.model.postAuctionSaleHours.required) {
    //         this.setValidateState({ index: 'postAuctionSaleHours', field: this.model.postAuctionSaleHours });
    //     }

    //     requiredFieldsNames = Object.entries(this.model)
    //         .filter((entry: any) => entry[1] != null && entry[1].required === true)
    //         .map((entry: any) => entry[0]);

    //     fieldsWithError = requiredFieldsNames.filter((field) => this.model[field].error.class === 'cp-error');

    //     return fieldsWithError.length > 0 ? true : false;
    // }

    @Action({ rawError: true })
    async prepareRequestData(publish?: boolean) {
        let data: Record<string, string | number | File> = {
            name: this.model.name.localization?.ru || '',
            isCorrespondenceBidding: this.model.isCorrespondenceBidding.list[0].checked ? 1 : 0,
        };

        if (this.model.slug.value) {
            data.slug = this.model.slug.value;
        }

        if ((this.model.city.current as InputSuggestionItem).id) {
            data.city = (this.model.city.current as InputSuggestionItem).id;
        }

        if ((this.model.sellerAuctionHouse.current as InputSuggestionItem).id) {
            data.sellerAuctionHouse = (this.model.sellerAuctionHouse.current as InputSuggestionItem).id;
        }

        if (this.model.lotInterval.value) {
            const lotIntervalCleared = formatRemoveSpaces(this.model.lotInterval.value);
            const lotIntervalSeconds = +lotIntervalCleared * 60;

            if (!isNaN(lotIntervalSeconds)) {
                data.lotInterval = lotIntervalSeconds;
            }
        }

        data = {
            ...data,
            ...prepareLocalization(this.model.name),
            ...prepareLocalization(this.model.description, 'description'),
            ...prepareLocalization(this.model.address, 'address'),
        };

        if (publish) {
            data['published'] = +publish;
        }

        if (this.model.dateStart.value && this.model.timeStart.value) {
            const date = [this.model.dateStart.value, this.model.timeStart.value].join(' ');
            const result = formatDateToDefault(date, 'DD/MM/YY HH:mm');
            data['dateTimeStartTrad'] = result;
        }

        if (this.model.address.localization?.ru) {
            data['address'] = this.model.address.localization.ru;
        }

        if (this.model.description.localization?.ru) {
            data['description'] = this.model.description.localization.ru;
        }

        if (this.model.photo.file) {
            data['photo'] = this.model.photo.file;
        }

        if (this.model.coverPhoto.file) {
            data['coverImage'] = this.model.coverPhoto.file;
        }

        const isPostAuctionSaleChecked = this.model.isPostAuctionSale.list[0].checked;

        data['isPostauctionSale'] = Number(isPostAuctionSaleChecked);

        if (isPostAuctionSaleChecked && this.model.postAuctionSaleHours.value) {
            data['postAuctionSaleOfLotsHours'] = this.model.postAuctionSaleHours.value;
        }

        if (this.model.auctionType.value) {
            const typeItem = this.model.auctionType.list.filter(
                (item: RadioItem) => item.value == this.model.auctionType.value,
            )[0];
            data['auctionType'] = typeItem.value;
        }

        const rateItem = this.model.exchangeRateType?.list.filter(
            (item: RadioItem) => item.value == this.model.exchangeRateType.value,
        )[0];

        if (rateItem) {
            data['exchangeRateType'] = rateItem.value;
        }

        if (this.model.streamUrl.value && this.model.auctionType.value === AuctionTypeEnum.Manual) {
            data['streamUrl'] = this.model.streamUrl.value;
        }

        if (
            this.model.auctionType.value === AuctionTypeEnum.AutoInOrder &&
            this.model.oneLotTradeTimeSeconds[AuctionTypeEnum.AutoInOrder].value.length
        ) {
            data['oneLotTradeTimeSeconds'] = +this.model.oneLotTradeTimeSeconds[AuctionTypeEnum.AutoInOrder].value;
        }

        if (
            this.model.auctionType.value === AuctionTypeEnum.AutoInOrder &&
            this.model.renewalTimeSeconds[AuctionTypeEnum.AutoInOrder].value.length
        ) {
            data['renewalTimeSeconds'] = +this.model.renewalTimeSeconds[AuctionTypeEnum.AutoInOrder].value;
        }

        if (
            this.model.auctionType.value === AuctionTypeEnum.AutoOneTime &&
            this.model.oneLotTradeTimeSeconds[AuctionTypeEnum.AutoOneTime].value.length
        ) {
            data['oneLotTradeTimeSeconds'] = +this.model.oneLotTradeTimeSeconds[AuctionTypeEnum.AutoOneTime].value * 24 * 60 * 60;
        }

        if (
            this.model.auctionType.value === AuctionTypeEnum.AutoOneTime &&
            this.model.renewalTimeSeconds[AuctionTypeEnum.AutoOneTime].value.length
        ) {
            data['renewalTimeSeconds'] = +this.model.renewalTimeSeconds[AuctionTypeEnum.AutoOneTime].value * 60;
        }

        const selectedCurrenciesIds = this.model.currency.hintsList.reduce(currenciesReducer, []);
        if (this.model.exchangeRateType.value === 'central_bank' && selectedCurrenciesIds.length) {
            CurrencyModule.currencies.forEach((currency, index) => {
                if (selectedCurrenciesIds.includes(currency.id)) {
                    data[`allowedCentralBankCurrencies[${index}]`] = currency.code;
                }
            });
        }

        if (Object.keys(this.model.exchangeRates.values).length) {
            Object.values(this.model.exchangeRates.values).forEach((rate, index) => {
                data[`exchangeRates[${index}][currency]`] = rate.code;
                data[`exchangeRates[${index}][course]`] = rate.value;
            });
        }

        return data;
    }

    @Action({ rawError: true })
    async sendEdit(publish?: boolean) {
        // const isError = await this.validate();

        // if (isError) {
        //     ResponseHandlerModule.showNotify({ message: 'Заполните обязательные поля', type: 'fail' });

        //     return;
        // }

        if (this.cardId === null) {
            return;
        }

        SiteModule.SET_IS_BLOCK(true);

        try {
            const data = await this.prepareRequestData(publish);
            const result = await editCatalogAuctionCard(this.cardId, data);

            this.setInfo(result);
            ResponseHandlerModule.showNotify({ message: 'Изменения сохранены', type: 'ok' });
        } catch (error) {
            ResponseHandlerModule.showNotify({ message: await getApiErrorMessage(error), type: 'fail' });
        }

        SiteModule.SET_IS_BLOCK(false);
    }

    @Action({ rawError: true })
    async sendAdd() {
        SiteModule.SET_IS_BLOCK(true);

        try {
            const data = await this.prepareRequestData();
            const response = await createCatalogAuctionCard(data);

            ResponseHandlerModule.showNotify({ message: 'Изменения сохранены', type: 'ok' });
            RedirectHandlerModule.changeUrl({ name: 'auction', params: { catalogId: response.id } });
            this.setInfo(response);
        } catch (error) {
            ResponseHandlerModule.showNotify({ message: await getApiErrorMessage(error), type: 'fail' });
        }

        SiteModule.SET_IS_BLOCK(false);
    }

    @Action({ rawError: true })
    async sendCloneCatalog(catalogId: string) {
        if (Number.isNaN(+catalogId)) {
            return;
        }

        SiteModule.SET_IS_BLOCK(true);

        try {
            this.SET_IS_LOADING(true);
            const result = await cloneCatalog(catalogId);
            SiteModule.SET_IS_BLOCK(false);

            if (result.id) {
                this.reset();
                this.setInfo(result);
                this.SET_IS_LOADING(false);

                return result.id;
            }

            responseHandler.showNotify({ message: 'Не удалось клонировать каталог', type: 'fail' });
            this.SET_IS_LOADING(false);
        } catch (error) {
            SiteModule.SET_IS_BLOCK(false);
            this.SET_IS_LOADING(false);
            responseHandler.showNotify({ message: getApiErrorMessage(error), type: 'fail' });
        }
    }

    @Action({ rawError: true })
    async unpublish(catalogId: string) {
        try {
            SiteModule.SET_IS_BLOCK(true);
            const result = await unpublishCatalog(catalogId);

            if (!result) {
                return;
            }

            this.setInfo(result);
            responseHandler.showNotify({ message: 'Каталог успешно снят с публикации', type: 'ok' });
            SiteModule.SET_IS_BLOCK(false);
        } catch (error) {
            SiteModule.SET_IS_BLOCK(false);
            responseHandler.showNotify({ message: getApiErrorMessage(error), type: 'fail' });
        }
    }

    @Action({ rawError: true })
    reset() {
        this.SET_IS_LOADING(true);
        this.SET_CARD_ID(null);
        this.SET_AUCTION_NAME('');
        this.SET_DATE_POSTING('');
        this.SET_DATE_FINISH('');
        this.SET_DATE_START('');
        this.SET_TIME_START('');

        this.resetSuggestionsStateByType('sellerAuctionHouse');
        this.resetSuggestionsStateByType('country');
        this.resetSuggestionsStateByType('city');


        this.SET_MODEL_FIELD_VALUE({ field: 'photo', value: '' });
        this.SET_MODEL_IMAGE_FIELD_FILE({ field: 'photo', file: null });
        this.SET_MODEL_IMAGE_FIELD_PREVIEW({ field: 'photo', base64String: '' });
        this.SET_MODEL_IMAGE_FIELD_URL({ field: 'photo', url: '' });

        this.SET_MODEL_FIELD_VALUE({ field: 'coverPhoto', value: '' });
        this.SET_MODEL_IMAGE_FIELD_FILE({ field: 'coverPhoto', file: null });
        this.SET_MODEL_IMAGE_FIELD_PREVIEW({ field: 'coverPhoto', base64String: '' });
        this.SET_MODEL_IMAGE_FIELD_URL({ field: 'coverPhoto', url: '' });

        this.SET_PHOTO('');
        this.SET_PHOTO_FILE(null);
        this.SET_PHOTO_PREVIEW('');

        this.SET_IS_CORRESPONDENCE_BIDDING(false);
        this.SET_IS_POST_AUCTION_SALE(false);
        this.SET_POST_AUCTION_SALE_HOURS('');
        this.SET_AUCTION_TYPE('');
        this.SET_EXCHANGE_RATE_TYPE('');

        this.SET_IS_SALE_HOURS_REQUIRED(false);
        this.SET_STREAM_URL('');

        this.model.auctionType.list.forEach((item) => {
            if (item.value === ('manual' as keyof LotTimeSecondsInterface)) {
                return;
            }

            this.SET_ONE_LOT_TRADE_TIME_SECONDS_BY_TYPE({
                type: item.value as keyof LotTimeSecondsInterface,
                value: '',
            });
            this.SET_RENEWAL_TIME_SECONDS_BY_TYPE({ type: item.value as keyof LotTimeSecondsInterface, value: '' });
        });

        this.RESET_LOCALIZATION_BY_FIELD();
        this.RESET_LOCALIZATION_BY_FIELD('description');
        this.RESET_LOCALIZATION_BY_FIELD('address');

        this.SET_MODEL_FIELD_VALUE({ field: 'slug', value: '' });

        this.SET_IS_ALLOW_REMOVE(false);
        this.SET_IS_ALLOW_EDIT(false);
        this.SET_IS_ALLOW_PUBLISH(false);
        this.SET_IS_ALLOW_UNPUBLISH(false);
        this.SET_IS_ALLOW_CLONE(false);
        this.SET_MODEL_FIELD_VALUE({ field: 'lotInterval' });

        this.DELETE_EXCHANGE_RATE_FIELD();

        this.toggleFieldsDisabled(false);
        this.resetState();
    }

    @Action({ rawError: true })
    resetState() {
        this.RESET_ERROR_BY_FIELD('name');
        this.RESET_ERROR_BY_FIELD('city');
        this.RESET_ERROR_BY_FIELD('sellerAuctionHouse');
        this.RESET_ERROR_BY_FIELD('currency');
        this.RESET_ERROR_BY_FIELD('auctionType');
        this.RESET_ERROR_BY_FIELD('exchangeRateType');
        this.RESET_ERROR_BY_FIELD('dateStart');
        this.RESET_ERROR_BY_FIELD('postAuctionSaleHours');
        this.RESET_ERROR_BY_FIELD('isCorrespondenceBidding');

        this.SET_FIELD_DISABLED_BY_TYPE({ type: 'sellerAuctionHouse', disabled: false });
    }
}

export default getModule(CatalogsAuctionsCommonEntityModule);
