import InputEntity from '@/lib/formFactory/inputEntity';
import SelectEntity from '@/lib/formFactory/selectEntity';
import ImageInputEntity from '@/lib/formFactory/imageInputEntity';
import InputSuggestionsEntity from '@/lib/formFactory/inputSuggestionsEntity';

export default class EntityModel {
    model: any;

    constructor() {
        const nameEntity = new InputEntity('name');
        nameEntity.setTitle('Имя');
        nameEntity.setMask({
            regex: '[a-zA-ZА-Яа-я+\\s]+',
            placeholder: ' ',
            minChars: 1,
        });
        nameEntity.initLocalization();
        nameEntity.setRequired(true);

        const termsEntity = new InputEntity('terms');
        termsEntity.initLocalization();

        const countryEntity = new InputSuggestionsEntity('country');
        countryEntity.setTitle('Страна');

        const cityEntity = new InputSuggestionsEntity('city');
        cityEntity.setTitle('Город');
        cityEntity.setRequired(true);

        const descriptionEntity = new InputEntity('description');
        descriptionEntity.setTitle('Краткое описание');
        descriptionEntity.setSize(512);

        const siteEntity = new InputEntity('site');
        siteEntity.setTitle('Сайт');
        siteEntity.setMask({
            placeholder: ' ',
            minChars: 1,
        });

        const emailEntity = new InputEntity('email');
        emailEntity.setTitle('Email');
        emailEntity.setMask({
            mask: '*{1,20}[.*{1,20}][.*{1,20}][.*{1,20}]@*{1,20}[.*{2,20}][.*{1,20}]',
            greedy: false,
            definitions: {
                '*': {
                    validator: "[0-9A-Za-z!#$%&'*+/=?^_`{|}~-]",
                    casing: 'lower',
                },
            },
        });

        const statusEntity = new SelectEntity('status');
        statusEntity.setTitle('Статус');

        const sortEntity = new InputEntity('sort');
        sortEntity.setTitle('Порядок сортировки');

        const siteCommissionEntity = new InputEntity('siteCommission');
        siteCommissionEntity.setTitle('Комиссия площадки с продажи');

        const slugEntity = new InputEntity('slug');
        slugEntity.setTitle('Slug');

        const vkEntity = new InputEntity('vk');
        vkEntity.setTitle('VK');

        const facebookEntity = new InputEntity('facebook');
        facebookEntity.setTitle('Facebook');

        const pinterestEntity = new InputEntity('pinterest');
        pinterestEntity.setTitle('Pinterest');

        const instagramEntity = new InputEntity('instagram');
        instagramEntity.setTitle('Instagram');

        const photoEntity = new ImageInputEntity('photo');
        photoEntity.setMinHeight(480);
        photoEntity.setMinWidth(480);

        this.model = {
            name: nameEntity.entity[nameEntity.entityName],
            terms: termsEntity.entity[termsEntity.entityName],
            country: countryEntity.entity[countryEntity.entityName],
            city: cityEntity.entity[cityEntity.entityName],
            description: descriptionEntity.entity[descriptionEntity.entityName],
            site: siteEntity.entity[siteEntity.entityName],
            email: emailEntity.entity[emailEntity.entityName],
            status: statusEntity.entity[statusEntity.entityName],
            sort: sortEntity.entity[sortEntity.entityName],
            siteCommission: siteCommissionEntity.entity[siteCommissionEntity.entityName],
            photo: photoEntity.entity[photoEntity.entityName],
            vk: vkEntity.entity[vkEntity.entityName],
            facebook: facebookEntity.entity[facebookEntity.entityName],
            pinterest: pinterestEntity.entity[pinterestEntity.entityName],
            instagram: instagramEntity.entity[instagramEntity.entityName],
            slug: slugEntity.entity[slugEntity.entityName],
            balance: '',
            balanceDetails: {
                lotsPlaced: {
                    title: 'Размещено лотов в данный момент',
                    value: null,
                },
                catalogsPlaced: {
                    title: 'Размещено каталогов в данный момент',
                    value: null,
                },
                soldLots: {
                    title: 'Продано лотов',
                    value: null,
                },
                totalValueLots: {
                    title: 'Общая сумма с проданных лотов',
                    value: null,
                },
                avgReceivedFromLot: {
                    title: 'В среднем получено с одного лота',
                    value: null,
                },
                avgLotValue: {
                    title: 'Средняя стоимость лота',
                    value: null,
                },
            },
        };
    }
}
