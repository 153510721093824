/**
 * Menu store.
 *
 */

import { Module, VuexModule, getModule, Mutation, Action } from 'vuex-module-decorators';
import store from '@/admin/store';
import { menuItems } from './items';

import { MenuItems } from '@/interfaces/menuItem.interface';
import UserModule from '@/admin/store/user';
import { AUCTIONLESS_SELLER_ROLES, RoleEnum } from '@/admin/store/role';

export const MODULE_NAME = 'menu';

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class MenuModule extends VuexModule {
    menuItems: MenuItems = {};

    constructor(module: MenuModule) {
        super(module);
    }

    @Mutation
    SET_MENU_ITEMS(items: MenuItems) {
        this.menuItems = items;
    }

    @Mutation
    SET_MENU_ITEM_PROP(params: { itemName: string; prop: string; value: string }) {
        if (!this.menuItems[params.itemName]) {
            return;
        }

        (this.menuItems[params.itemName] as any)[params.prop] = params.value;
    }

    @Action({ rawError: true })
    initMenuItems() {
        const allowAuctionsAccess = !AUCTIONLESS_SELLER_ROLES.includes(UserModule.role as RoleEnum);
        let sellerPathAndParams = {};
        if (UserModule.sellerAuctionHouse?.id) {
            sellerPathAndParams = {
                path: 'auction_house',
                params: { auctionHouseId: UserModule.sellerAuctionHouse.id },
            };
        }

        if (UserModule.sellerGallery?.id) {
            sellerPathAndParams = {
                path: 'gallery',
                params: { galleryId: UserModule.sellerGallery.id },
            };
        }

        if (UserModule.sellerPainter?.id) {
            sellerPathAndParams = {
                path: 'painter',
                params: { painterId: UserModule.sellerPainter.id },
            };
        }

        if (!allowAuctionsAccess) {
            menuItems.auctions.name = 'Лоты';
            menuItems.auctions.path = 'lots';
        }

        menuItems.seller = {
            ...menuItems.seller,
            ...sellerPathAndParams,
        };

        this.SET_MENU_ITEMS(menuItems);
    }

    @Action({ rawError: true })
    updateAutoLogsHint(count: { total: number; today: number; tomorrow: number }) {
        if (count.total > 0) {
            this.context.commit('SET_MENU_ITEM_PROP', {
                itemName: 'autoLogs',
                prop: 'hint',
                value: count.total.toString(),
            });
            this.context.commit('SET_MENU_ITEM_PROP', {
                itemName: 'autoLogs',
                prop: 'hintClass',
                value: count.today > 0 ? 'red' : 'yellow',
            });

            return;
        }

        this.context.commit('SET_MENU_ITEM_PROP', { itemName: 'autoLogs', prop: 'hint', value: '' });
        this.context.commit('SET_MENU_ITEM_PROP', { itemName: 'autoLogs', prop: 'hintClass', value: '' });
    }
}

export default getModule(MenuModule);
