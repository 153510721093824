/**
 * Global site state.
 *
 */

import { AxiosError } from 'axios';
import { VuexModule, Action, Mutation, Module, getModule } from 'vuex-module-decorators';
import { UNAUTHORIZED, FORBIDDEN } from 'http-status-codes';
import store from '@/admin/store';
import userModule from './user';
import menuModule from './menu/index';
import { getUserProfile } from '../api/users';
import { parseJwt } from '@/lib/utils/Utils';
import CurrencyModule from '@/admin/store/currency';

@Module({ dynamic: true, store, name: 'site', namespaced: true })
class SiteModule extends VuexModule {
    errors: string[] = [];
    isGlobalError = false;
    isBlock = false;
    menuItems = {};
    menuItemsLinks = {};
    isStage = false;

    @Mutation
    SET_IS_STAGE(bool: boolean) {
        this.isStage = bool;
    }

    @Mutation
    setMenuItems() {
        this.menuItems = menuModule.menuItems;
    }

    @Mutation
    clearMenu() {
        this.menuItems = {};
    }

    @Mutation
    addError(error: string) {
        this.errors.push(error);
    }

    @Mutation
    setGlobalError(isGlobalError: boolean) {
        this.isGlobalError = isGlobalError;
    }

    @Mutation
    SET_IS_BLOCK(bool: boolean) {
        this.isBlock = bool;
    }

    @Action
    setIsBlock(bool: boolean) {
        this.context.commit('SET_IS_BLOCK', bool);
    }

    @Action
    fetchApiResponseError(error: AxiosError) {
        if (error.response?.status === FORBIDDEN) {
            this.context.commit('addError', 'Ошибка доступа');

            return;
        }

        if (error.response?.status === UNAUTHORIZED) {
            this.context.commit('addError', 'Ошибка доступа');
            userModule.setAuthenticated(false);

            return;
        }

        this.context.commit('addError', error.response?.data.message ?? 'Упс... Неизвестная ошибка на сервере');
    }

    @Action
    fetchApiRequestError(error: AxiosError) {
        this.context.commit('addError', error.response?.data.message ?? 'Упс... Неизвестная сетевая ошибка');
    }

    @Action
    async init() {
        try {
            userModule.setAuthenticated(true);
            this.SET_IS_STAGE(['stage', 'prod'].includes(String(process.env.VUE_APP_ENVIRONMENT)));
            const userProfile = await getUserProfile();
            const { user } = parseJwt(window.localStorage.tokenAdmin);

            userModule.setUser({
                id: user ?? 0,
                login: userProfile.email,
                name: [userProfile.firstName, userProfile.middleName, userProfile.lastName]
                    .filter((name) => name)
                    .join(' '),
                roles: userProfile.roles,
                sellerAuctionHouse: userProfile.sellerAuctionHouse,
                sellerGallery: userProfile.sellerGallery,
                sellerPainter: userProfile.sellerPainter,
            });
            await Promise.all([menuModule.initMenuItems(), CurrencyModule.getCurrencies()]);
        } catch (error: any) {
            userModule.setToken('');
            if (error?.response?.status !== UNAUTHORIZED) {
                this.context.commit('setGlobalError', false);
            }
        }
    }
}

export default getModule(SiteModule);
