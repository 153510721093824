import API from '@/admin/config/api-routers';
import { makeGetRequest, makeFormRequest, makePostRequest } from '@/api/helpers';
import { UserApiInterface } from '@/interfaces/users.interface';

export const getUsersList = async (pageCurrent: number, sort: string, filter: string, items: number | string) => {
    const itemsFilter = items ? `&items=${items}` : '';
    const result = await makeGetRequest(API.user.getList + `?page=${pageCurrent}${itemsFilter}&${sort}${filter}`);

    return result.data.result.table;
};

export const getUserProfile = async (): Promise<UserApiInterface> => {
    const result = await makeGetRequest(API.user.profile);

    return result.data.result.user;
};

export const getUserCard = async (cardId: number | string) => {
    const result = await makeGetRequest(API.user.card + `${cardId}/`);

    return result.data.result.item;
};

export const editUserCard = async (cardId: number | string, data: any) => {
    const result = await makeFormRequest(API.user.card + `${cardId}/`, data);

    return result;
};

export const createUserCard = async (data: any) => {
    const result = await makeFormRequest(API.user.create, data);

    return result;
};

export const getUserRoles = async (): Promise<Record<string, string>> => {
    const result = await makeGetRequest(API.user.roles);

    return result.data?.result?.roles;
};

export const activateUser = async (userId: number | string): Promise<UserApiInterface> => {
    const result = await makePostRequest(`${API.user.card}${userId}/activate/`);

    return result.data?.result?.item;
};

export const deactivateUser = async (userId: number | string): Promise<UserApiInterface> => {
    const result = await makePostRequest(`${API.user.card}${userId}/deactivate/`);

    return result.data?.result?.item;
};
