import { makeGetRequest, makeFormRequest, makePostRequest } from '@/api/helpers';
import API from '@/admin/config/api-routers';
import { ApprovedUserInterface } from '@/interfaces/models/approvedUser.interface';

/**
 * Get all auction houses.
 *
 */
export const getAuctionHouseAll = async () => {
    const result = await makeGetRequest(API.auctionHouse.all);

    return result.data.result.table;
};

/**
 * Get auction houses list.
 *
 * @param currentPage
 * @param filter
 * @param sort
 * @param items
 */
export const getAuctionHouseList = async (
    currentPage: string | number,
    filter: string,
    sort: string,
    items: number | string,
) => {
    filter = filter || '';
    sort = sort || '';
    const result = await makeGetRequest(API.auctionHouse.list + `?page=${currentPage}&items=${items}&${filter}${sort}`);

    return result.data.result.table;
};

/**
 * Get auction house card.
 *
 * @param houseId
 */
export const getAuctionHouseCard = async (houseId: number | string) => {
    const result = await makeGetRequest(API.auctionHouse.card + `${houseId}/`);

    return result.data.result.item;
};

/**
 * Save auctin house card changes.
 *
 * @param houseId
 * @param data
 */
export const saveCard = async (houseId: number | string, data: any) => {
    const result = await makeFormRequest(API.auctionHouse.card + `${houseId}/`, data);

    return result.data.result.item;
};

/**
 * Create new auction house card.
 *
 * @param data
 */
export const createCard = async (data: any) => {
    const result = await makeFormRequest(API.auctionHouse.create, data);

    return result.data.result.item;
};

/**
 * Get auction house members list.
 *
 */
export const getAuctionHouseMembersList = async (page: number, sort: string, filter: string, items: number) => {
    const result = await makeGetRequest(`${API.auctionHouse.membersList}?page=${page}${sort}${filter}&items=${items}`);

    return result.data.result.table;
};

/**
 * Get auction house member card.
 *
 * @param id
 */
export const getAuctionHouseMember = async (id: number) => {
    const result = await makeGetRequest(`${API.auctionHouse.member}${id}/`);

    return result.data.result.item;
};

/**
 * Edit auction house memeber
 *
 * @param id
 * @param params
 * @returns
 */
export const editAuctionHouseMember = async (
    id: number,
    params: { status?: string; newbieLimit?: number },
): Promise<ApprovedUserInterface> => {
    let queryString = params.status ? `status=${params.status}` : '';
    queryString += params.newbieLimit ? `newbieLimit=${params.newbieLimit}` : '';

    const result = await makePostRequest(`${API.auctionHouse.member}${id}/?${queryString}`);

    return result.data.result.item;
};

export const cancelAuctionHouseMember = async (id: number): Promise<string> => {
    const result = await makePostRequest(`${API.auctionHouse.member}${id}/cancel/`);

    return result.data.result;
};

export const approveAuctionHouseMember = async (id: number): Promise<string> => {
    const result = await makePostRequest(`${API.auctionHouse.member}${id}/approve/`);

    return result.data.result.status;
};
