<template>
    <div
        class="mr-select-default__wrapper"
        :class="{ disabled: data.disabled, 'required-field-wrapper': data.required }"
    >
        <span
            class="mr-input-default__title cd-form-input-label"
            :class="{ 'cd-form-input-label-active': visible || current.value }"
            >{{ data.title }}</span
        >

        <div ref="dropdownSelect" class="mr-select-default-toggle__wrapper" :class="stateClass" @click="toggleSelect">
            <div class="mr-select-default-input__wrapper cp-input-radius-50" :class="data.error.class">
                {{ current.value }}

                <arrow-down />
            </div>

            <div v-if="visible && !data.disabled" class="mr-select-default-list__wrapper cp-input-radius-25">
                <div v-for="item in items" :key="`mr-select-default-list_${item.id}`">
                    <div
                        v-if="item.visible == null || item.visible === true"
                        class="mr-select-default-list cp-input-radius-50"
                        @click="selectValue(item.id, item.value)"
                    >
                        {{ item.value }}
                    </div>
                </div>
            </div>

            <span class="mr-select-default-error-message">{{ data.error.message }}</span>
        </div>

        <span
            v-if="data.clearAction && current.value"
            class="cp-cursor-pointer mr-select-default__clear"
            @click="resetValue"
        >
            <icon-font size="11" icon="close" />
        </span>
    </div>
</template>

<script>
import IconFont from '@/components/icons/IconFont.vue';
import ArrowDown from '@/components/icons/ArrowDown.vue';
import SelectMixin from '@/components/select/SelectMixin.js';

export default {
    name: 'SelectCustom',
    components: {
        IconFont,
        ArrowDown,
    },
    mixins: [SelectMixin],
    methods: {
        selectValue(id, value) {
            if (id === 0) {
                return;
            }

            const newValue = { id, value };
            this.visible = false;
            this.$emit('click', newValue);
        },
        resetValue() {
            this.$store.dispatch(this.data.clearAction);
        },
    },
};
</script>

<style lang="scss" scoped>
/*@import '../assets/scss/templates/forms/select-custom.scss';*/
</style>
