export const modalParams = {
    terms: {
        isShow: false,
        title: 'О нас',
        editor: {
            title: '',
            value: '',
            key: '',
            action: 'sellersPainterEntity/updateTermsContent',
            error: {
                class: '',
                message: '',
            },
        },
    },
};
