import API from '@/admin/config/api-routers';
import { makeGetRequest, makeFormRequest } from '@/api/helpers';

export const getPainterAll = async () => {
    const result = await makeGetRequest(API.painter.getAll);

    return result.data.result.table;
};

export const getPainterList = async (pageCurrent: number, sort: string, filter: string, items: number | string) => {
    const result = await makeGetRequest(API.painter.getList + `?page=${pageCurrent}&items=${items}&${sort}${filter}`);

    return result.data.result.table;
};

export const getPainterCard = async (cardId: number | string) => {
    const result = await makeGetRequest(API.painter.card + `${cardId}/`);

    return result.data.result.item;
};

export const editPainterCard = async (cardId: number | string, data: any) => {
    const result = await makeFormRequest(API.painter.card + `${cardId}/`, data);

    return result;
};

export const createPainterCard = async (data: any) => {
    const result = await makeFormRequest(API.painter.create, data);

    return result;
};
