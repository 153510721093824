import API from '@/admin/config/api-routers';
import { makeGetRequest, makeFormRequest } from '@/api/helpers';

export const getAllCities = async () => {
    const result = await makeGetRequest(API.city.getAll);

    return result.data.result.table;
};

export const getCitiesList = async (pageCurrent: number, sort: string, filter: string, items: number | string) => {
    const itemsFilter = items ? `&items=${items}` : '';
    const result = await makeGetRequest(API.city.getList + `?page=${pageCurrent}${itemsFilter}&${sort}${filter}`);

    return result.data.result.table;
};

export const getCityCard = async (cardId: number | string) => {
    const result = await makeGetRequest(API.city.card + `${cardId}/`);

    if (result.data.result.localization) {
        result.data.result.item['localization'] = result.data.result.localization;
    }

    return result.data.result.item;
};

export const editCityCard = async (cardId: number | string, data: any) => {
    const result = await makeFormRequest(API.city.card + `${cardId}/`, data);

    return result;
};

export const createCityCard = async (data: any) => {
    const result = await makeFormRequest(API.city.create, data);

    return result;
};
