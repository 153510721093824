import { makeGetRequest } from '@/api/helpers';
import API from './../config/api-routers';

export const getStatistics = async (
    type: string,
    id: number,
    filter: string,
): Promise<{ [key: string]: number | string }> => {
    const result = await makeGetRequest(`${API.statistics.get}${type}/${id}${filter}`);

    return result.data.result.result;
};
