/**
 * Auth store.
 *
 */

import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '@/admin/store';
import { AuthModel } from '@/interfaces/models/auth.interface';
import UserAuthModel from '@/lib/models/userAuth';
import InputEntity from '@/lib/formFactory/inputEntity';

import { login as loginApi, logout } from '@/admin/api/user';

import siteModule from '@/admin/store/site';
import userModule from '@/admin/store/user';
import { Admin } from '@/interfaces/admin.interface';
import ResponseHandlerModule from '@/admin/store/modules/responseHandler';
import { getApiErrorMessage } from '@/lib/utils/Utils';

export const MODULE_NAME = 'auth';

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class AuthModule extends VuexModule {
    text: {} = {
        btnLogin: 'Войти',
    };
    model: AuthModel;
    userAuth = new UserAuthModel();

    constructor(module: AuthModule) {
        super(module);
        const loginEntity = new InputEntity('login');
        loginEntity.setTitle('Логин');

        const passwordEntity = new InputEntity('password');
        passwordEntity.setType('password');
        passwordEntity.setTitle('Пароль');

        this.model = {
            login: loginEntity.entity[loginEntity.entityName],
            password: passwordEntity.entity[passwordEntity.entityName],
        };
    }

    @Mutation
    setModel() {
        this.userAuth.login = 'test';
        this.userAuth.password = '';
    }

    @Mutation
    setLoginValue(value: string) {
        this.model.login.value = value;
    }

    @Mutation
    setPasswordValue(value: string) {
        this.model.password.value = value;
    }

    @Action({ rawError: true })
    async loginRequest() {
        try {
            const result = await loginApi(this.model.login.value, this.model.password.value);

            const user = result.result.user;
            await userModule.setUser(user as Admin);
            await userModule.setToken(result.token);
            await siteModule.init();
        } catch (error) {
            ResponseHandlerModule.showNotify({ message: await getApiErrorMessage(error), type: 'fail' });
        }
    }

    @Action
    async logoutRequest() {
        try {
            await logout();
            userModule.clearUser();
            await userModule.setToken('');

            return true;
        } catch {
            return false;
        }
    }
}

export default getModule(AuthModule);
