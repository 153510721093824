export default {
    description: {
        isShow: false,
        title: 'Описание',
        editor: {
            title: '',
            value: '',
            key: '',
            error: {
                class: '',
                message: '',
            },
        },
    },
};
