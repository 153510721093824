import API from '@/admin/config/api-routers';
import { makeGetRequest, makeFormRequest } from '@/api/helpers';
import { BidStepsFromStartPrice } from '@/interfaces/settingsAuctions';

export interface SettingsSectionInterface {
    id: number | string;
    name: string;
    section: string;
    value: string | BidStepsFromStartPrice[];
}

export const getSettingAuction = async (filter: string): Promise<SettingsSectionInterface[]> => {
    const result = await makeGetRequest(`${API.settingAuction.getAll}${filter}`);

    return result.data.result.table;
};

export const editSettingAuction = async (section: string, name: string, value: string) => {
    const result = await makeFormRequest(`${API.settingAuction.editItem}${section}/${name}/${value}`, {}, 'POST');

    return result;
};

export const editSetting = async (section: string, name: string, value: { [key: string]: string }) => {
    const result = await makeFormRequest(`${API.settingAuction.editItem}${section}/${name}/`, value, 'POST');

    return result;
};
