/**
 * User store.
 *
 * @author Artem Bakulin <dekkyartem@gmail.com>
 */

import { Module, VuexModule, Mutation, getModule, Action } from 'vuex-module-decorators';
import store from '@/admin/store';
import router from '@/admin/router';
import { Admin } from '@/interfaces/admin.interface';
import { MenuItem } from '@/interfaces/menuItem.interface';
import { UserApiSellerInterface } from '@/interfaces/users.interface';
import { RoleEnum } from '@/admin/store/role';
import WebsocketsModule from './websockets';

export const MODULE_NAME = 'user';

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class UserModule extends VuexModule {
    id: number | null = null;
    login: string | null = null;
    name: string | null = null;
    allowedItems: string[] = [];
    isAuthenticated = false;
    token: string | null = null;
    role: RoleEnum | null = null;
    sellerAuctionHouse: UserApiSellerInterface | null = null;
    sellerGallery: UserApiSellerInterface | null = null;
    sellerPainter: UserApiSellerInterface | null = null;

    get isSuperAdmin() {
        return this.role === RoleEnum.ROLE_SUPER_ADMIN;
    }

    get isOperator() {
        return this.role === RoleEnum.ROLE_SELLER_AUCTION_HOUSE_OPERATOR;
    }

    @Mutation
    SET_TOKEN(token: string) {
        this.token = token;
        if (typeof window.localStorage === 'object') {
            window.localStorage.tokenAdmin = token;
        }
    }

    @Mutation
    setAuthenticated(isAuthenticated: boolean) {
        this.isAuthenticated = isAuthenticated;
    }

    @Mutation
    setUser(admin: Admin) {
        const roleEnumValues = Object.values<string>(RoleEnum);
        this.id = admin.id;
        this.login = admin.login;
        this.name = admin.name;
        this.role = admin.roles.filter((role) => roleEnumValues.includes(role))[0] as RoleEnum;
        this.sellerAuctionHouse = admin.sellerAuctionHouse || null;
        this.sellerGallery = admin.sellerGallery || null;
        this.sellerPainter = admin.sellerPainter || null;
    }

    @Mutation
    clearUser() {
        this.id = null;
        this.login = null;
        this.name = null;
        this.allowedItems = [];
        this.isAuthenticated = false;
        this.role = null;
    }

    @Mutation
    setAllowedItems(menu: MenuItem[]) {
        this.allowedItems = menu.map((item) => item.code);
    }

    @Action({ rawError: true })
    setToken(token: string) {
        this.SET_TOKEN(token);
        (WebsocketsModule as any).changeToken(token);
        (WebsocketsModule as any).ws?.reset();
    }

    @Action({ rawError: true })
    clearUserData() {
        this.context.commit('setToken', '');
        this.context.commit('setAuthenticated', false);
        this.context.commit('clearUser');

        router.push({ name: 'login' });
    }

    get isBlockAllowed() {
        return (code?: string | null) => typeof code === 'string' && this.allowedItems.includes(code);
    }
}

export default getModule(UserModule);
