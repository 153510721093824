
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class IconArrowReturn extends Vue {
    @Prop({ required: false }) readonly icon?: string;
    @Prop({ required: false }) readonly size?: string;

    get iconClass() {
        return `icon-${this.icon}`;
    }
}
