import { ApiInterface } from '@/interfaces/api.interface';

const API_BASE = '/api/admin/';
const CATALOG_BASE = `${API_BASE}auction`;
const LOT_BASE = `${API_BASE}lot`;
const USER_BASE = `${API_BASE}user`;
const CATEGORY_BASE = `${API_BASE}category`;

const AUTH_LOGIN = 'api/admin/auth/login/';
const AUTH_LOGOUT = '/api/admin/auth/logout/';
const AUCTION_HOUSE_ALL = '/api/admin/seller/auction-house/all/';
const AUCTION_HOUSE_LIST = '/api/admin/seller/auction-house/list/';
const AUCTION_HOUSE_CARD = 'api/admin/seller/auction-house/';
const AUCTION_HOUSE_CARD_CREATE = 'api/admin/seller/auction-house/create/';
const AUCTION_HOUSE_MEMBER_LIST = '/api/admin/auction_house_member/list/';
const AUCTION_HOUSE_MEMBER = '/api/admin/auction_house_member/';
const CITY_ALL = '/api/admin/city/all/';
const CITY_LIST = '/api/admin/city/list/';
const CITY_CARD = '/api/admin/city/';
const CITY_CREATE = '/api/admin/city/create/';
const COUNTRY_ALL = '/api/admin/country/all/';
const COUNTRY_LIST = '/api/admin/country/list/';
const COUNTRY_CARD = '/api/admin/country/';
const COUNTRY_CREATE = '/api/admin/country/create/';
const STATUS_LIST = '/api/admin/seller/auction-house/statuses/';
const GALLERY_ALL = '/api/admin/seller/gallery/all/';
const GALLERY_LIST = '/api/admin/seller/gallery/list/';
const GALLERY_CARD = '/api/admin/seller/gallery/';
const GALLERY_CREATE = '/api/admin/seller/gallery/create/';
const PAINTER_ALL = '/api/admin/seller/painter/all/';
const PAINTER_LIST = '/api/admin/seller/painter/list/';
const PAINTER_CARD = '/api/admin/seller/painter/';
const PAINTER_CREATE = '/api/admin/seller/painter/create/';

const CURRENCY_ALL = '/api/admin/currency/all/';
const ORDERS_LIST = '/api/admin/order/list/';
const ORDERS_CARD = '/api/admin/order/';
const ORDERS_STATUSES = '/api/admin/order/statuses/';
const SPECIALTY_ALL = '/api/admin/specialization/all/';
const SPECIALTY_LIST = '/api/admin/specialization/list/';
const SPECIALTY_CARD = '/api/admin/specialization/';
const SPECIALTY_CREATE = '/api/admin/specialization/create/';
const SETTING_ACTION_ALL = '/api/admin/setting/list/';
const SETTING_ACTION_EDIT = '/api/admin/setting/';
const BIDS_LIST = '/api/admin/bid/list/';
const BID_CANCEL = '/api/admin/bid/';
const TRANSACTION_LIST = '/api/admin/transaction/list/';
const TRANSACTION_CREATE = '/api/admin/transaction/create/';
const TRANSACTION_CANCEL = '/api/admin/transaction/cancel/';
const TRANSACTION_OPERATION_NAMES = '/api/admin/transaction/operation_names/';
const CONTENT_FAQ_LIST = '/api/admin/faq/list/';
const CONTENT_FAQ = '/api/admin/faq/';
const CONTENT_FAQ_CREATE = '/api/admin/faq/create/';
const CONTENT_ABOUT = '/api/public/content_page/about/';
const CONTENT_ABOUT_EDIT = '/api/admin/content_page/about/update/';
const POLICY = '/api/public/content_page/privacy_policy/';
const POLICY_EDIT = '/api/admin/content_page/privacy_policy/update/';
const CONTENT_NEWS_LIST = '/api/admin/news/list/';
const CONTENT_NEWS_CARD = '/api/admin/news/';
const CONTENT_NEWS_CREATE = '/api/admin/news/create/';
const CONTENT_RULES = '/api/public/content_page/rules/';
const CONTENT_RULES_EDIT = '/api/admin/content_page/rules/update/';
const IMPORT_CREATE = '/api/admin/import/create/';
const IMPORT_TYPES = '/api/admin/import/types/';
const IMPORT_FILES = '/api/admin/import/files/';
const IMPORT_LIST = '/api/admin/import/list/';
const IMPORT_CARD = '/api/admin/import/';
const IMPORT_STATUSES = '/api/admin/import/statuses/';
const CONTENT_TERMS = '/api/public/content_page/terms_of_use/';
const CONTENT_TERMS_EDIT = '/api/admin/content_page/terms_of_use/update/';
const CONTENT_PUBLIC_OFFER = '/api/public/content_page/public_offer/';
const CONTENT_PUBLIC_OFFER_EDIT = '/api/admin/content_page/public_offer/update/';
const STATISTICS = '/api/admin/statistic/';
const USER_QUESTION_LIST = '/api/admin/user_question/list/';
const USER_QUESTION = '/api/admin/user_question/';
const SALE_OFFER_LIST = '/api/admin/sale_offer/list/';
const SALE_OFFER = '/api/admin/sale_offer/';
const ADDRESS_CREATE = '/api/admin/address/create/';
const ADDRESS_CARD = '/api/admin/address/';
const SLIDER_CONTENT_TABLE = '/api/admin/slider_content/list/';
const SLIDER_CONTENT_CREATE = '/api/admin/slider_content/create/';
const SLIDER_CONTENT_CARD = '/api/admin/slider_content/';
const SEO_LIST = '/api/admin/content-seo/list/';
const SEO_UPDATE = '/api/admin/content-seo/';
const MAILING_LIST = '/api/admin/mailing/all/';
const MAILING_CREATE = '/api/admin/mailing/create/';
const MAILING_DETAIL = '/api/admin/mailing/';
const MAILING_CREATE_ALL = '/api/admin/mailing/send_to_all/';
const MAILING_DELETE = '/api/admin/mailing/';
const TARIFFS_LIST = '/api/admin/subscription/list/';
const TARIFFS_ID  = '/api/admin/subscription/';

const API: ApiInterface = {
    auth: {
        login: AUTH_LOGIN,
        logout: AUTH_LOGOUT,
    },
    auctionHouse: {
        all: AUCTION_HOUSE_ALL,
        list: AUCTION_HOUSE_LIST,
        card: AUCTION_HOUSE_CARD,
        create: AUCTION_HOUSE_CARD_CREATE,
        membersList: AUCTION_HOUSE_MEMBER_LIST,
        member: AUCTION_HOUSE_MEMBER,
    },
    city: {
        getAll: CITY_ALL,
        getList: CITY_LIST,
        card: CITY_CARD,
        create: CITY_CREATE,
    },
    country: {
        getAll: COUNTRY_ALL,
        getList: COUNTRY_LIST,
        card: COUNTRY_CARD,
        create: COUNTRY_CREATE,
    },
    category: {
        getAll: `${CATEGORY_BASE}/all/`,
        getList: `${CATEGORY_BASE}/list/`,
        getNewList: `${CATEGORY_BASE}/new-list/`,
        card: `${CATEGORY_BASE}/`,
        create: `${CATEGORY_BASE}/create/`,
    },
    status: {
        getList: STATUS_LIST,
    },
    gallery: {
        getAll: GALLERY_ALL,
        getList: GALLERY_LIST,
        card: GALLERY_CARD,
        create: GALLERY_CREATE,
    },
    painter: {
        getAll: PAINTER_ALL,
        getList: PAINTER_LIST,
        card: PAINTER_CARD,
        create: PAINTER_CREATE,
    },
    user: {
        getList: `${USER_BASE}/list/`,
        card: `${USER_BASE}/`,
        create: `${USER_BASE}/create/`,
        profile: `/api/user/profile/`,
        roles: `${USER_BASE}/roles/`,
    },
    catalogAuction: {
        getAll: `${CATALOG_BASE}/all/`,
        getList: `${CATALOG_BASE}/list/`,
        card: `${CATALOG_BASE}/`,
        clone: `${CATALOG_BASE}/clone/`,
        create: `${CATALOG_BASE}/create/`,
        statuses: `${CATALOG_BASE}/statuses/`,
    },
    currency: {
        getAll: CURRENCY_ALL,
    },
    lots: {
        getAll: `${LOT_BASE}/all/`,
        getList: `${LOT_BASE}/list/`,
        card: `${LOT_BASE}/`,
        create: `${LOT_BASE}/create/`,
        statuses: `${LOT_BASE}/statuses/`,
        setStatus: `${LOT_BASE}/status/`,
        deleteMultiple: `${LOT_BASE}/multiple-delete/`,
    },
    orders: {
        getList: ORDERS_LIST,
        card: ORDERS_CARD,
        statuses: ORDERS_STATUSES,
    },
    specialty: {
        getAll: SPECIALTY_ALL,
        getList: SPECIALTY_LIST,
        card: SPECIALTY_CARD,
        create: SPECIALTY_CREATE,
    },
    settingAuction: {
        getAll: SETTING_ACTION_ALL,
        editItem: SETTING_ACTION_EDIT,
    },
    bids: {
        list: BIDS_LIST,
        cancel: BID_CANCEL,
    },
    transaction: {
        getList: TRANSACTION_LIST,
        create: TRANSACTION_CREATE,
        cancel: TRANSACTION_CANCEL,
        operationNames: TRANSACTION_OPERATION_NAMES,
    },
    faq: {
        getList: CONTENT_FAQ_LIST,
        card: CONTENT_FAQ,
        create: CONTENT_FAQ_CREATE,
    },
    about: {
        getPage: CONTENT_ABOUT,
        editPage: CONTENT_ABOUT_EDIT,
    },
    policy: {
        get: POLICY,
        edit: POLICY_EDIT,
    },
    news: {
        getList: CONTENT_NEWS_LIST,
        card: CONTENT_NEWS_CARD,
        create: CONTENT_NEWS_CREATE,
    },
    rules: {
        get: CONTENT_RULES,
        edit: CONTENT_RULES_EDIT,
    },
    import: {
        create: IMPORT_CREATE,
        types: IMPORT_TYPES,
        files: IMPORT_FILES,
        list: IMPORT_LIST,
        card: IMPORT_CARD,
        statuses: IMPORT_STATUSES,
    },
    terms: {
        get: CONTENT_TERMS,
        edit: CONTENT_TERMS_EDIT,
    },
    publicOffer: {
        get: CONTENT_PUBLIC_OFFER,
        edit: CONTENT_PUBLIC_OFFER_EDIT,
    },
    statistics: {
        get: STATISTICS,
    },
    user_question: {
        getList: USER_QUESTION_LIST,
        card: USER_QUESTION,
    },
    sale_offer: {
        getList: SALE_OFFER_LIST,
        card: SALE_OFFER,
    },
    address: {
        create: ADDRESS_CREATE,
        card: ADDRESS_CARD,
    },
    sliderContent: {
        list: SLIDER_CONTENT_TABLE,
        create: SLIDER_CONTENT_CREATE,
        card: SLIDER_CONTENT_CARD,
    },
    seo: {
        getList: SEO_LIST,
        get: SEO_LIST,
        update: SEO_UPDATE,
    },
    logs: {
        getList: `${API_BASE}user-action/list/`,
    },
    mailing: {
        getList: MAILING_LIST,
        create: MAILING_CREATE,
        detail: MAILING_DETAIL,
        remove: MAILING_DELETE,
        createAll: MAILING_CREATE_ALL,
    },
    tariffs: {
        list: TARIFFS_LIST,
        id: TARIFFS_ID,
    },
};

export default API;
