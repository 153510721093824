import { Module, Action, Mutation, getModule } from 'vuex-module-decorators';
import EntityBaseModule from '@/admin/store/entity';
import EntityModel from './entityModel';
import { modalParams } from './modalParams';

import store from '@/admin/store';
import { getGalleryCard, editGalleryCard, createGalleryCard } from '@/admin/api/gallery';
import ResponseHandlerModule from '@/admin/store/modules/responseHandler';
import { clearPhone, formatPhone, getApiErrorMessage, imageFileToBase64, showError } from '@/lib/utils/Utils';
import RedirectHandlerModule from '@/admin/store/modules/redirectHandler';
import { getSettingAuction } from '@/admin/api/settingAuction';
import { SettingsPainterInterface } from '@/interfaces/settingsPainter';
import SiteModule from '@/admin/store/site';
import { prepareLocalization, processLocalizationFromBackend } from '@/lib/utils/localization';

export const MODULE_NAME = 'sellersGalleryEntity';

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class SellersGalleryEntityModule extends EntityBaseModule {
    cardId: number | null = null;
    model: any;
    modalParams: any;
    galleryName = '';
    titleReturnData = {
        edit: {
            icon: 'bank',
            title: this.galleryName,
        },
        create: {
            icon: '',
            title: 'Добавление галереи',
        },
    };
    selectDefault = { id: 0, value: '' };

    constructor(module: SellersGalleryEntityModule) {
        super(module);

        const entityModel = new EntityModel();
        this.model = entityModel.model;
        this.modalParams = modalParams;
    }

    @Mutation
    SET_CARD_ID(id: number | null) {
        this.cardId = id;
    }

    @Mutation
    SET_GALLERY_NAME(name: string) {
        this.galleryName = name;
        this.titleReturnData.edit.title = name;
    }

    @Mutation
    SET_EMAIL(email: string) {
        this.model.email.value = email;
    }

    @Mutation
    SET_PHONE(phone: string) {
        this.model.phone.value = phone;
    }

    @Mutation
    SET_SITE(phone: string) {
        this.model.site.value = phone;
    }

    @Mutation
    SET_SORT_ORDER(sort: string) {
        this.model.sort.value = sort;
    }

    @Mutation
    SET_SITE_COMMISSION(value: string) {
        this.model.siteCommission.value = value;
    }

    @Mutation
    SET_STATUS(selected: { id: string; value: string }) {
        this.model.status.current = selected;
    }

    @Mutation
    SET_PHOTO(url: string) {
        this.model.photo.value = url;
    }

    @Mutation
    SET_PHOTO_FILE(file: File | null) {
        this.model.photo.file = file;
    }

    @Mutation
    SET_PHOTO_PREVIEW(base64String: string) {
        this.model.photo.preview = base64String;
    }

    @Mutation
    SET_MODAL_IS_SHOW(params: { key: string; bool: boolean }) {
        this.modalParams[params.key].isShow = params.bool;
    }

    @Mutation
    SET_MODAL_PARAMS_VALUE_BY_FIELD(params: { field: string; value: string }): void {
        this.modalParams[params.field].editor.value = params.value;
    }

    @Mutation
    SET_BALANCE(balance: number | null) {
        this.model.balance = typeof balance === 'number' ? `${balance} ₽` : balance;
    }

    @Action({ rawError: true })
    async initForm(galleryId: number) {
        if (Number.isInteger(galleryId)) {
            return;
        }

        const settings: SettingsPainterInterface[] = await getSettingAuction('?section=CommonGallery');
        const platformCommission = settings.filter((setting) => setting.name === 'platformCommission')[0];
        this.SET_SITE_COMMISSION(platformCommission.value as string);
    }

    @Action({ rawError: true })
    async initCardNameById(auctionHouseId: string) {
        const result = await getGalleryCard(auctionHouseId);

        this.context.commit('SET_GALLERY_NAME', result.name);
    }

    @Action({ rawError: true })
    async initCardById(galleryId: number | string) {
        try {
            this.SET_IS_LOADING(true);
            const result = await getGalleryCard(galleryId);

            this.setInfo(result);
            this.SET_IS_LOADING(false);
        } catch (error) {
            this.SET_IS_LOADING(false);
            showError(getApiErrorMessage(error));
        }
    }

    @Action({ rawError: true })
    setInfo(data: any) {
        if (!data) {
            return;
        }

        this.context.commit('SET_CARD_ID', data.id);
        this.context.commit('SET_GALLERY_NAME', data.name);
        this.context.commit('SET_SITE', data.site ? data.site : '');
        this.context.commit('SET_PHONE', data.phone ? formatPhone(data.phone) : '');
        this.context.commit('SET_EMAIL', data.email ? data.email : '');
        this.context.commit('SET_SORT_ORDER', data.sort ? data.sort : '');
        this.context.commit('SET_SITE_COMMISSION', data.commissionSite ? data.commissionSite : '');
        this.SET_MODEL_IMAGE_FIELD_URL({ field: 'photo', url: data.photo ?? '' });

        if (data.city) {
            const country = data.city.country;
            this.updateSuggestionsSelectedByType({
                selected: country?.id ? { id: country.id, value: country.name } : this.selectDefault,
                type: 'country',
            });

            this.updateSuggestionsSelectedByType({
                selected: data.city.id ? { id: data.city.id, value: data.city.name } : this.selectDefault,
                type: 'city',
            });
        }

        if (data.status) {
            this.context.commit('SET_STATUS', { id: data.status, value: '' });
        }

        this.SET_MODEL_FIELD_VALUE({ field: 'slug', value: data.slug || '' });

        this.SET_BALANCE(data.balance);
        this.SET_AVG_LOT_VALUE(data.avgLotValue ?? 0);
        this.SET_AVG_RECEIVED_FROM_LOT(data.avgReceivedFromLot ?? 0);
        this.SET_CATALOGS_PLACED(data.catalogsPlaced ?? 0);
        this.SET_LOTS_PLACED(data.lotsPlaced ?? 0);
        this.SET_TOTAL_VALUE_LOTS(data.totalValueLots ?? 0);
        this.SET_SOLD_LOTS(data.soldLots ?? 0);

        this.SET_TOP_SELLER(data.topSeller);

        Object.keys(data)
            .filter((key) => key.indexOf('bill') === 0 && key !== 'billLogo')
            .forEach((key) => {
                this.SET_MODEL_FIELD_VALUE({ field: key, value: data[key] });
            });
        this.SET_MODEL_IMAGE_FIELD_URL({ field: 'billLogo', url: data.billLogo ?? '' });

        this.SET_LOCALIZATION_BY_FIELD({
            fieldName: 'name',
            data: processLocalizationFromBackend(data),
        });

        this.SET_LOCALIZATION_BY_FIELD({
            fieldName: 'address',
            data: processLocalizationFromBackend(data, 'address'),
        });

        this.SET_LOCALIZATION_BY_FIELD({
            fieldName: 'terms',
            data: processLocalizationFromBackend(data, 'terms'),
        });

        this.SET_LOCALIZATION_BY_FIELD({
            fieldName: 'description',
            data: processLocalizationFromBackend(data, 'description'),
        });
    }

    @Action({ rawError: true })
    updateSite(site: string) {
        this.context.commit('SET_SITE', site);
    }

    @Action({ rawError: true })
    updatePhone(phone: string) {
        this.context.commit('SET_PHONE', phone);
    }

    @Action({ rawError: true })
    updateEmail(email: string) {
        this.context.commit('SET_EMAIL', email);
    }

    @Action({ rawError: true })
    updateSortOrder(sort: string) {
        this.context.commit('SET_SORT_ORDER', sort);
    }

    @Action({ rawError: true })
    updateSiteCommission(value: string) {
        this.context.commit('SET_SITE_COMMISSION', value);
    }

    @Action({ rawError: true })
    toggleModal(params: { key: string; bool: boolean }) {
        this.context.commit('SET_MODAL_IS_SHOW', params);
    }

    @Action({ rawError: true })
    async updatePhotoFile(file: File) {
        this.context.commit('SET_PHOTO_FILE', file);

        const preview = await imageFileToBase64(file);
        this.context.commit('SET_PHOTO_PREVIEW', preview);
    }

    @Action({ rawError: true })
    removeImagePreview() {
        this.context.commit('SET_PHOTO_FILE', null);
        this.context.commit('SET_PHOTO_PREVIEW', '');
    }

    @Action({ rawError: true })
    removeImage() {
        this.context.commit('SET_PHOTO', '');
    }

    @Action({ rawError: true })
    updateModalParamsByField(params: { field: string; value: string }): void {
        this.SET_MODAL_PARAMS_VALUE_BY_FIELD(params);
    }

    @Action({ rawError: true })
    async sendEdit() {
        const isError = await this.validate();

        if (isError === true) {
            ResponseHandlerModule.showNotify({ message: 'Заполните обязательные поля', type: 'fail' });

            return;
        }

        SiteModule.SET_IS_BLOCK(true);

        try {
            const formData = await this.prepareRequestData();
            let result: any;

            if (this.cardId != null) {
                result = await editGalleryCard(this.cardId, formData);
            }

            if (!result.message) {
                ResponseHandlerModule.showNotify({ message: 'Изменения сохранены', type: 'ok' });
                SiteModule.SET_IS_BLOCK(false);

                this.SET_MODEL_IMAGE_FIELD_URL({ field: 'billLogo', url: result.data.result.item.billLogo });
                this.SET_MODEL_IMAGE_FIELD_FILE({ field: 'billLogo', file: null });
                this.SET_MODEL_IMAGE_FIELD_PREVIEW({ field: 'billLogo', base64String: '' });

                this.SET_MODEL_IMAGE_FIELD_URL({ field: 'photo', url: result.data.result.item.photo });
                this.SET_MODEL_IMAGE_FIELD_FILE({ field: 'photo', file: null });
                this.SET_MODEL_IMAGE_FIELD_PREVIEW({ field: 'photo', base64String: '' });
            } else {
                ResponseHandlerModule.showNotify({ message: result.message, type: 'fail' });
            }
        } catch (error) {
            ResponseHandlerModule.showNotify({ message: await getApiErrorMessage(error), type: 'fail' });
        }

        SiteModule.SET_IS_BLOCK(false);
    }

    @Action({ rawError: true })
    async sendAdd(redirect: any = null) {
        const isError = await this.validate();

        if (isError === true) {
            ResponseHandlerModule.showNotify({ message: 'Заполните обязательные поля', type: 'fail' });

            return;
        }

        SiteModule.SET_IS_BLOCK(true);

        try {
            const formData = await this.prepareRequestData();
            const result = (await createGalleryCard(formData)) as any;

            if (!result.message) {
                ResponseHandlerModule.showNotify({ message: 'Изменения сохранены', type: 'ok' });
                RedirectHandlerModule.changeUrl(redirect);
            } else {
                ResponseHandlerModule.showNotify({ message: result.message, type: 'fail' });
            }
        } catch (error) {
            ResponseHandlerModule.showNotify({ message: await getApiErrorMessage(error), type: 'fail' });
        }

        SiteModule.SET_IS_BLOCK(false);
    }

    @Action({ rawError: true })
    prepareRequestData() {
        let data: Record<string, string | number> = {
            name: this.model.name.localization.ru,
            status: this.model.status.current.id,
            city: this.model.city.current.id,
            topSeller: this.model.topSeller.list[0].checked ? 1 : 0,
            slug: this.model.slug.value,
        };

        data = {
            ...data,
            ...prepareLocalization(this.model.name),
            ...prepareLocalization(this.model.address, 'address'),
            ...prepareLocalization(this.model.terms, 'terms'),
            ...prepareLocalization(this.model.description, 'description'),
        };

        if (this.model.address.localization.ru) {
            data['address'] = this.model.address.localization.ru;
        }

        if (this.model.terms.localization.ru) {
            data['terms'] = this.model.terms.localization.ru;
        }

        if (this.model.description.localization.ru) {
            data['description'] = this.model.description.localization.ru;
        }

        if (this.model.site.value) {
            data['site'] = this.model.site.value;
        }

        if (this.model.email.value) {
            data['email'] = this.model.email.value;
        }

        if (this.model.phone.value) {
            data['phone'] = clearPhone(this.model.phone.value);
        }

        if (this.model.sort.value) {
            data['sort'] = Number(this.model.sort.value);
        }

        if (this.model.siteCommission.value) {
            data['commissionSite'] = +this.model.siteCommission.value;
        }

        const photo = this.model.photo.file ?? null;
        if (photo) {
            data['photo'] = photo;
        }

        Object.keys(this.model)
            .filter((key) => key.indexOf('bill') === 0 && key !== 'billLogo')
            .forEach((key) => {
                if (this.model[key]?.value?.length) {
                    data[key] = this.model[key].value;
                }
            });

        const billLogo = this.model.billLogo.file ?? null;
        if (billLogo) {
            data['billLogo'] = billLogo;
        }

        return data;
    }

    @Action({ rawError: true })
    validate() {
        const requiredFields = ['name', 'city'];
        this.context.commit('RESET_ERROR_BY_FIELD', 'name');
        this.context.commit('RESET_ERROR_BY_FIELD', 'city');

        this.setValidateState({ index: 'name', field: this.model.name });
        this.setValidateSelect({ index: 'city', field: this.model.city });

        const fieldsWithError = requiredFields.filter((field) => this.model[field].error.class === 'cp-error');

        return fieldsWithError.length > 0 ? true : false;
    }

    @Action({ rawError: true })
    reset() {
        this.context.commit('SET_IS_LOADING', true);
        this.context.commit('SET_CARD_ID', null);
        this.context.commit('SET_ADDRESS', '');
        this.context.commit('SET_NAME', '');
        this.context.commit('SET_SITE', '');
        this.context.commit('SET_PHONE', '');
        this.context.commit('SET_EMAIL', '');
        this.context.commit('SET_SORT_ORDER', '');
        this.context.commit('SET_SITE_COMMISSION', '');
        this.context.commit('SET_STATUS', this.selectDefault);
        this.context.commit('SET_CREATED_AT', '');
        this.SET_MODEL_IMAGE_FIELD_URL({ field: 'photo', url: '' });
        this.SET_MODEL_IMAGE_FIELD_FILE({ field: 'photo', file: null });
        this.SET_MODEL_IMAGE_FIELD_PREVIEW({ field: 'photo', base64String: '' });

        this.resetSuggestionsStateByType('country');
        this.resetSuggestionsStateByType('city');

        this.SET_BALANCE(null);
        this.SET_AVG_LOT_VALUE(null);
        this.SET_AVG_RECEIVED_FROM_LOT(null);
        this.SET_CATALOGS_PLACED(null);
        this.SET_LOTS_PLACED(null);
        this.SET_TOTAL_VALUE_LOTS(null);
        this.SET_SOLD_LOTS(null);
        this.SET_MODEL_FIELD_VALUE({ field: 'slug', value: '' });

        this.SET_TOP_SELLER(false);
        SiteModule.SET_IS_BLOCK(false);

        Object.keys(this.model)
            .filter((key) => key.indexOf('bill') === 0 && key !== 'billLogo')
            .forEach((key) => {
                this.SET_MODEL_FIELD_VALUE({ field: key, value: '' });
            });
        this.SET_MODEL_IMAGE_FIELD_URL({ field: 'billLogo', url: '' });
        this.SET_MODEL_IMAGE_FIELD_FILE({ field: 'billLogo', file: null });
        this.SET_MODEL_IMAGE_FIELD_PREVIEW({ field: 'billLogo', base64String: '' });

        this.SET_MODAL_PARAMS_VALUE_BY_FIELD({ field: 'terms', value: '' });
        this.SET_MODAL_PARAMS_VALUE_BY_FIELD({ field: 'description', value: '' });

        this.RESET_LOCALIZATION_BY_FIELD();
        this.RESET_LOCALIZATION_BY_FIELD('address');
        this.RESET_LOCALIZATION_BY_FIELD('description');
        this.RESET_LOCALIZATION_BY_FIELD('terms');

        this.resetErrorState();
    }

    @Action({ rawError: true })
    resetErrorState() {
        this.context.commit('RESET_ERROR_BY_FIELD', 'name');
        this.context.commit('RESET_ERROR_BY_FIELD', 'city');
    }

    @Action({ rawError: true })
    resetGalleryName() {
        this.context.commit('SET_GALLERY_NAME', '');
    }
}

export default getModule(SellersGalleryEntityModule);
