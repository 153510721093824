/*
 *
 * */

import { InputTimePicker } from '@/lib/formFactory/inputTimePicker.interface';
export default class InputTimePickerEntity {
    defaultValue: InputTimePicker;
    entityName: string;
    entity: any;

    constructor(name: string) {
        this.defaultValue = {
            key: name,
            type: 'timePicker',
            title: 'Интервал смены',
            value: '18:00',
            hour: '18',
            minute: '00',
            action: '',
            required: true,
            start: 0,
            end: 24,
            step: 5,
            disabled: false,
            error: {
                class: '',
                message: '',
            },
            validation: {
                empty: 'Поле не заполнено',
            },
        };
        this.entityName = name;
        this.entity = { [this.entityName]: this.defaultValue };
    }

    setType(type: string) {
        this.entity[this.entityName]['type'] = type;
    }

    setTitle(title: string) {
        this.entity[this.entityName]['title'] = title;
    }

    setPlaceholder(placeholder: string) {
        this.entity[this.entityName]['placeholder'] = placeholder;
    }

    setRequired(required: boolean) {
        this.entity[this.entityName]['required'] = required;
    }

    setMask(mask: {
        regex?: string;
        mask?: string;
        alias?: string;
        inputFormat?: string;
        clearIncomplete?: boolean;
        placeholder?: string;
        minChars?: number;
    }) {
        this.entity[this.entityName]['mask'] = mask;
    }

    setValidation(validation: any) {
        this.entity[this.entityName]['validation'] = validation;
    }

    setSize(size: number) {
        this.entity[this.entityName]['size'] = size;
    }

    setAction(action: string) {
        this.entity[this.entityName]['action'] = action;
    }
}
