/**
 * Roles store.
 *
 */

import { Module, VuexModule, getModule } from 'vuex-module-decorators';
import store from '.';

export const MODULE_NAME = 'role';

export enum RoleEnum {
    ROLE_SELLER_PAINTER = 'ROLE_SELLER_PAINTER',
    ROLE_SELLER_GALLERY = 'ROLE_SELLER_GALLERY',
    ROLE_SELLER_AUCTION_HOUSE_ADMIN = 'ROLE_SELLER_AUCTION_HOUSE_ADMIN',
    ROLE_SELLER_AUCTION_HOUSE_OPERATOR = 'ROLE_SELLER_AUCTION_HOUSE_OPERATOR',
    ROLE_SUPER_ADMIN = 'ROLE_SUPER_ADMIN',
}

export const AUCTIONLESS_SELLER_ROLES = [RoleEnum.ROLE_SELLER_PAINTER, RoleEnum.ROLE_SELLER_GALLERY];

export interface AccessByRouter {
    [index: string]: {
        read: RoleEnum[];
        write: RoleEnum[];
    };
}

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class RoleModule extends VuexModule {
    roles = RoleEnum;
    homePages: { [key in RoleEnum]?: string } = {};

    accessByMenuItemName: AccessByRouter = {
        auctions: {
            read: [
                RoleEnum.ROLE_SUPER_ADMIN,
                RoleEnum.ROLE_SELLER_AUCTION_HOUSE_ADMIN,
                RoleEnum.ROLE_SELLER_GALLERY,
                RoleEnum.ROLE_SELLER_PAINTER,
                RoleEnum.ROLE_SELLER_AUCTION_HOUSE_OPERATOR,
            ],
            write: [
                RoleEnum.ROLE_SUPER_ADMIN,
                RoleEnum.ROLE_SELLER_AUCTION_HOUSE_ADMIN,
                RoleEnum.ROLE_SELLER_GALLERY,
                RoleEnum.ROLE_SELLER_PAINTER,
                RoleEnum.ROLE_SELLER_AUCTION_HOUSE_OPERATOR,
            ],
        },
        lots: {
            read: [
                RoleEnum.ROLE_SUPER_ADMIN,
                RoleEnum.ROLE_SELLER_AUCTION_HOUSE_ADMIN,
                RoleEnum.ROLE_SELLER_GALLERY,
                RoleEnum.ROLE_SELLER_PAINTER,
                RoleEnum.ROLE_SELLER_AUCTION_HOUSE_OPERATOR,
            ],
            write: [
                RoleEnum.ROLE_SUPER_ADMIN,
                RoleEnum.ROLE_SELLER_AUCTION_HOUSE_ADMIN,
                RoleEnum.ROLE_SELLER_GALLERY,
                RoleEnum.ROLE_SELLER_PAINTER,
                RoleEnum.ROLE_SELLER_AUCTION_HOUSE_OPERATOR,
            ],
        },
        finance: {
            read: [RoleEnum.ROLE_SUPER_ADMIN],
            write: [RoleEnum.ROLE_SUPER_ADMIN],
        },
        users: {
            read: [
                RoleEnum.ROLE_SUPER_ADMIN,
                RoleEnum.ROLE_SELLER_AUCTION_HOUSE_ADMIN,
                RoleEnum.ROLE_SELLER_GALLERY,
                RoleEnum.ROLE_SELLER_PAINTER,
            ],
            write: [
                RoleEnum.ROLE_SUPER_ADMIN,
                RoleEnum.ROLE_SELLER_AUCTION_HOUSE_ADMIN,
                RoleEnum.ROLE_SELLER_GALLERY,
                RoleEnum.ROLE_SELLER_PAINTER,
            ],
        },
        auction_houses: {
            read: [RoleEnum.ROLE_SUPER_ADMIN],
            write: [RoleEnum.ROLE_SUPER_ADMIN],
        },
        auction_house: {
            read: [RoleEnum.ROLE_SUPER_ADMIN, RoleEnum.ROLE_SELLER_AUCTION_HOUSE_ADMIN],
            write: [RoleEnum.ROLE_SUPER_ADMIN, RoleEnum.ROLE_SELLER_AUCTION_HOUSE_ADMIN],
        },
        gallery: {
            read: [RoleEnum.ROLE_SUPER_ADMIN, RoleEnum.ROLE_SELLER_GALLERY],
            write: [RoleEnum.ROLE_SUPER_ADMIN, RoleEnum.ROLE_SELLER_GALLERY],
        },
        painter: {
            read: [RoleEnum.ROLE_SUPER_ADMIN, RoleEnum.ROLE_SELLER_PAINTER],
            write: [RoleEnum.ROLE_SUPER_ADMIN, RoleEnum.ROLE_SELLER_PAINTER],
        },
        questions: {
            read: [RoleEnum.ROLE_SUPER_ADMIN],
            write: [RoleEnum.ROLE_SUPER_ADMIN],
        },
        content: {
            read: [RoleEnum.ROLE_SUPER_ADMIN],
            write: [RoleEnum.ROLE_SUPER_ADMIN],
        },
        settings_auctions: {
            read: [RoleEnum.ROLE_SUPER_ADMIN],
            write: [RoleEnum.ROLE_SUPER_ADMIN],
        },
        seo: {
            read: [RoleEnum.ROLE_SUPER_ADMIN],
            write: [RoleEnum.ROLE_SUPER_ADMIN],
        },
        mailing: {
            read: [RoleEnum.ROLE_SUPER_ADMIN],
            write: [RoleEnum.ROLE_SUPER_ADMIN],
        },
        mailing_edit: {
            read: [RoleEnum.ROLE_SUPER_ADMIN],
            write: [RoleEnum.ROLE_SUPER_ADMIN],
        },
        tariffs: {
            read: [RoleEnum.ROLE_SUPER_ADMIN],
            write: [RoleEnum.ROLE_SUPER_ADMIN],
        },
        tariffs_edit: {
            read: [RoleEnum.ROLE_SUPER_ADMIN],
            write: [RoleEnum.ROLE_SUPER_ADMIN],
        },
    };

    accessByRouterName: AccessByRouter = {
        home: {
            read: [
                RoleEnum.ROLE_SUPER_ADMIN,
                RoleEnum.ROLE_SELLER_AUCTION_HOUSE_ADMIN,
                RoleEnum.ROLE_SELLER_GALLERY,
                RoleEnum.ROLE_SELLER_PAINTER,
            ],
            write: [
                RoleEnum.ROLE_SUPER_ADMIN,
                RoleEnum.ROLE_SELLER_AUCTION_HOUSE_ADMIN,
                RoleEnum.ROLE_SELLER_GALLERY,
                RoleEnum.ROLE_SELLER_PAINTER,
            ],
        },
        auctions: {
            read: [
                RoleEnum.ROLE_SUPER_ADMIN,
                RoleEnum.ROLE_SELLER_AUCTION_HOUSE_ADMIN,
                RoleEnum.ROLE_SELLER_AUCTION_HOUSE_OPERATOR,
            ],
            write: [RoleEnum.ROLE_SUPER_ADMIN, RoleEnum.ROLE_SELLER_AUCTION_HOUSE_ADMIN],
        },
        auction: {
            read: [
                RoleEnum.ROLE_SUPER_ADMIN,
                RoleEnum.ROLE_SELLER_AUCTION_HOUSE_ADMIN,
                RoleEnum.ROLE_SELLER_AUCTION_HOUSE_OPERATOR,
                RoleEnum.ROLE_SELLER_GALLERY,
                RoleEnum.ROLE_SELLER_PAINTER,
            ],
            write: [
                RoleEnum.ROLE_SUPER_ADMIN,
                RoleEnum.ROLE_SELLER_AUCTION_HOUSE_ADMIN,
                RoleEnum.ROLE_SELLER_GALLERY,
                RoleEnum.ROLE_SELLER_PAINTER,
            ],
        },
        auction_lots: {
            read: [
                RoleEnum.ROLE_SUPER_ADMIN,
                RoleEnum.ROLE_SELLER_AUCTION_HOUSE_ADMIN,
                RoleEnum.ROLE_SELLER_GALLERY,
                RoleEnum.ROLE_SELLER_PAINTER,
            ],
            write: [
                RoleEnum.ROLE_SUPER_ADMIN,
                RoleEnum.ROLE_SELLER_AUCTION_HOUSE_ADMIN,
                RoleEnum.ROLE_SELLER_GALLERY,
                RoleEnum.ROLE_SELLER_PAINTER,
            ],
        },
        auction_bidding: {
            read: [RoleEnum.ROLE_SELLER_AUCTION_HOUSE_OPERATOR],
            write: [RoleEnum.ROLE_SELLER_AUCTION_HOUSE_OPERATOR],
        },
        auction_create: {
            read: [
                RoleEnum.ROLE_SUPER_ADMIN,
                RoleEnum.ROLE_SELLER_AUCTION_HOUSE_ADMIN,
                RoleEnum.ROLE_SELLER_GALLERY,
                RoleEnum.ROLE_SELLER_PAINTER,
            ],
            write: [
                RoleEnum.ROLE_SUPER_ADMIN,
                RoleEnum.ROLE_SELLER_AUCTION_HOUSE_ADMIN,
                RoleEnum.ROLE_SELLER_GALLERY,
                RoleEnum.ROLE_SELLER_PAINTER,
            ],
        },
        lots: {
            read: [
                RoleEnum.ROLE_SUPER_ADMIN,
                RoleEnum.ROLE_SELLER_AUCTION_HOUSE_ADMIN,
                RoleEnum.ROLE_SELLER_GALLERY,
                RoleEnum.ROLE_SELLER_PAINTER,
            ],
            write: [RoleEnum.ROLE_SUPER_ADMIN],
        },
        order: {
            read: [
                RoleEnum.ROLE_SUPER_ADMIN,
                RoleEnum.ROLE_SELLER_AUCTION_HOUSE_ADMIN,
                RoleEnum.ROLE_SELLER_AUCTION_HOUSE_OPERATOR,
                RoleEnum.ROLE_SELLER_GALLERY,
                RoleEnum.ROLE_SELLER_PAINTER,
            ],
            write: [
                RoleEnum.ROLE_SUPER_ADMIN,
                RoleEnum.ROLE_SELLER_AUCTION_HOUSE_ADMIN,
                RoleEnum.ROLE_SELLER_GALLERY,
                RoleEnum.ROLE_SELLER_PAINTER,
            ],
        },
        orders: {
            read: [
                RoleEnum.ROLE_SUPER_ADMIN,
                RoleEnum.ROLE_SELLER_AUCTION_HOUSE_ADMIN,
                RoleEnum.ROLE_SELLER_GALLERY,
                RoleEnum.ROLE_SELLER_PAINTER,
            ],
            write: [RoleEnum.ROLE_SUPER_ADMIN],
        },
        user: {
            read: [
                RoleEnum.ROLE_SUPER_ADMIN,
                RoleEnum.ROLE_SELLER_AUCTION_HOUSE_ADMIN,
                RoleEnum.ROLE_SELLER_GALLERY,
                RoleEnum.ROLE_SELLER_PAINTER,
            ],
            write: [
                RoleEnum.ROLE_SUPER_ADMIN,
                RoleEnum.ROLE_SELLER_AUCTION_HOUSE_ADMIN,
                RoleEnum.ROLE_SELLER_GALLERY,
                RoleEnum.ROLE_SELLER_PAINTER,
            ],
        },
        user_create: {
            read: [
                RoleEnum.ROLE_SUPER_ADMIN,
                RoleEnum.ROLE_SELLER_AUCTION_HOUSE_ADMIN,
                RoleEnum.ROLE_SELLER_GALLERY,
                RoleEnum.ROLE_SELLER_PAINTER,
            ],
            write: [
                RoleEnum.ROLE_SUPER_ADMIN,
                RoleEnum.ROLE_SELLER_AUCTION_HOUSE_ADMIN,
                RoleEnum.ROLE_SELLER_GALLERY,
                RoleEnum.ROLE_SELLER_PAINTER,
            ],
        },
        user_balance: {
            read: [
                RoleEnum.ROLE_SUPER_ADMIN,
                RoleEnum.ROLE_SELLER_AUCTION_HOUSE_ADMIN,
                RoleEnum.ROLE_SELLER_GALLERY,
                RoleEnum.ROLE_SELLER_PAINTER,
            ],
            write: [RoleEnum.ROLE_SUPER_ADMIN],
        },
        user_logs: {
            read: [RoleEnum.ROLE_SUPER_ADMIN, RoleEnum.ROLE_SELLER_AUCTION_HOUSE_OPERATOR],
            write: [RoleEnum.ROLE_SUPER_ADMIN, RoleEnum.ROLE_SELLER_AUCTION_HOUSE_OPERATOR],
        },
        users: {
            read: [
                RoleEnum.ROLE_SUPER_ADMIN,
                RoleEnum.ROLE_SELLER_AUCTION_HOUSE_ADMIN,
                RoleEnum.ROLE_SELLER_GALLERY,
                RoleEnum.ROLE_SELLER_PAINTER,
            ],
            write: [
                RoleEnum.ROLE_SUPER_ADMIN,
                RoleEnum.ROLE_SELLER_AUCTION_HOUSE_ADMIN,
                RoleEnum.ROLE_SELLER_GALLERY,
                RoleEnum.ROLE_SELLER_PAINTER,
            ],
        },
        questions: {
            read: [RoleEnum.ROLE_SUPER_ADMIN],
            write: [RoleEnum.ROLE_SUPER_ADMIN],
        },
        questions_auction_houses: {
            read: [RoleEnum.ROLE_SUPER_ADMIN],
            write: [RoleEnum.ROLE_SUPER_ADMIN],
        },
        questions_galleries: {
            read: [RoleEnum.ROLE_SUPER_ADMIN],
            write: [RoleEnum.ROLE_SUPER_ADMIN],
        },
        questions_painters: {
            read: [RoleEnum.ROLE_SUPER_ADMIN],
            write: [RoleEnum.ROLE_SUPER_ADMIN],
        },
        questions_sell: {
            read: [RoleEnum.ROLE_SUPER_ADMIN],
            write: [RoleEnum.ROLE_SUPER_ADMIN],
        },
        auction_houses: {
            read: [RoleEnum.ROLE_SUPER_ADMIN],
            write: [RoleEnum.ROLE_SUPER_ADMIN],
        },
        auction_house: {
            read: [
                RoleEnum.ROLE_SUPER_ADMIN,
                RoleEnum.ROLE_SELLER_AUCTION_HOUSE_ADMIN,
                RoleEnum.ROLE_SELLER_AUCTION_HOUSE_OPERATOR,
            ],
            write: [RoleEnum.ROLE_SUPER_ADMIN, RoleEnum.ROLE_SELLER_AUCTION_HOUSE_ADMIN],
        },
        auction_house_add: {
            read: [RoleEnum.ROLE_SUPER_ADMIN],
            write: [RoleEnum.ROLE_SUPER_ADMIN],
        },
        auction_house_catalogs: {
            read: [RoleEnum.ROLE_SUPER_ADMIN, RoleEnum.ROLE_SELLER_AUCTION_HOUSE_ADMIN],
            write: [RoleEnum.ROLE_SUPER_ADMIN, RoleEnum.ROLE_SELLER_AUCTION_HOUSE_ADMIN],
        },
        auction_house_catalog: {
            read: [RoleEnum.ROLE_SUPER_ADMIN, RoleEnum.ROLE_SELLER_AUCTION_HOUSE_ADMIN],
            write: [RoleEnum.ROLE_SUPER_ADMIN, RoleEnum.ROLE_SELLER_AUCTION_HOUSE_ADMIN],
        },
        auction_house_lots: {
            read: [RoleEnum.ROLE_SUPER_ADMIN, RoleEnum.ROLE_SELLER_AUCTION_HOUSE_ADMIN],
            write: [RoleEnum.ROLE_SUPER_ADMIN, RoleEnum.ROLE_SELLER_AUCTION_HOUSE_ADMIN],
        },
        auction_house_users: {
            read: [RoleEnum.ROLE_SUPER_ADMIN, RoleEnum.ROLE_SELLER_AUCTION_HOUSE_ADMIN],
            write: [RoleEnum.ROLE_SUPER_ADMIN, RoleEnum.ROLE_SELLER_AUCTION_HOUSE_ADMIN],
        },
        auction_house_orders: {
            read: [RoleEnum.ROLE_SUPER_ADMIN, RoleEnum.ROLE_SELLER_AUCTION_HOUSE_ADMIN],
            write: [RoleEnum.ROLE_SUPER_ADMIN, RoleEnum.ROLE_SELLER_AUCTION_HOUSE_ADMIN],
        },
        auction_house_balance: {
            read: [RoleEnum.ROLE_SUPER_ADMIN, RoleEnum.ROLE_SELLER_AUCTION_HOUSE_ADMIN],
            write: [RoleEnum.ROLE_SUPER_ADMIN],
        },
        auction_house_approved_users: {
            read: [RoleEnum.ROLE_SUPER_ADMIN, RoleEnum.ROLE_SELLER_AUCTION_HOUSE_ADMIN],
            write: [RoleEnum.ROLE_SUPER_ADMIN, RoleEnum.ROLE_SELLER_AUCTION_HOUSE_ADMIN],
        },
        auction_house_approved_user: {
            read: [RoleEnum.ROLE_SUPER_ADMIN, RoleEnum.ROLE_SELLER_AUCTION_HOUSE_ADMIN],
            write: [RoleEnum.ROLE_SUPER_ADMIN, RoleEnum.ROLE_SELLER_AUCTION_HOUSE_ADMIN],
        },
        auction_house_approved_user_requests: {
            read: [RoleEnum.ROLE_SUPER_ADMIN, RoleEnum.ROLE_SELLER_AUCTION_HOUSE_ADMIN],
            write: [RoleEnum.ROLE_SUPER_ADMIN, RoleEnum.ROLE_SELLER_AUCTION_HOUSE_ADMIN],
        },
        galleries: {
            read: [RoleEnum.ROLE_SUPER_ADMIN],
            write: [RoleEnum.ROLE_SUPER_ADMIN],
        },
        gallery: {
            read: [RoleEnum.ROLE_SUPER_ADMIN, RoleEnum.ROLE_SELLER_GALLERY],
            write: [RoleEnum.ROLE_SUPER_ADMIN, RoleEnum.ROLE_SELLER_GALLERY],
        },
        gallery_create: {
            read: [RoleEnum.ROLE_SUPER_ADMIN],
            write: [RoleEnum.ROLE_SUPER_ADMIN],
        },
        gallery_lots: {
            read: [RoleEnum.ROLE_SUPER_ADMIN, RoleEnum.ROLE_SELLER_GALLERY],
            write: [RoleEnum.ROLE_SUPER_ADMIN, RoleEnum.ROLE_SELLER_GALLERY],
        },
        gallery_orders: {
            read: [RoleEnum.ROLE_SUPER_ADMIN, RoleEnum.ROLE_SELLER_GALLERY],
            write: [RoleEnum.ROLE_SUPER_ADMIN, RoleEnum.ROLE_SELLER_GALLERY],
        },
        gallery_users: {
            read: [RoleEnum.ROLE_SUPER_ADMIN, RoleEnum.ROLE_SELLER_GALLERY],
            write: [RoleEnum.ROLE_SUPER_ADMIN, RoleEnum.ROLE_SELLER_GALLERY],
        },
        gallery_balance: {
            read: [RoleEnum.ROLE_SUPER_ADMIN, RoleEnum.ROLE_SELLER_GALLERY],
            write: [RoleEnum.ROLE_SUPER_ADMIN],
        },
        painters: {
            read: [RoleEnum.ROLE_SUPER_ADMIN],
            write: [RoleEnum.ROLE_SUPER_ADMIN],
        },
        painter: {
            read: [RoleEnum.ROLE_SUPER_ADMIN, RoleEnum.ROLE_SELLER_PAINTER],
            write: [RoleEnum.ROLE_SUPER_ADMIN, RoleEnum.ROLE_SELLER_PAINTER],
        },
        painter_create: {
            read: [RoleEnum.ROLE_SUPER_ADMIN],
            write: [RoleEnum.ROLE_SUPER_ADMIN],
        },
        painter_lots: {
            read: [RoleEnum.ROLE_SUPER_ADMIN, RoleEnum.ROLE_SELLER_PAINTER],
            write: [RoleEnum.ROLE_SUPER_ADMIN, RoleEnum.ROLE_SELLER_PAINTER],
        },
        painter_works: {
            read: [RoleEnum.ROLE_SUPER_ADMIN, RoleEnum.ROLE_SELLER_PAINTER],
            write: [RoleEnum.ROLE_SUPER_ADMIN, RoleEnum.ROLE_SELLER_PAINTER],
        },
        painter_work: {
            read: [RoleEnum.ROLE_SUPER_ADMIN, RoleEnum.ROLE_SELLER_PAINTER],
            write: [RoleEnum.ROLE_SUPER_ADMIN, RoleEnum.ROLE_SELLER_PAINTER],
        },
        painter_work_create: {
            read: [RoleEnum.ROLE_SUPER_ADMIN, RoleEnum.ROLE_SELLER_PAINTER],
            write: [RoleEnum.ROLE_SUPER_ADMIN, RoleEnum.ROLE_SELLER_PAINTER],
        },
        painter_orders: {
            read: [RoleEnum.ROLE_SUPER_ADMIN, RoleEnum.ROLE_SELLER_PAINTER],
            write: [RoleEnum.ROLE_SUPER_ADMIN, RoleEnum.ROLE_SELLER_PAINTER],
        },
        painter_users: {
            read: [RoleEnum.ROLE_SUPER_ADMIN, RoleEnum.ROLE_SELLER_PAINTER],
            write: [RoleEnum.ROLE_SUPER_ADMIN, RoleEnum.ROLE_SELLER_PAINTER],
        },
        painter_balance: {
            read: [RoleEnum.ROLE_SUPER_ADMIN, RoleEnum.ROLE_SELLER_PAINTER],
            write: [RoleEnum.ROLE_SUPER_ADMIN],
        },
        finance: {
            read: [RoleEnum.ROLE_SUPER_ADMIN],
            write: [RoleEnum.ROLE_SUPER_ADMIN],
        },
        finance_customer: {
            read: [RoleEnum.ROLE_SUPER_ADMIN],
            write: [RoleEnum.ROLE_SUPER_ADMIN],
        },
        finance_seller: {
            read: [RoleEnum.ROLE_SUPER_ADMIN],
            write: [RoleEnum.ROLE_SUPER_ADMIN],
        },
        content: {
            read: [RoleEnum.ROLE_SUPER_ADMIN],
            write: [RoleEnum.ROLE_SUPER_ADMIN],
        },
        content_news: {
            read: [RoleEnum.ROLE_SUPER_ADMIN],
            write: [RoleEnum.ROLE_SUPER_ADMIN],
        },
        content_news_create: {
            read: [RoleEnum.ROLE_SUPER_ADMIN],
            write: [RoleEnum.ROLE_SUPER_ADMIN],
        },
        content_news_card: {
            read: [RoleEnum.ROLE_SUPER_ADMIN],
            write: [RoleEnum.ROLE_SUPER_ADMIN],
        },
        content_main_page: {
            read: [RoleEnum.ROLE_SUPER_ADMIN],
            write: [RoleEnum.ROLE_SUPER_ADMIN],
        },
        slider_content_card: {
            read: [RoleEnum.ROLE_SUPER_ADMIN],
            write: [RoleEnum.ROLE_SUPER_ADMIN],
        },
        slider_content_card_create: {
            read: [RoleEnum.ROLE_SUPER_ADMIN],
            write: [RoleEnum.ROLE_SUPER_ADMIN],
        },
        content_about: {
            read: [RoleEnum.ROLE_SUPER_ADMIN],
            write: [RoleEnum.ROLE_SUPER_ADMIN],
        },
        content_rules: {
            read: [RoleEnum.ROLE_SUPER_ADMIN],
            write: [RoleEnum.ROLE_SUPER_ADMIN],
        },
        content_faq: {
            read: [RoleEnum.ROLE_SUPER_ADMIN],
            write: [RoleEnum.ROLE_SUPER_ADMIN],
        },
        content_faq_create: {
            read: [RoleEnum.ROLE_SUPER_ADMIN],
            write: [RoleEnum.ROLE_SUPER_ADMIN],
        },
        content_faq_card: {
            read: [RoleEnum.ROLE_SUPER_ADMIN],
            write: [RoleEnum.ROLE_SUPER_ADMIN],
        },
        content_policy: {
            read: [RoleEnum.ROLE_SUPER_ADMIN],
            write: [RoleEnum.ROLE_SUPER_ADMIN],
        },
        content_terms: {
            read: [RoleEnum.ROLE_SUPER_ADMIN],
            write: [RoleEnum.ROLE_SUPER_ADMIN],
        },
        content_offer: {
            read: [RoleEnum.ROLE_SUPER_ADMIN],
            write: [RoleEnum.ROLE_SUPER_ADMIN],
        },
        settings_auctions: {
            read: [RoleEnum.ROLE_SUPER_ADMIN],
            write: [RoleEnum.ROLE_SUPER_ADMIN],
        },
        settings_cities: {
            read: [RoleEnum.ROLE_SUPER_ADMIN],
            write: [RoleEnum.ROLE_SUPER_ADMIN],
        },
        settings_city: {
            read: [RoleEnum.ROLE_SUPER_ADMIN],
            write: [RoleEnum.ROLE_SUPER_ADMIN],
        },
        settings_city_create: {
            read: [RoleEnum.ROLE_SUPER_ADMIN],
            write: [RoleEnum.ROLE_SUPER_ADMIN],
        },
        settings_countries: {
            read: [RoleEnum.ROLE_SUPER_ADMIN],
            write: [RoleEnum.ROLE_SUPER_ADMIN],
        },
        settings_country: {
            read: [RoleEnum.ROLE_SUPER_ADMIN],
            write: [RoleEnum.ROLE_SUPER_ADMIN],
        },
        settings_country_create: {
            read: [RoleEnum.ROLE_SUPER_ADMIN],
            write: [RoleEnum.ROLE_SUPER_ADMIN],
        },
        settings_specialties: {
            read: [RoleEnum.ROLE_SUPER_ADMIN],
            write: [RoleEnum.ROLE_SUPER_ADMIN],
        },
        settings_specialty: {
            read: [RoleEnum.ROLE_SUPER_ADMIN],
            write: [RoleEnum.ROLE_SUPER_ADMIN],
        },
        settings_specialty_create: {
            read: [RoleEnum.ROLE_SUPER_ADMIN],
            write: [RoleEnum.ROLE_SUPER_ADMIN],
        },
        settings_categories: {
            read: [RoleEnum.ROLE_SUPER_ADMIN],
            write: [RoleEnum.ROLE_SUPER_ADMIN],
        },
        settings_category: {
            read: [RoleEnum.ROLE_SUPER_ADMIN],
            write: [RoleEnum.ROLE_SUPER_ADMIN],
        },
        settings_category_create: {
            read: [RoleEnum.ROLE_SUPER_ADMIN],
            write: [RoleEnum.ROLE_SUPER_ADMIN],
        },
        settings_colors: {
            read: [RoleEnum.ROLE_SUPER_ADMIN],
            write: [RoleEnum.ROLE_SUPER_ADMIN],
        },
        settings_currencies: {
            read: [RoleEnum.ROLE_SUPER_ADMIN],
            write: [RoleEnum.ROLE_SUPER_ADMIN],
        },
        settings_import: {
            read: [RoleEnum.ROLE_SUPER_ADMIN],
            write: [RoleEnum.ROLE_SUPER_ADMIN],
        },
        lot: {
            read: [
                RoleEnum.ROLE_SUPER_ADMIN,
                RoleEnum.ROLE_SELLER_AUCTION_HOUSE_ADMIN,
                RoleEnum.ROLE_SELLER_AUCTION_HOUSE_OPERATOR,
                RoleEnum.ROLE_SELLER_GALLERY,
                RoleEnum.ROLE_SELLER_PAINTER,
            ],
            write: [
                RoleEnum.ROLE_SUPER_ADMIN,
                RoleEnum.ROLE_SELLER_AUCTION_HOUSE_ADMIN,
                RoleEnum.ROLE_SELLER_GALLERY,
                RoleEnum.ROLE_SELLER_PAINTER,
            ],
        },
        lot_bids: {
            read: [
                RoleEnum.ROLE_SUPER_ADMIN,
                RoleEnum.ROLE_SELLER_AUCTION_HOUSE_ADMIN,
                RoleEnum.ROLE_SELLER_AUCTION_HOUSE_OPERATOR,
                RoleEnum.ROLE_SELLER_PAINTER,
            ],
            write: [
                RoleEnum.ROLE_SUPER_ADMIN,
                RoleEnum.ROLE_SELLER_AUCTION_HOUSE_ADMIN,
                RoleEnum.ROLE_SELLER_AUCTION_HOUSE_OPERATOR,
                RoleEnum.ROLE_SELLER_PAINTER,
            ],
        },
        seo: {
            read: [RoleEnum.ROLE_SUPER_ADMIN],
            write: [RoleEnum.ROLE_SUPER_ADMIN],
        },
        mailing: {
            read: [RoleEnum.ROLE_SUPER_ADMIN],
            write: [RoleEnum.ROLE_SUPER_ADMIN],
        },
        mailing_edit: {
            read: [RoleEnum.ROLE_SUPER_ADMIN],
            write: [RoleEnum.ROLE_SUPER_ADMIN],
        },
        tariffs: {
            read: [RoleEnum.ROLE_SUPER_ADMIN],
            write: [RoleEnum.ROLE_SUPER_ADMIN],
        },
        tariffs_edit: {
            read: [RoleEnum.ROLE_SUPER_ADMIN],
            write: [RoleEnum.ROLE_SUPER_ADMIN],
        },
        lot_create: {
            read: [
                RoleEnum.ROLE_SUPER_ADMIN,
                RoleEnum.ROLE_SELLER_AUCTION_HOUSE_ADMIN,
                RoleEnum.ROLE_SELLER_GALLERY,
                RoleEnum.ROLE_SELLER_PAINTER,
            ],
            write: [
                RoleEnum.ROLE_SUPER_ADMIN,
                RoleEnum.ROLE_SELLER_AUCTION_HOUSE_ADMIN,
                RoleEnum.ROLE_SELLER_GALLERY,
                RoleEnum.ROLE_SELLER_PAINTER,
            ],
        },
        not_found: {
            read: [
                RoleEnum.ROLE_SUPER_ADMIN,
                RoleEnum.ROLE_SELLER_AUCTION_HOUSE_ADMIN,
                RoleEnum.ROLE_SELLER_AUCTION_HOUSE_OPERATOR,
                RoleEnum.ROLE_SELLER_GALLERY,
                RoleEnum.ROLE_SELLER_PAINTER,
            ],
            write: [],
        },
    };

    constructor(module: RoleModule) {
        super(module);
    }
}
export default getModule(RoleModule);
