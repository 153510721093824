import { LanguageType } from './interfaces/localization';

export const API_URL = process.env.VUE_APP_API_URL;
export const TOKEN_NAME = 'artebido-token';
export const TEMP_TOKEN_NAME = 'artebido-temp-token';
export const WEBSOCKET_URL = process.env.VUE_APP_WEBSOCKET_URL as string;

export const numberRegExp = '(\\d\\s?)*';
export const floatRegExp = '\\d+\\.?([0-9]{0,2})?';
export const slugRegExp = '[a-z-]+';

export const languagesCodes: LanguageType[] = ['ru', 'en'];
export const languageIcons: Record<string, string> = {
    ru: '🇷🇺',
    en: '🇬🇧',
};

export const YesNoMap = new Map([
    [true, 'Да'],
    [false, 'Нет'],
    [undefined, '-'],
]);

export const SelectValueDefault = { id: 0, value: '' };