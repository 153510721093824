
import { Component, Vue } from 'vue-property-decorator';
import ResponseHandlerModule from '@/admin/store/modules/responseHandler';

@Component({})
export default class ResponseHandler extends Vue {
    get isShow() {
        return ResponseHandlerModule.isShow;
    }

    get messages() {
        return ResponseHandlerModule.messages;
    }

    get type() {
        return ResponseHandlerModule.type;
    }

    closeNotify() {
        this.$store.dispatch('responseHandler/hideNotify');
    }
}
