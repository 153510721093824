
/**
 * Домашняя страница.
 *
 */

import { Component, Vue } from 'vue-property-decorator';

import Layout from '@/admin/layouts/Default.vue';
import TableBlock from '@/components/data/Table/Table.vue';
import SelectCustom from '@/components/select/SelectCustom.vue';

@Component({ components: { Layout, TableBlock, SelectCustom } })
export default class HomePage extends Vue {}
