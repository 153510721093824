/**
 * Returns an array of id and value props
 *
 * @param {Array} list list to process
 * @returns {Array} processed list for Select entity
 */

import { HierarchyCategory } from '@/admin/api/category';
import { Input } from '../formFactory/input.interface';
import { InputSuggestionMultipleList } from '../formFactory/inputSuggestionMultiple.interface';
import { SelectItem } from '../formFactory/select.interface';

export function processListForSelect(list: any[], valueKey = 'name'): SelectItem[] {
    return list.map((item) => {
        return {
            id: item.id,
            value: item[valueKey],
        };
    });
}

export function processListForSuggestionsMultiple(
    list: any[],
    valueKey = 'name',
    selected = false,
): InputSuggestionMultipleList {
    return list.map((item) => {
        return {
            id: item.id,
            value: item[valueKey],
            selected,
        };
    });
}

export interface HierarchyCategorySelectItem {
    id: number;
    value: string;
    selected: boolean;
    children?: HierarchyCategorySelectItem[];
    disabled?: boolean;
}

export function processCategoryListForSuggestionsMultiple(
    list: HierarchyCategory[],
    valueKey = 'name',
    childrenKey = 'children',
): HierarchyCategorySelectItem[] {
    return list.map((item) => {
        return {
            id: item.id as number,
            value: item[valueKey] as string,
            selected: false,
            children: (item[childrenKey] as HierarchyCategory[])?.length
                ? processCategoryListForSuggestionsMultiple(item.categories, valueKey, childrenKey)
                : [],
        };
    });
}

export function getTreeCategoryListUpdated(
    list: HierarchyCategorySelectItem[],
    item: HierarchyCategorySelectItem,
): HierarchyCategorySelectItem[] {
    return list.map((category) => {
        if (category.id === item.id) {
            category.selected = item.selected;
        } else if (category.children?.length) {
            category.children = getTreeCategoryListUpdated(category.children, item);
        }

        return category;
    });
}

export function getTreeCategoryListSelected(list: HierarchyCategorySelectItem[]): HierarchyCategorySelectItem[] {
    return list.reduce((accum: HierarchyCategorySelectItem[], current) => {
        if (current.selected) {
            accum.push({ id: current.id, value: current.value, selected: current.selected });
        }

        if (current.children?.length) {
            accum = [...accum, ...getTreeCategoryListSelected(current?.children || [])];
        }

        return accum;
    }, []);
}

export function getTreeCategoryFullListSelected(list: any[], selectedId: number[]): HierarchyCategorySelectItem[] {
    return list.reduce((accum: any[], current) => {
        const isSelected = selectedId.find((item: number) => {
            return item === current.id;
        });

        accum.push({ id: current.id, value: current.name, selected: Boolean(isSelected), children: getTreeCategoryFullListSelected(current.categories, selectedId) });

        return accum;
    }, []);
}

export function getTreeCategoryListUnselected(
    list: HierarchyCategorySelectItem[],
    excludeList: number[],
): HierarchyCategorySelectItem[] {
    return list.reduce((accum: HierarchyCategorySelectItem[], current) => {
        if (current.children?.length) {
            current.children = getTreeCategoryListUnselected(current.children, excludeList);
        }

        const isInExcludeList = excludeList.includes(current.id);

        if (isInExcludeList && current.children?.length) {
            accum.push({ ...current, disabled: true });
        }

        if (!isInExcludeList) {
            accum.push(current);
        }

        return accum;
    }, []);
}

export function getContainsCategory(
    list: HierarchyCategorySelectItem[],
    category: SelectItem,
    lvl = 0,
): { contains: boolean; lvl: number } {
    for (const item of list) {
        if (item.id === category.id) {
            return { contains: true, lvl };
        }

        if (!item.children?.length) {
            continue;
        }

        const result = getContainsCategory(item.children, category, ++lvl);

        if (!result.contains) {
            continue;
        }

        return result;
    }

    return { contains: false, lvl };
}

/**
 * Get matching category with all its children
 * @param {Array} list categories hierarchy
 * @param {number} categoryId
 * @returns {object | null} matching category if found
 */
export function getFullCategory(
    list: HierarchyCategorySelectItem[],
    categoryId: number,
): HierarchyCategorySelectItem | null {
    for (const item of list) {
        if (item.id === categoryId) {
            return item;
        }

        if (!item.children?.length) {
            continue;
        }

        return getFullCategory(item.children, categoryId);
    }

    return null;
}

export function getValidatedPricesList(list: Input[]) {
    let errorsCount = 0;
    const prices = list.map((input) => {
        if (input.hidden) {
            input.error.class = '';

            return input;
        }

        if (!input.value && !input.hidden) {
            input.error.class = 'cp-error';
            errorsCount++;

            return input;
        }

        if (input.error.class.length) {
            errorsCount++;
        }

        return input;
    });

    return { prices, errorsCount };
}
