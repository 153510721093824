export default {
    props: {
        data: {
            type: Object,
        },
        items: {
            // type: Array,
            required: true,
            default() {
                return [
                    {
                        id: 0,
                        value: '',
                    },
                ];
            },
        },
        current: {
            type: Object,
            required: true,
            default() {
                return {
                    id: 1,
                    value: 'First',
                };
            },
        },
        errorHandler: {
            type: String,
        },
    },
    data() {
        return {
            visible: false,
            stateClass: '',
            classOpen: 'open',
        };
    },
    created() {
        document.body.addEventListener('click', this.documentClick);
    },
    updated() {
        document.body.addEventListener('click', this.documentClick);
    },
    destroyed() {
        document.body.addEventListener('click', this.documentClick);
    },
    methods: {
        documentClick(e) {
            const el = this.$refs.dropdownSelect;
            const target = e.target;

            if (this.visible === true) {
                if (el && el !== target && !el.contains(target)) {
                    this.closeState();
                }
            }
        },
        closeState() {
            this.visible = false;
            this.stateClass = '';
        },
        toggleSelect() {
            if (this.data.disabled) {
                this.stateClass = '';
                this.visible = false;

                return;
            }

            this.stateClass = this.stateClass === this.classOpen ? '' : this.classOpen;
            this.visible = !this.visible;
        },
    },
};
