/**
 * Guard check for auction house pages.
 *
 */

import { NavigationGuard, Route } from 'vue-router';

import LotEntity from '@/admin/store/auctions/lots/entity';

const isLotRelatedRouteName = (routeName: string | undefined | null): boolean => {
    return ['lot', 'lot_bids'].includes(routeName || '');
};

const initLotEdit = async (route: Route) => {
    await LotEntity.reset();
    await LotEntity.SET_CARD_ID(+route.params.lotId);
    await LotEntity.initForm({ sellerType: route.params.sellerType, ...route.query });

    LotEntity.initCardById(route.params.lotId);
};

const initLotCreate = async (route: Route) => {
    await LotEntity.reset();
    await LotEntity.initForm({ sellerType: route.params.sellerType, ...route.params });

    if (!Number.isNaN(+route.params.catalogId)) {
        LotEntity.setCatalogInfoById(+route.params.catalogId);
    }

    LotEntity.updateIsLoading(false);
};

const guard: NavigationGuard = async (to: Route, from: Route, next) => {
    if (
        to.name === 'lot' &&
        (!isLotRelatedRouteName(from.name) || (isLotRelatedRouteName(from.name) && !LotEntity.cardId))
    ) {
        initLotEdit(to);

        next();
    } else if (to.name === 'lot_create') {
        initLotCreate(to);

        next();
    } else if (isLotRelatedRouteName(String(from.name)) && !isLotRelatedRouteName(String(to.name))) {
        LotEntity.reset();
        next();
    } else {
        next();
    }
};

export default guard;
