import { makeGetRequest } from '@/api/helpers';
import API from '@/admin/config/api-routers';
import { CurrencyApiInterface } from '@/interfaces/catalog.interface';

/**
 * Get all currencies.
 *
 */
export const getCurrencyAll = async (): Promise<CurrencyApiInterface[]> => {
    const result = await makeGetRequest(API.currency.getAll);

    return result.data.result.table;
};
