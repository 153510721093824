import { WS } from '@/lib/utils/websockets';
import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '@/admin/store';

@Module({ dynamic: true, store, name: 'websockets', namespaced: true })
class WebsocketsModule extends VuexModule {
    ws: WS | null = null;
    token = '';
    initialLoad = false;

    @Mutation
    setWs(value: WS | null): void {
        this.ws = value;
    }

    @Mutation
    setToken(value: string): void {
        this.token = value;
    }

    @Mutation
    setInitial(value: boolean): void {
        this.initialLoad = value;
    }

    @Action({ rawError: true })
    changeWs(value: WS | null): void {
        this.context.commit('setWs', value);
    }

    @Action({ rawError: true })
    resetWs(): void {
        this.ws?.reset();
    }

    @Action({ rawError: true })
    changeToken(value: string): void {
        this.context.commit('setToken', value);
    }

    @Action({ rawError: true })
    changeInitial(value: boolean): void {
        this.context.commit('setInitial', value);
    }
}

export default getModule(WebsocketsModule);
