export const tabsNav = {
    bidding: {
        id: 'bidding',
        name: 'Торги',
        template: 'auction_bidding',
        pathName: 'auction_bidding',
        icon: 'book',
        iconSize: '16',
    },
    common: {
        id: 'common',
        name: 'Общие',
        template: 'common',
        pathName: 'auction',
        icon: 'book',
        iconSize: '16',
    },
    lots: {
        id: 'lots',
        name: 'Лоты',
        template: 'lots',
        pathName: 'auction_lots',
        icon: 'pencil',
        iconSize: '16',
        routeParams: {},
    },
};
