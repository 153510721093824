import API from '@/admin/config/api-routers';
import { makeGetRequest, makeFormRequest } from '@/api/helpers';

export const getGalleryAll = async () => {
    const result = await makeGetRequest(API.gallery.getAll);

    return result.data.result.table;
};

export const getGalleryList = async (pageCurrent: number, sort: string, filter: string, items: number | string) => {
    const result = await makeGetRequest(API.gallery.getList + `?page=${pageCurrent}&items=${items}&${sort}${filter}`);

    return result.data.result.table;
};

export const getGalleryCard = async (cardId: number | string) => {
    const result = await makeGetRequest(API.gallery.card + `${cardId}/`);

    return result.data.result.item;
};

export const editGalleryCard = async (cardId: number | string, data: any) => {
    const result = await makeFormRequest(API.gallery.card + `${cardId}/`, data);

    return result;
};

export const createGalleryCard = async (data: any) => {
    const result = await makeFormRequest(API.gallery.create, data);

    return result;
};
