/*
 *
 * */

import { ImageInput } from '@/lib/formFactory/imageInput.interface';
export default class ImageInputEntity {
    defaultValue: ImageInput;
    entityName: string;
    entity: any;

    constructor(name: string) {
        this.defaultValue = {
            key: name,
            type: 'text',
            title: '',
            placeholder: '',
            value: '',
            required: false,
            size: 0,
            file: null,
            preview: '',
            minWidth: 480,
            minHeight: 480,
            error: {
                class: '',
                message: '',
            },
            validation: {
                empty: 'Поле не заполнено',
            },
        };
        this.entityName = name;
        this.entity = { [this.entityName]: this.defaultValue };
    }

    setType(type: string) {
        this.entity[this.entityName]['type'] = type;
    }

    setTitle(title: string) {
        this.entity[this.entityName]['title'] = title;
    }

    setPlaceholder(placeholder: string) {
        this.entity[this.entityName]['placeholder'] = placeholder;
    }

    setRequired(required: boolean) {
        this.entity[this.entityName]['required'] = required;
    }

    setValidation(validation: any) {
        this.entity[this.entityName]['validation'] = validation;
    }

    setSize(size: number) {
        this.entity[this.entityName]['size'] = size;
    }

    setMinWidth(width: number) {
        this.entity[this.entityName]['minWidth'] = width;
    }

    setMinHeight(height: number) {
        this.entity[this.entityName]['minHeight'] = height;
    }

    setValue(value: string) {
        this.entity[this.entityName]['value'] = value;
    }
}
