export const modalParams = {
    terms: {
        isShow: false,
        title: 'Условия доставки и оплаты',
        editor: {
            title: '',
            value: '',
            key: '',
            action: '',
            error: {
                class: '',
                message: '',
            },
        },
    },
    description: {
        isShow: false,
        title: 'О галерее',
        editor: {
            title: '',
            value: '',
            key: '',
            action: '',
            error: {
                class: '',
                message: '',
            },
        },
    },
};
