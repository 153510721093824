/**
 * Guard check if user is authenticated.
 *
 */

import { NavigationGuard, Route } from 'vue-router';

import UserModule from '@/admin/store/user';
import RoleModule from '@/admin/store/role';
import { RoleEnum } from '../store/role';

const guard: NavigationGuard = (to: Route, _, next): void => {
    if (to?.meta?.requiresAuth && !UserModule.token) {
        next({ name: 'login' });
    } else if (to.name === 'login' && UserModule.token && UserModule.token?.length > 0) {
        next({ name: 'home' });
    } else if (to.path === '/' && UserModule.token && UserModule.token?.length > 0) {
        let accessMatchFound = false;
        const homeRouteNames = ['auctions', 'lots'];
        homeRouteNames.forEach((routeName) => {
            if (accessMatchFound) {
                return;
            }

            if (RoleModule.accessByRouterName[routeName as RoleEnum].read.includes(UserModule.role as RoleEnum)) {
                accessMatchFound = true;
                next({ name: routeName });
            }
        });

        if (!accessMatchFound) {
            next({ name: 'not_found' });
        }
    } else {
        next();
    }
};

export default guard;
