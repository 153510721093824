/**
 * Guard check for auction house pages.
 *
 */

import { NavigationGuard, Route } from 'vue-router';

import CatalogEntity from '@/admin/store/auctions/catalogsAuctions/entityCommon';

const AUCTION_ROUTES = ['auction', 'auction_lots', 'auction_bidding'];

const isAuctionRelatedRouteName = (routeName: string | undefined | null): boolean => {
    return Boolean(routeName && AUCTION_ROUTES.includes(routeName || ''));
};

const initEditAuctionCard = async (route: Route) => {
    await CatalogEntity.initForm();
    CatalogEntity.initCardById(Number(route.params.catalogId));
};

const initCreateAuctionCard = async (route: Route) => {
    CatalogEntity.reset();
    CatalogEntity.initForm();
    CatalogEntity.initAuctionHouse(route.params.auctionHouseId);
};

const guard: NavigationGuard = async (to: Route, from: Route, next) => {
    if (
        to.name === 'auction' &&
        (!isAuctionRelatedRouteName(from.name) || (isAuctionRelatedRouteName(from.name) && !CatalogEntity.cardId))
    ) {
        initEditAuctionCard(to);

        next();
    } else if (to.name === 'auction_house_catalog') {
        initEditAuctionCard(to);

        next();
    } else if (to.name === 'auction_create') {
        initCreateAuctionCard(to);

        next();
    } else if (isAuctionRelatedRouteName(from.name) && !isAuctionRelatedRouteName(to.name)) {
        CatalogEntity.reset();
        next();
    } else {
        next();
    }
};

export default guard;
