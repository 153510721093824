import { VuexModule, Module, getModule, Mutation, Action } from 'vuex-module-decorators';

import store from '@/admin/store';
import { CurrencyApiInterface } from '@/interfaces/catalog.interface';
import { getCurrencyAll } from '@/admin/api/currency';

@Module({ dynamic: true, name: 'CurrencyModule', store, namespaced: true })
class CurrencyModule extends VuexModule {
    currencies: CurrencyApiInterface[] = [];

    constructor(module: CurrencyModule) {
        super(module);
    }

    @Mutation
    SET_CURRENCIES(list: CurrencyApiInterface[]): void {
        this.currencies = list;
    }

    @Action({ rawError: true })
    async getCurrencies(): Promise<CurrencyApiInterface[]> {
        if (!this.currencies.length) {
            this.SET_CURRENCIES(await getCurrencyAll());
        }

        return this.currencies;
    }
}
export default getModule(CurrencyModule);
