import { getModule, Mutation, Action, Module } from 'vuex-module-decorators';
import EntityBaseModule from '../../entity';
import store from '@/admin/store';
import { tabsNav } from './entityTabs';

const MODULE_NAME = 'catalogsAuctionsEntity';

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class CatalogsAuctionsEntityModule extends EntityBaseModule {
    allTabs = tabsNav;
    tabsNav: any = {};

    constructor(module: CatalogsAuctionsEntityModule) {
        super(module);
    }

    @Mutation
    SET_TABS_NAV(list: { [index: string]: {} }) {
        this.tabsNav = list;
    }

    @Mutation
    SET_TABS_LOTS_ROUTE_PARAM(param: { auctionId: string }) {
        this.tabsNav.lots.routeParams = param;
    }

    @Action({ rawError: true })
    initTabsItems() {
        this.SET_TABS_NAV(tabsNav);
    }

    @Action({ rawError: true })
    updateTablsLotsParams(params: {}) {
        this.context.commit('SET_TABS_LOTS_ROUTE_PARAM', params);
    }
}
export default getModule(CatalogsAuctionsEntityModule);
