var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mr-table-main-block__wrapper"},[_c('div',{ref:"scrollbar_bottom",staticClass:"mr-table-block__wrapper"},[_c('table',{ref:"table",staticClass:"mr-table__wrapper"},[_c('thead',{staticClass:"mr-table-titles__wrapper"},[_c('tr',_vm._l((_vm.titles),function(title){return _c('th',{key:title.id,staticClass:"mr-table-title-item",class:{ hidden: _vm.hiddenColumns.includes(title.id), small: title.id === 'id' }},[_c('div',{class:{ sort: _vm.sortFields.includes(title.id) }},[_c('span',[_vm._v(_vm._s(title.name))]),(_vm.sortFields.includes(title.id))?_c('div',{staticClass:"mr-table-sort__wrapper cp-table-sort__single",on:{"click":function($event){return _vm.toggleSort(title.id, title.sort)}}},[_c('span',{staticClass:"mr-table-sort__item",class:{
                                        'sort-asc': title.sort === 'ASC',
                                        'sort-desc': title.sort === 'DESC',
                                    }})]):_vm._e()])])}),0)]),_c('tbody',_vm._l((_vm.rows),function(row){return _c('tr',{key:row.id,class:{ 'not-active': row.is_active_row === false }},_vm._l((row),function(value,id){return _c('td',{key:id,class:{
                            'actions-wrapper': id === '_actions',
                            hidden: _vm.hiddenColumns.includes(id),
                            small: id === 'id',
                        }},[((id === 'name' || id === 'title' || id === 'fullName') && _vm.routeName)?_vm._t("default",function(){return [_c('router-link',{staticClass:"cp-table__item-link",attrs:{"to":{ name: _vm.routeName, params: { [_vm.routeParam]: row.id } }},domProps:{"innerHTML":_vm._s(value)}})]}):(id === 'name' || id === 'title' || id === 'fullName')?_vm._t("default",function(){return [_c('span',{staticClass:"cp-table__item-link",domProps:{"innerHTML":_vm._s(value)},on:{"click":function($event){return _vm.actionHandler('edit', row.id)}}})]}):(
                                _vm.actionsHandler.additional && Object.keys(_vm.actionsHandler.additional).includes(id)
                            )?_vm._t("default",function(){return [_c('span',{class:{ 'cp-cursor-pointer': value },on:{"click":function($event){return _vm.actionHandler(id, row.id)}}},[(value)?_vm._t("default",function(){return [_c('span',{domProps:{"innerHTML":_vm._s(value)}})]}):_vm._t("default",function(){return [_vm._v(" - ")]})],2)]}):(id === '_actions')?_vm._t("default",function(){return _vm._l((value),function(name){return _c('span',{key:name,staticClass:"mr-admin-actions-icons",class:name,on:{"click":function($event){return _vm.actionHandler(name, row.id)}}},[(name === 'delete')?_c('font-awesome-icon',{attrs:{"icon":['far', 'trash-alt']}}):_vm._e()],1)})}):_vm._t("default",function(){return [(value !== null)?_vm._t("default",function(){return [_c('span',{domProps:{"innerHTML":_vm._s(value)}})]}):_vm._t("default",function(){return [_vm._v(" - ")]})]})],2)}),0)}),0)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }