/**
 * Guard check if user has access rights.
 *
 */

import { NavigationGuard, Route } from 'vue-router';

import RoleModule, { RoleEnum } from '@/admin/store/role';
import UserModule from '@/admin/store/user';

const guard: NavigationGuard = (to: Route, _, next): void => {
    if (!to?.meta?.requiresAuth) {
        next();
    } else if (!RoleModule.accessByRouterName[to.name as RoleEnum].read.includes(UserModule.role as RoleEnum)) {
        next({ name: 'not_found' });
    } else {
        next();
    }
};

export default guard;
