import Vue from 'vue';

export default Vue.extend({
    props: {
        titles: {
            type: Array,
            default() {
                return [
                    {
                        id: 'id',
                        name: 'id',
                        sort: 'desc',
                        visible: true,
                    },
                    {
                        id: 'name',
                        name: 'ФИО',
                        // sort: 'desc',
                        visible: true,
                    },
                    {
                        id: 'email',
                        name: 'E-mail',
                        visible: true,
                    },
                ];
            },
        },
        hiddenColumns: {
            type: Array,
            default() {
                return [];
            },
        },
        sortFields: {
            type: Array,
            default() {
                return ['id'];
            },
        },
        rows: {
            type: Array,
            default() {
                return [
                    {
                        id: 1,
                        name: 'John Snow',
                        email: 'john@wall.com',
                    },
                    {
                        id: 2,
                        name: 'Jamie Lannister',
                        email: 'jamie@lannister.com',
                    },
                ];
            },
        },
        actionsHandler: {
            type: Object,
            default() {
                return {
                    edit: 'edit',
                    delete: 'delete',
                    sort: 'sort',
                };
            },
        },
        routeName: {
            type: String,
        },
        routeParam: {
            type: String,
        },
    },
    data() {
        return {
            touch: null,
            isExportOpen: false,
            isShowDetails: false,
            detailsId: null,
        };
    },
    mounted() {
        // this.initScroll();
    },
    updated() {
        // this.initScroll();
    },
    methods: {
        initScroll() {
            if (this.$refs.table) {
                const tableWidth = (this.$refs.table as HTMLElement).offsetWidth;
                (this.$refs.scrollbar_top_inner as HTMLElement).style.setProperty('width', tableWidth + 'px');
            }
        },
        actionHandler(name: string, id: string) {
            this.$emit('click', { action: name, id });
        },
        toggleSort(id: string, sort: string) {
            this.$emit('click', { action: 'sort', field: id, sort });
        },
        scrollHandler(e: any) {
            (this.$refs.scrollbar_bottom as HTMLElement).scrollLeft = e.target.scrollLeft;
        },
    },
});
