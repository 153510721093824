import responseHandler from '@/admin/store/modules/responseHandler';
import moment from 'moment';

/**
 *
 * @param str string
 */
export function capitaize(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

/**
 *
 * @param message string
 */
export function showError(message: string | string[]): void {
    responseHandler.showNotify({ message, type: 'fail' });
}

/**
 *
 * @param message string
 */
export function showMessage(message: string | string[]): void {
    responseHandler.showNotify({ message, type: 'ok' });
}

/**
 * @param {Map} map instance
 * @param {string} value to look for
 * @returns {any} value of map key
 */
export function getMapKeyByValue(map: Map<any, string>, niddle: string): any {
    for (const [key, value] of map.entries()) {
        if (value !== niddle) {
            continue;
        }

        return key;
    }
}

/**
 * @param {Map} map instance
 * @param {string} value to look for
 * @returns {boolean} shows if entry with specified value is present in map
 */
export function getMapHasByValue(map: Map<any, string>, niddle: string): boolean {
    const match = Array.from(map.entries()).find((entry) => entry[1] === niddle);

    return match ? true : false;
}

/**
 * @param {string} token
 * @returns {object} parsed token string object
 */

export function parseJwt(token: string): Record<string, number> {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
        atob(base64)
            .split('')
            .map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join(''),
    );

    return JSON.parse(jsonPayload);
}

/**
 * Returns +9 999 999 99 99
 *
 * @param {string} phoneNumber string for formatting 99999999999
 * @returns {string} phoneNumber
 */

export function formatPhone(phoneNumber: string): string {
    if (!phoneNumber) {
        return '';
    }

    return phoneNumber.replace(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/, '+$1 $2 $3 $4 $5');
}

/**
 * Returns 99999999999
 *
 * @param {string} phoneNumber string for clear  +9 999 999 99 99
 * @returns {string} phoneNumber
 */
export function clearPhone(phoneNumber: string): string {
    if (!phoneNumber) {
        return '';
    }

    phoneNumber = phoneNumber.replace(/^(0|\D)+/g, '');
    phoneNumber = phoneNumber.replace(/[-_()]/, '');
    phoneNumber = phoneNumber.replace(/^8/, '7');

    return phoneNumber.replace(/\D/g, '');
}

export function isDateStartOverDateEnd(dateStart: string, dateEnd: string, separator = '/'): boolean {
    const dataEndValues = dateEnd.split(separator);
    const dataEndTimestamp =
        new Date(dataEndValues[1] + '/' + dataEndValues[0] + '/' + dataEndValues[2]).getTime() / 1000;

    const dateStartValues = dateStart.split(separator);
    const dateStartTimestamp =
        new Date(dateStartValues[1] + '/' + dateStartValues[0] + '/' + dateStartValues[2]).getTime() / 1000;

    return dateStartTimestamp >= dataEndTimestamp;
}

export async function imageUrlToFile(url: string): Promise<File> {
    const response = await fetch(url);
    const blob = await response.blob();

    return new File([blob], 'img.png', { type: blob.type });
}

export async function imageToFile(file: File | string): Promise<File | null> {
    if (!file) {
        return null;
    }

    const result = typeof file === 'string' ? await imageUrlToFile(file as string) : file;

    return result;
}

export function imageFileToBase64(file: File): Promise<string | ArrayBuffer | null> {
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onloadend = () => {
            resolve(reader.result);
        };
    });
}

export function formatCreatedAt(date: string): string {
    return moment(date).format('DD.MM.YYYY HH:mm');
}

export function formatDateToDefault(date: string, format: string): string {
    return moment(date, format).format();
}

export function formatDefaultDateTo(date: string, format: string): string {
    return moment(date).format(format);
}

export function formatDateNowDays(date: string): string {
    if (!date) {
        return '';
    }

    const fromNow = moment(date).fromNow();
    const diff = moment().diff(moment(date), 'days');

    if (fromNow.includes('день') || fromNow.includes('дня') || fromNow.includes('дне') || fromNow.includes('сек') || fromNow.includes('час') || fromNow.includes('мин')) {
        return fromNow;
    }

    return `${fromNow} или ${diff} д. назад`;
}

export function formatNumberSpaced(value: number | string, toFixed?: number): string {
    if (!['string', 'number'].includes(typeof value)) {
        return '';
    }

    let hasDecimal = false;
    let decimal = '';

    if (value === 0) {
        return '0.00';
    }

    if (typeof value === 'number' && !Number.isInteger(value)) {
        value = value.toFixed(toFixed || 2).toString();
        value = value.toString();
    }

    if (typeof value === 'number') {
        value = value.toString();
    }

    if (value.indexOf('.') > 0) {
        hasDecimal = true;
        [value, decimal] = value.split('.');
    }

    value = formatRemoveSpaces(value).replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

    if (hasDecimal) {
        value = [value, decimal].join('.');
    }

    if (value === '-0.00') {
        value = '0.00';
    }

    return value;
}

export function formatRemoveSpaces(value: string): string {
    if (typeof value !== 'string') {
        return '';
    }

    return value.replace(/\s/g, '');
}

/**
 * Returns sort string sort[0][id]=<id>&sort[0][value]=<value>&...
 *
 * @param {string}
 * @returns {string}
 */
export function generateSort(params: { id: string; value: string } | { id: string; value: string }[]): string {
    if (Array.isArray(params)) {
        return params.reduce((sortString: string, current: { id: string; value: string }, index: number) => {
            return sortString + `sort[${index}][id]=${current.id}&sort[${index}][value]=${current.value}&`;
        }, '');
    }

    if (params.id) {
        return `sort[0][id]=${params.id}&sort[0][value]=${params.value}&`;
    }

    return '';
}

/**
 * Returns filter string filter[0][id]=<id>&filter[0][value]=<value>&...
 *
 * @param {string}
 * @returns {string}
 */
export function generateFilter(params: { id: string; value: string } | { id: string; value: string }[]): string {
    if (Array.isArray(params)) {
        return params.reduce((filterString: string, current: { id: string; value: string }, index: number) => {
            return filterString + `filters[${index}][id]=${current.id}&filters[${index}][value]=${current.value}&`;
        }, '');
    }

    if (params.id) {
        return `filters[0][id]=${params.id}&filters[0][value]=${params.value}&`;
    }

    return '';
}

/**
 * Returns truncated string
 *
 * @param {string} value
 * @param {number} length
 * @returns {string}
 */
export function truncateString(value: string, length: number): string {
    return value.length > length ? `${value.substring(0, length + 1)}...` : value;
}

export interface LotErrorInterface {
    error: string;
    lotId: string;
}

export function getApiErrorMessage(error: any): string | string[] {
    const defaultMessage = 'Неизвестная ошибка';
    let messages: string[] = [];

    if (typeof error === 'string') {
        return error;
    }

    if (typeof error?.response === 'string') {
        return error.response;
    }

    if (error?.response?.data?.message) {
        return error.response.data.message;
    }

    if (error?.response?.data?.errors?.fields) {
        const fields = error.response.data.errors.fields;
        messages = Object.keys(fields).map((key) => {
            return `${key}: ${fields[key]}`;
        });

        return messages.length ? messages : defaultMessage;
    }

    if (error?.response?.data?.errors) {
        messages = error.response.data.errors.map((error: LotErrorInterface) => `${error.lotId}: ${error.error}`);

        return messages.length ? messages : defaultMessage;
    }

    console.warn('Не удалось обработать ошибку', error.response || error);

    return defaultMessage;
}

/**
 * Returns formatted & converted number of bytes string
 *
 * @param {number} bytes
 * @param {format} string
 * @returns {object}
 */
export function convertBytes(bytes: number): { KB: number; MB: number } {
    const result = {
        KB: 0,
        MB: 0,
    };

    result.KB = Math.round(bytes / 1024);
    result.MB = Math.round(result.KB / 1024);

    return result;
}

/**
 * Returns declinated word string
 *
 * @param {number} n
 * @param {Array<string>} text_forms
 * @returns {string}
 */
export function declinateWord(n: number, text_forms: string[]): string {
    n = Math.abs(n) % 100;
    const n1 = n % 10;

    if (n > 10 && n < 20) {
        return text_forms[2];
    }
    if (n1 > 1 && n1 < 5) {
        return text_forms[1];
    }
    if (n1 == 1) {
        return text_forms[0];
    }

    return text_forms[2];
}

/**
 * Returns formatted relative url
 *
 * @param {string} value
 * @returns {string}
 */
export function formatToRelativeUrl(value: string): string {
    if (!value.length) {
        return '';
    }

    try {
        const url = new URL(value);

        return url.pathname;
    } catch (error) {
        if (value.indexOf('/') !== 0) {
            return `/${value}`;
        }

        return value;
    }
}
