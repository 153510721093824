/**
 * Response Handler store.
 *
 */

import { Module, VuexModule, Action, getModule, Mutation } from 'vuex-module-decorators';
import store from '@/admin/store';

export const MODULE_NAME = 'responseHandler';

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class ResponseHandlerModule extends VuexModule {
    isShow = false;
    messages: string[] = [];
    type = '';

    @Mutation
    SET_ERROR(message: string | string[]) {
        if (Array.isArray(message)) {
            this.messages = message;

            return;
        }

        if (typeof message === 'string') {
            this.messages = [message];
        }
    }

    @Mutation
    SET_SHOW_STATE(payload: { isShow: boolean; type: string }) {
        this.isShow = payload.isShow;
        this.type = payload.type;
    }

    @Action({ rawError: true })
    showNotify(payload: { message: string | string[]; type: string }) {
        const that = this;
        this.context.commit('SET_ERROR', payload.message);
        this.context.commit('SET_SHOW_STATE', { isShow: true, type: payload.type });

        setTimeout(function () {
            that.context.dispatch('hideNotify');
        }, 4000);
    }

    @Action({ rawError: true })
    hideNotify() {
        this.context.commit('SET_ERROR', '');
        this.context.commit('SET_SHOW_STATE', { isShow: false, type: '' });
    }
}

export default getModule(ResponseHandlerModule);
