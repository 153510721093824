/**
 * Redirect Handler store.
 *
 */

import { Module, VuexModule, Action, getModule, Mutation } from 'vuex-module-decorators';
import store from '@/admin/store';
import VueRouter from 'vue-router';

export const MODULE_NAME = 'redirectHandler';

type UrlType = Record<string, any> | string;

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class RedirectHandlerModule extends VuexModule {
    url: UrlType | string | null = null;

    @Mutation
    SET_URL(value: UrlType) {
        this.url = value;
    }

    @Action({ rawError: true })
    changeUrl(value?: UrlType) {
        if (!value) {
            return;
        }

        this.context.commit('SET_URL', value);
    }

    @Action({ rawError: true })
    redirect(router: VueRouter) {
        if (this.url) {
            router.push(this.url);
            this.context.commit('SET_URL', null);
        }
    }
}

export default getModule(RedirectHandlerModule);
