/*
 * default side menu
 * */
import { MenuItem } from '@/interfaces/menuItem.interface';
export const menuItems: Record<string, MenuItem> = {
    // home: {
    //     name: 'Главная',
    //     path: 'home',
    //     code: 'USER',
    //     isActive: true,
    //     class: 'in',
    //     hint: '',
    //     hintClass: '',
    //     iconSize: '18',
    // },
    auctions: {
        name: 'Аукционы',
        path: 'auctions',
        code: 'auctions',
        isActive: true,
        class: 'bank',
        hint: '',
        hintClass: '',
        iconSize: '18',
    },
    finance: {
        name: 'Финансы',
        path: 'finance',
        code: 'finance',
        isActive: true,
        class: 'coin',
        hint: '',
        hintClass: '',
        iconSize: '18',
    } as MenuItem,
    users: {
        name: 'Пользователи',
        path: 'users',
        code: 'users',
        isActive: true,
        class: 'users',
        hint: '',
        hintClass: '',
        iconSize: '18',
    },
    auctionHouses: {
        name: 'Продавцы',
        path: 'auction_houses',
        code: 'sellers',
        isActive: true,
        class: 'store',
        hint: '',
        hintClass: '',
        iconSize: '18',
    },
    seller: {
        name: 'Профиль',
        path: 'seller',
        code: 'seller',
        isActive: true,
        class: 'store',
        hint: '',
        hintClass: '',
        iconSize: '18',
    },
    incomingRequests: {
        name: 'Входящие запросы',
        path: 'questions',
        code: 'questions',
        isActive: true,
        class: 'in',
        hint: '',
        hintClass: '',
        iconSize: '18',
    },
    content: {
        name: 'Контент',
        path: 'content',
        code: 'content',
        isActive: true,
        class: 'tax',
        hint: '',
        hintClass: '',
        iconSize: '18',
    },
    seo: {
        name: 'SEO',
        path: 'seo',
        code: 'seo',
        isActive: true,
        class: 'book',
        hint: '',
        hintClass: '',
        iconSize: '18',
    },
    mailing: {
        name: 'Рассылки',
        path: 'mailing',
        code: 'mailing',
        isActive: true,
        class: 'email',
        hint: '',
        hintClass: '',
        iconSize: '18',
    },
    tariffs: {
        name: 'Тарифные планы',
        path: 'tariffs',
        code: 'tariffs',
        isActive: true,
        class: 'payments',
        hint: '',
        hintClass: '',
        iconSize: '18',
    },
    settings: {
        name: 'Настройки',
        path: 'settings_auctions',
        code: 'settings',
        isActive: true,
        class: 'settings',
        hint: '',
        hintClass: '',
        iconSize: '18',
    },
};
