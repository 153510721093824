/*
 *
 * */

import { ImageCollectionInterface } from './imageCollection.interface';
export default class ImageInputEntity {
    defaultValue: ImageCollectionInterface;
    entityName: string;
    entity: any;

    constructor(name: string) {
        this.defaultValue = {
            title: '',
            list: [],
            minWidth: 480,
            minHeight: 480,
            validation: {
                empty: 'Изображение не выбрано',
            },
            error: {
                class: '',
                message: '',
            },
            required: false,
        };
        this.entityName = name;
        this.entity = { [this.entityName]: this.defaultValue };
    }

    setTitle(title: string): void {
        this.entity[this.entityName]['title'] = title;
    }

    setRequired(bool: boolean): void {
        this.entity[this.entityName]['required'] = bool;
    }
}
