import { makeDeleteRequest, makeFormRequest, makeGetRequest, makePostRequest } from '@/api/helpers';
import API from '@/admin/config/api-routers';
import { TableApiInterface } from '@/lib/layouts/page/table.interface';
import { LotApiInterface } from '@/interfaces/lot.interface';

export type LotsStatusesApiType = Array<LotStatusItemInterface>;

interface LotStatusItemInterface {
    id: string;
    name: string;
}

/**
 * Get all lots.
 *
 */
export const getLotsAll = async () => {
    const result = await makeGetRequest(API.lots.getAll);

    return result.data.result.table;
};

/**
 * Get lots list.
 *
 * @param currentPage
 * @param filter
 * @param sort
 * @param items – amount of requested items per page
 */
export const getLotsList = async (
    currentPage: string | number,
    sort = '',
    filter = '',
    items: number | string = 50,
): Promise<TableApiInterface> => {
    if (sort) {
        sort = `&${sort}`;
    }

    if (filter) {
        filter = `&${filter}`;
    }

    const result = await makeGetRequest(API.lots.getList + `?page=${currentPage}&items=${items}${sort}${filter}`);

    return result.data.result.table;
};

/**
 * Get lot card.
 *
 * @param cardId
 */
export const getLotCard = async (cardId: string | number): Promise<LotApiInterface> => {
    const result = await makeGetRequest(API.lots.card + `${cardId}/`);

    return result.data.result.item;
};

/**
 * Edit lot card.
 *
 * @param cardId
 * @param data
 */
export const editLotCard = async (cardId: number, data: any) => {
    const result = await makeFormRequest(API.lots.card + `${cardId}/`, data);

    return result.data.result.item;
};

/**
 * Create lot card.
 *
 * @param data
 */
export const createLotCard = async (data: any) => {
    const result = await makeFormRequest(API.lots.create, data);

    return result;
};

/**
 * Get order statuses.
 *
 */
export const getLotsStatuses = async (): Promise<LotsStatusesApiType> => {
    const result = await makeGetRequest(API.lots.statuses);

    return result.data.result.table;
};

/**
 * Update status of multiple lots
 * @param data
 */
export const updateLotsStatus = async (data: Record<string, string>): Promise<string> => {
    const result = await makeFormRequest(API.lots.setStatus, data);

    return result?.data?.result?.status;
};

/**
 *
 * @param id lot id
 */
export const withdrawLotFromSales = async (id: string): Promise<unknown> => {
    const result = await makePostRequest(`${API.lots.card}${id}/withdraw_from_sale`);

    return result?.data?.result?.status;
};

/**
 * Update status of multiple lots
 * @param params
 */
export const deleteMultipleLots = async (params: string): Promise<string> => {
    const result = await makeDeleteRequest(`${API.lots.deleteMultiple}?${params}`);

    return result?.data?.result?.status;
};

/**
 *
 * @param id lot id
 */
export const sendLotPublish = async (id: string): Promise<string> => {
    const result = await makePostRequest(`${API.lots.card}${id}/publication/`);

    return result?.data?.result?.status;
};
