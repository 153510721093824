/**
 * Guard check for auction house pages.
 *
 */

import { NavigationGuard, Route } from 'vue-router';

import auctionHouseEntity from '@/admin/store/sellers/auctionHouses/entity';

const AUCTION_HOUSE_ROUTES = [
    'auction_house',
    'auction_house_catalogs',
    'auction_house_catalog',
    'auction_house_lots',
    'auction_house_users',
    'auction_house_orders',
    'auction_house_balance',
    'auction_house_approved_users',
    'auction_house_approved_user',
    'auction_house_approved_user_requests',
];

const isAuctionHouseRelatedRouteName = (routeName: string | undefined | null): boolean => {
    return Boolean(routeName && AUCTION_HOUSE_ROUTES.includes(routeName || ''));
};

const initAuctionHouseEdit = async (route: Route) => {
    await auctionHouseEntity.reset();
    auctionHouseEntity.initCardById(route.params.auctionHouseId);
};

const initAuctionHouseCreate = async () => {
    await auctionHouseEntity.reset();
    await auctionHouseEntity.initForm(null);
    auctionHouseEntity.updateIsLoading(false);
};

const guard: NavigationGuard = async (to: Route, from: Route, next) => {
    if (
        to.name === 'auction_house' &&
        (!isAuctionHouseRelatedRouteName(from.name) || (isAuctionHouseRelatedRouteName(from.name) && !auctionHouseEntity.cardId))
    ) {
        initAuctionHouseEdit(to);

        next();
    } else if (to.name === 'auction_house_add') {
        initAuctionHouseCreate();

        next();
    } else if (isAuctionHouseRelatedRouteName(from.name) && !isAuctionHouseRelatedRouteName(to.name)) {
        auctionHouseEntity.reset();
        next();
    } else {
        next();
    }
};

export default guard;
