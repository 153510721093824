<template>
    <div class="mr-table-main-block__wrapper">
        <div ref="scrollbar_bottom" class="mr-table-block__wrapper">
            <table ref="table" class="mr-table__wrapper">
                <thead class="mr-table-titles__wrapper">
                    <tr>
                        <th
                            v-for="title in titles"
                            :key="title.id"
                            class="mr-table-title-item"
                            :class="{ hidden: hiddenColumns.includes(title.id), small: title.id === 'id' }"
                        >
                            <div :class="{ sort: sortFields.includes(title.id) }">
                                <span>{{ title.name }}</span>
                                <div
                                    v-if="sortFields.includes(title.id)"
                                    class="mr-table-sort__wrapper cp-table-sort__single"
                                    @click="toggleSort(title.id, title.sort)"
                                >
                                    <span
                                        class="mr-table-sort__item"
                                        :class="{
                                            'sort-asc': title.sort === 'ASC',
                                            'sort-desc': title.sort === 'DESC',
                                        }"
                                    ></span>
                                </div>
                            </div>
                        </th>
                    </tr>
                </thead>

                <tbody>
                    <tr v-for="row in rows" :key="row.id" :class="{ 'not-active': row.is_active_row === false }">
                        <td
                            v-for="(value, id) in row"
                            :key="id"
                            :class="{
                                'actions-wrapper': id === '_actions',
                                hidden: hiddenColumns.includes(id),
                                small: id === 'id',
                            }"
                        >
                            <slot v-if="(id === 'name' || id === 'title' || id === 'fullName') && routeName">
                                <router-link
                                    class="cp-table__item-link"
                                    :to="{ name: routeName, params: { [routeParam]: row.id } }"
                                    v-html="value"
                                ></router-link>
                            </slot>

                            <slot v-else-if="id === 'name' || id === 'title' || id === 'fullName'">
                                <span
                                    class="cp-table__item-link"
                                    @click="actionHandler('edit', row.id)"
                                    v-html="value"
                                ></span>
                            </slot>

                            <slot
                                v-else-if="
                                    actionsHandler.additional && Object.keys(actionsHandler.additional).includes(id)
                                "
                            >
                                <span :class="{ 'cp-cursor-pointer': value }" @click="actionHandler(id, row.id)">
                                    <slot v-if="value">
                                        <span v-html="value"></span>
                                    </slot>
                                    <slot v-else> - </slot>
                                </span>
                            </slot>

                            <slot v-else-if="id === '_actions'">
                                <span
                                    v-for="name in value"
                                    :key="name"
                                    class="mr-admin-actions-icons"
                                    :class="name"
                                    @click="actionHandler(name, row.id)"
                                >
                                    <font-awesome-icon v-if="name === 'delete'" :icon="['far', 'trash-alt']" />
                                </span>
                            </slot>

                            <slot v-else>
                                <slot v-if="value !== null">
                                    <span v-html="value"></span>
                                </slot>
                                <slot v-else> - </slot>
                            </slot>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script type="ts">

import { Component, Vue } from 'vue-property-decorator';
import tableMixin from './tableMixin';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';

library.add(faTrashAlt);

@Component({
    components: {
        FontAwesomeIcon
    },
    mixins: [tableMixin],
})
export default class Table extends Vue {

}
</script>

<style lang="scss">
@import '@/admin/assets/scss/components/table.scss';
</style>
