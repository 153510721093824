import { languagesCodes } from '@/constants';
import { LocalizationItem, Localization, LanguageType } from '@/interfaces/localization';
import { Input } from '../formFactory/input.interface';

type LocalizationFormData = Record<string, string>;

export const prepareLocalization = (field: Input, fieldName = 'name'): LocalizationFormData | null => {
    let data: LocalizationFormData = {};
    const localization = field.localization as LocalizationItem;

    if (Object.values(localization).filter((value) => value).length) {
        data = {};
        (Object.keys(localization) as LanguageType[]).forEach((lang) => {
            if (lang === 'ru') {
                return;
            }

            data[`localization[${lang}][${fieldName}]`] = localization[lang];
        });
    }

    return Object.values(data).length ? data : null;
};

export const processLocalizationFromBackend = (data: Record<string, any>, fieldName = 'name'): Localization => {
    const entries = languagesCodes
        .map((code) => {
            if (code === 'ru') {
                return [code, data[fieldName]];
            }

            if (!data.localization || !data.localization[code] || !data.localization[code][fieldName]) {
                return [code, ''];
            }

            return [code, data.localization[code][fieldName]];
        })
        .filter((entry) => entry.length);

    return Object.fromEntries(entries);
};
