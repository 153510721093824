
import { Vue } from 'vue-property-decorator';

interface Styles {
    root: string;
}

export default class App extends Vue {
    $style!: Styles;
}
