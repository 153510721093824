import Vue from 'vue';
import Vuetify from 'vuetify';

Vue.use(Vuetify);

const opts = {
    theme: {
        themes: {
            light: {
                primary: '#FFC42B',
                secondary: '#26292B',
                accent: '#6E258B',
                error: '#FF4B3A',
                success: '#37AB00',
            },
        },
    },
};

export default new Vuetify(opts);
