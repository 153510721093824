import InputEntity from '@/lib/formFactory/inputEntity';
import SelectEntity from '@/lib/formFactory/selectEntity';
import ImageInputEntity from '@/lib/formFactory/imageInputEntity';
import InputSuggestionEntity from '@/lib/formFactory/inputSuggestionsEntity';
import CheckboxEntity from '@/lib/formFactory/checkboxEntity';
import { GalleryModelInterface } from '@/interfaces/models/gallery.interface';

export default class EntityModel {
    model: GalleryModelInterface;

    constructor() {
        const nameEntity = new InputEntity('name');
        nameEntity.setTitle('Название');
        nameEntity.setMask({
            regex: '[a-zA-ZА-Яа-я+\\s]+',
            placeholder: ' ',
            minChars: 1,
        });
        nameEntity.initLocalization();
        nameEntity.setRequired(true);

        const countryEntity = new InputSuggestionEntity('country');
        countryEntity.setTitle('Страна');

        const cityEntity = new InputSuggestionEntity('city');
        cityEntity.setTitle('Город');
        cityEntity.setRequired(true);

        const addressEntity = new InputEntity('address');
        addressEntity.setTitle('Адрес');
        addressEntity.setSize(512);
        addressEntity.initLocalization();

        const siteEntity = new InputEntity('site');
        siteEntity.setTitle('Сайт');
        siteEntity.setMask({
            placeholder: ' ',
            minChars: 1,
        });

        const phoneEntity = new InputEntity('phone');
        phoneEntity.setTitle('Телефон');
        phoneEntity.setMask({
            regex: '\\+[0-9]{1,18}',
            greedy: false,
            showMaskOnHover: false,
            placeholder: ' ',
            minChars: 0,
            maxChars: 18,
        });

        const emailEntity = new InputEntity('email');
        emailEntity.setTitle('Email');
        emailEntity.setMask({
            mask: '*{1,20}[.*{1,20}][.*{1,20}][.*{1,20}]@*{1,20}[.*{2,20}][.*{1,20}]',
            greedy: false,
            definitions: {
                '*': {
                    validator: "[0-9A-Za-z!#$%&'*+/=?^_`{|}~-]",
                    casing: 'lower',
                },
            },
        });

        const statusEntity = new SelectEntity('status');
        statusEntity.setTitle('Статус');

        const sortEntity = new InputEntity('sort');
        sortEntity.setTitle('Порядок сортировки');

        const slugEntity = new InputEntity('slug');
        slugEntity.setTitle('Slug');

        const siteCommissionEntity = new InputEntity('siteCommission');
        siteCommissionEntity.setTitle('Комиссия площадки с продажи');

        const photoEntity = new ImageInputEntity('photo');
        photoEntity.setMinHeight(480);
        photoEntity.setMinWidth(480);

        const topSellerEntity = new CheckboxEntity('topSeller');
        topSellerEntity.setList({
            0: {
                id: 'topSeller',
                name: 'Топ-продавец',
                checked: false,
            },
        });

        const descriptionEntity = new InputEntity('description');
        descriptionEntity.setSize(2000);
        descriptionEntity.initLocalization();

        const termsEntity = new InputEntity('terms');
        termsEntity.initLocalization();

        const billNameEntity = new InputEntity('billName');
        billNameEntity.setTitle('Юридическое наименование');
        const billAddressEntity = new InputEntity('billAddress');
        billAddressEntity.setTitle('Юридический адрес');
        const billPhoneEntity = new InputEntity('billPhone');
        billPhoneEntity.setTitle('Номер телефона');
        const billInnEntity = new InputEntity('billInn');
        billInnEntity.setTitle('ИНН');
        const billKppEntity = new InputEntity('billKpp');
        billKppEntity.setTitle('КПП');
        const billBankEntity = new InputEntity('billBank');
        billBankEntity.setTitle('Банк');
        const billBikEntity = new InputEntity('billBik');
        billBikEntity.setTitle('БИК');
        const billAccountEntity = new InputEntity('billAccount');
        billAccountEntity.setTitle('Расчетный счет');
        const billCorrespondentAccountEntity = new InputEntity('billCorrespondentAccount');
        billCorrespondentAccountEntity.setTitle('Корреспондентский счет');

        const billLogoEntity = new ImageInputEntity('billLogo');
        billLogoEntity.setTitle('Лого');
        billLogoEntity.setMinHeight(480);
        billLogoEntity.setMinWidth(480);

        this.model = {
            name: nameEntity.entity[nameEntity.entityName],
            country: countryEntity.entity[countryEntity.entityName],
            city: cityEntity.entity[cityEntity.entityName],
            address: addressEntity.entity[addressEntity.entityName],
            site: siteEntity.entity[siteEntity.entityName],
            phone: phoneEntity.entity[phoneEntity.entityName],
            email: emailEntity.entity[emailEntity.entityName],
            status: statusEntity.entity[statusEntity.entityName],
            sort: sortEntity.entity[sortEntity.entityName],
            siteCommission: siteCommissionEntity.entity[siteCommissionEntity.entityName],
            photo: photoEntity.entity[photoEntity.entityName],
            topSeller: topSellerEntity.entity[topSellerEntity.entityName],
            description: descriptionEntity.entity[descriptionEntity.entityName],
            terms: termsEntity.entity[termsEntity.entityName],
            slug: slugEntity.entity[slugEntity.entityName],
            balance: '',
            balanceDetails: {
                lotsPlaced: {
                    title: 'Размещено лотов в данный момент',
                    value: null,
                },
                catalogsPlaced: {
                    title: 'Размещено каталогов в данный момент',
                    value: null,
                },
                soldLots: {
                    title: 'Продано лотов',
                    value: null,
                },
                totalValueLots: {
                    title: 'Общая сумма с проданных лотов',
                    value: null,
                },
                avgReceivedFromLot: {
                    title: 'В среднем получено с одного лота',
                    value: null,
                },
                avgLotValue: {
                    title: 'Средняя стоимость лота',
                    value: null,
                },
            },
            billName: billNameEntity.entity[billNameEntity.entityName],
            billAddress: billAddressEntity.entity[billAddressEntity.entityName],
            billPhone: billPhoneEntity.entity[billPhoneEntity.entityName],
            billInn: billInnEntity.entity[billInnEntity.entityName],
            billKpp: billKppEntity.entity[billKppEntity.entityName],
            billBank: billBankEntity.entity[billBankEntity.entityName],
            billBik: billBikEntity.entity[billBikEntity.entityName],
            billAccount: billAccountEntity.entity[billAccountEntity.entityName],
            billCorrespondentAccount: billCorrespondentAccountEntity.entity[billCorrespondentAccountEntity.entityName],
            billLogo: billLogoEntity.entity[billLogoEntity.entityName],
        };
    }
}
