import { Module, Action, Mutation, getModule } from 'vuex-module-decorators';
import EntityBaseModule from '@/admin/store/entity';
import EntityModel from './entityModel';
import EntityStaticModel from './entityStaticModel';
import { modalParams } from './entityModalParams';

import store from '@/admin/store';
import { getPainterCard, editPainterCard, createPainterCard } from '@/admin/api/painter';
import ResponseHandlerModule from '@/admin/store/modules/responseHandler';
import { formatCreatedAt, getApiErrorMessage, imageFileToBase64, showError } from '@/lib/utils/Utils';
import RedirectHandlerModule from '@/admin/store/modules/redirectHandler';
import { getSettingAuction } from '@/admin/api/settingAuction';
import { SettingsPainterInterface } from '@/interfaces/settingsPainter';
import { getStatistics } from '@/admin/api/statistics';
import SiteModule from '@/admin/store/site';
import moment from 'moment';
import { prepareLocalization, processLocalizationFromBackend } from '@/lib/utils/localization';

export const MODULE_NAME = 'sellersPainterEntity';

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class SellersPainterEntityModule extends EntityBaseModule {
    cardId: number | null = null;
    model: any;
    staticModel: any;
    modalParams: any;
    painterName = '';
    titleReturnData = {
        edit: {
            icon: 'pencil',
            title: this.painterName,
        },
        create: {
            icon: '',
            title: 'Добавление Автора',
        },
    };
    selectDefault = { id: 0, value: '' };

    constructor(module: SellersPainterEntityModule) {
        super(module);

        const entityModel = new EntityModel();
        this.model = entityModel.model;
        this.modalParams = modalParams;
        this.staticModel = EntityStaticModel;
    }

    @Mutation
    SET_CARD_ID(id: number | null) {
        this.cardId = id;
    }

    @Mutation
    SET_PAINTER_NAME(name: string) {
        this.painterName = name;
        this.titleReturnData.edit.title = name;
    }

    @Mutation
    SET_NAME(name: string) {
        this.model.name.value = name;
    }

    @Mutation
    SET_EMAIL(email: string) {
        this.model.email.value = email;
    }

    @Mutation
    SET_SITE(site: string) {
        this.model.site.value = site;
    }

    @Mutation
    SET_SORT_ORDER(sort: string) {
        this.model.sort.value = sort;
    }

    @Mutation
    SET_SITE_COMMISSION(value: string) {
        this.model.siteCommission.value = value;
    }

    @Mutation
    SET_STATUS(selected: { id: string; value: string }) {
        this.model.status.current = selected;
    }

    @Mutation
    SET_MODAL_IS_SHOW(params: { key: string; bool: boolean }) {
        this.modalParams[params.key].isShow = params.bool;
    }

    @Mutation
    SET_PHOTO(url: string) {
        this.model.photo.value = url;
    }

    @Mutation
    SET_PHOTO_FILE(file: File) {
        this.model.photo.file = file;
    }

    @Mutation
    SET_PHOTO_PREVIEW(base64String: string) {
        this.model.photo.preview = base64String;
    }

    @Mutation
    SET_VK(value: string) {
        this.model.vk.value = value;
    }

    @Mutation
    SET_FACEBOOK(value: string) {
        this.model.facebook.value = value;
    }

    @Mutation
    SET_INSTAGRAM(value: string) {
        this.model.instagram.value = value;
    }

    @Mutation
    SET_PINTEREST(value: string) {
        this.model.pinterest.value = value;
    }

    @Mutation
    SET_DESCRIPTION(value: string) {
        this.model.description.value = value;
    }

    @Mutation
    SET_AVERAGE_COST_OF_SOLD(cost: string | number) {
        this.staticModel.averageCostOfSold.value = cost;
    }

    @Mutation
    SET_PURCHASED_COUNT(count: string | number) {
        this.staticModel.purchasedCount.value = count;
    }

    @Mutation
    SET_SOLD_COUNT(count: string | number) {
        this.staticModel.soldCount.value = count;
    }

    @Mutation
    SET_BALANCE(balance: number | null) {
        this.model.balance = typeof balance === 'number' ? `${balance} ₽` : balance;
    }

    @Action({ rawError: true })
    async initForm(painterId?: number) {
        if (Number.isInteger(painterId)) {
            return;
        }

        const settings: SettingsPainterInterface[] = await getSettingAuction('?section=CommonPainter');
        const platformComission = settings.filter((setting) => setting.name === 'platformCommission')[0];
        this.SET_SITE_COMMISSION(platformComission.value as string);
    }

    @Action({ rawError: true })
    async initCardNameById(id: number) {
        const result = await getPainterCard(id);
        this.context.commit('SET_PAINTER_NAME', result.name);
    }

    @Action({ rawError: true })
    async initCardById(painterId: number | string) {
        try {
            this.updateIsLoading(true);

            const fromDate = moment().subtract(6, 'months').format();
            const toDate = moment().format();
            const filter = `?filters[0][id]=fromDate&filters[0][value]=${fromDate}&filters[1][id]=toDate&filters[1][value]=${toDate}`;
            const promises = [getPainterCard(painterId), getStatistics('painter', +painterId, filter)];
            const [card, statistics] = await Promise.all(promises);

            await this.setInfo(card);
            this.setStatistics(statistics);

            this.updateIsLoading(false);
        } catch (error) {
            this.updateIsLoading(false);
            showError(getApiErrorMessage(error));
        }
    }

    @Action({ rawError: true })
    setStatistics(data: any) {
        this.context.commit('SET_PURCHASED_COUNT', data.lots_bought ? data.lots_bought : 0);
        this.context.commit('SET_SOLD_COUNT', data.lots_sold ? data.lots_sold : 0);
    }

    @Action({ rawError: true })
    setInfo(data: any) {
        if (!data) {
            return;
        }

        this.context.commit('SET_CARD_ID', data.id);
        this.context.commit('SET_PAINTER_NAME', data.name);
        this.context.commit('SET_SITE', data.site || '');
        this.context.commit('SET_EMAIL', data.email || '');
        this.context.commit('SET_SORT_ORDER', data.sort || '');
        this.context.commit('SET_SITE_COMMISSION', data.commissionSite || '');
        this.context.commit('SET_PHOTO', data.photo || '');

        this.context.commit('SET_VK', data.vk || '');
        this.context.commit('SET_FACEBOOK', data.facebook || '');
        this.context.commit('SET_INSTAGRAM', data.instagram || '');
        this.context.commit('SET_PINTEREST', data.pinterest || '');

        this.context.commit('SET_AVERAGE_COST_OF_SOLD', data.averageCostOfSold || 0);

        this.context.commit(
            'SET_CREATED_AT',
            data.createdByUser.createdAt ? formatCreatedAt(data.createdByUser.createdAt) : '',
        );

        if (data.city) {
            const country = data.city.country;
            this.updateSuggestionsSelectedByType({
                selected: country?.id ? { id: country.id, value: country.name } : this.selectDefault,
                type: 'country',
            });

            this.updateSuggestionsSelectedByType({
                selected: data.city?.id ? { id: data.city.id, value: data.city.name } : this.selectDefault,
                type: 'city',
            });
        }

        if (data.status) {
            this.SET_STATUS({ id: data.status, value: '' });
        }

        this.SET_BALANCE(data.balance);
        this.SET_AVG_LOT_VALUE(data.avgLotValue ?? 0);
        this.SET_AVG_RECEIVED_FROM_LOT(data.avgReceivedFromLot ?? 0);
        this.SET_CATALOGS_PLACED(data.catalogsPlaced ?? 0);
        this.SET_LOTS_PLACED(data.lotsPlaced ?? 0);
        this.SET_TOTAL_VALUE_LOTS(data.totalValueLots ?? 0);
        this.SET_SOLD_LOTS(data.soldLots ?? 0);

        this.SET_MODEL_FIELD_VALUE({ field: 'slug', value: data.slug || '' });

        this.SET_LOCALIZATION_BY_FIELD({
            fieldName: 'name',
            data: processLocalizationFromBackend(data),
        });

        this.SET_LOCALIZATION_BY_FIELD({
            fieldName: 'terms',
            data: processLocalizationFromBackend(data, 'terms'),
        });
    }

    @Action({ rawError: true })
    updateSite(site: string) {
        this.context.commit('SET_SITE', site);
    }

    @Action({ rawError: true })
    updateEmail(email: string) {
        this.context.commit('SET_EMAIL', email);
    }

    @Action({ rawError: true })
    updateSortOrder(sort: string) {
        this.context.commit('SET_SORT_ORDER', sort);
    }

    @Action({ rawError: true })
    updateSiteCommission(value: string) {
        this.context.commit('SET_SITE_COMMISSION', value);
    }

    @Action({ rawError: true })
    toggleModal(params: { key: string; bool: boolean }) {
        this.context.commit('SET_MODAL_IS_SHOW', params);
    }

    @Action({ rawError: true })
    async updatePhotoFile(file: File) {
        this.context.commit('SET_PHOTO_FILE', file);

        const preview = await imageFileToBase64(file);
        this.context.commit('SET_PHOTO_PREVIEW', preview);
    }

    @Action({ rawError: true })
    removeImagePreview() {
        this.context.commit('SET_PHOTO_FILE', null);
        this.context.commit('SET_PHOTO_PREVIEW', '');
    }

    @Action({ rawError: true })
    removeImage() {
        this.context.commit('SET_PHOTO', '');
    }

    @Action({ rawError: true })
    updateVk(value: string) {
        this.context.commit('SET_VK', value);
    }

    @Action({ rawError: true })
    updateFacebook(value: string) {
        this.context.commit('SET_FACEBOOK', value);
    }

    @Action({ rawError: true })
    updateInstagram(value: string) {
        this.context.commit('SET_INSTAGRAM', value);
    }

    @Action({ rawError: true })
    updatePinterest(value: string) {
        this.context.commit('SET_PINTEREST', value);
    }

    @Action({ rawError: true })
    async sendEdit() {
        const isError = await this.validate();

        if (isError === true) {
            ResponseHandlerModule.showNotify({ message: 'Заполните обязательные поля', type: 'fail' });

            return;
        }

        SiteModule.SET_IS_BLOCK(true);

        try {
            const formData = await this.prepareRequestData();
            let result: any;

            if (this.cardId != null) {
                result = await editPainterCard(this.cardId, formData);
            }

            if (!result.message) {
                ResponseHandlerModule.showNotify({ message: 'Изменения сохранены', type: 'ok' });

                SiteModule.SET_IS_BLOCK(false);

                this.SET_MODEL_IMAGE_FIELD_URL({ field: 'billLogo', url: result.data.result.item.billLogo });
                this.SET_MODEL_IMAGE_FIELD_FILE({ field: 'billLogo', file: null });
                this.SET_MODEL_IMAGE_FIELD_PREVIEW({ field: 'billLogo', base64String: '' });

                this.SET_MODEL_IMAGE_FIELD_URL({ field: 'photo', url: result.data.result.item.photo });
                this.SET_MODEL_IMAGE_FIELD_FILE({ field: 'photo', file: null });
                this.SET_MODEL_IMAGE_FIELD_PREVIEW({ field: 'photo', base64String: '' });
            } else {
                ResponseHandlerModule.showNotify({ message: result.message, type: 'fail' });
            }
        } catch (error) {
            ResponseHandlerModule.showNotify({ message: await getApiErrorMessage(error), type: 'fail' });
        }

        SiteModule.SET_IS_BLOCK(false);
    }

    @Action({ rawError: true })
    async sendAdd(redirect: any = null) {
        const isError = await this.validate();

        if (isError === true) {
            ResponseHandlerModule.showNotify({ message: 'Заполните обязательные поля', type: 'fail' });

            return;
        }

        SiteModule.SET_IS_BLOCK(true);

        try {
            const formData = await this.prepareRequestData();
            const result = (await createPainterCard(formData)) as any;

            if (!result.message) {
                ResponseHandlerModule.showNotify({ message: 'Изменения сохранены', type: 'ok' });
                RedirectHandlerModule.changeUrl(redirect);
            } else {
                ResponseHandlerModule.showNotify({ message: result.message, type: 'fail' });
            }
        } catch (error) {
            ResponseHandlerModule.showNotify({ message: await getApiErrorMessage(error), type: 'fail' });
        }

        SiteModule.SET_IS_BLOCK(false);
    }

    @Action({ rawError: true })
    prepareRequestData() {
        let data: Record<string, string | number> = {
            name: this.model.name.localization.ru,
            status: this.model.status.current.id,
            city: this.model.city.current.id,
            slug: this.model.slug.value,
        };

        data = {
            ...data,
            ...prepareLocalization(this.model.name),
            ...prepareLocalization(this.model.terms, 'terms'),
        };

        data['terms'] = this.model.terms.localization.ru;

        if (this.model.site.value) {
            data['site'] = this.model.site.value;
        }

        if (this.model.email.value) {
            data['email'] = this.model.email.value;
        }

        if (this.model.sort.value) {
            data['sort'] = +this.model.sort.value;
        }

        if (this.model.siteCommission.value) {
            data['commissionSite'] = Number(this.model.siteCommission.value);
        }

        const photo = this.model.photo.file ? this.model.photo.file : null;
        if (photo) {
            data['photo'] = photo;
        }

        if (this.model.vk.value) {
            data['vk'] = this.model.vk.value;
        }

        if (this.model.facebook.value) {
            data['facebook'] = this.model.facebook.value;
        }

        if (this.model.instagram.value) {
            data['instagram'] = this.model.instagram.value;
        }

        if (this.model.pinterest.value) {
            data['pinterest'] = this.model.pinterest.value;
        }

        return data;
    }

    @Action({ rawError: true })
    validate() {
        const requiredFields = ['name', 'city'];
        this.context.commit('RESET_ERROR_BY_FIELD', 'name');
        this.context.commit('RESET_ERROR_BY_FIELD', 'city');

        this.setValidateState({ index: 'name', field: this.model.name });
        this.setValidateSelect({ index: 'city', field: this.model.city });

        const fieldsWithError = requiredFields.filter((field) => this.model[field].error.class === 'cp-error');

        return fieldsWithError.length > 0 ? true : false;
    }

    @Action({ rawError: true })
    reset() {
        this.context.commit('SET_IS_LOADING', true);
        this.context.commit('SET_CARD_ID', null);
        this.context.commit('SET_SITE', '');
        this.context.commit('SET_EMAIL', '');
        this.context.commit('SET_SORT_ORDER', '');
        this.context.commit('SET_SITE_COMMISSION', '');
        this.context.commit('SET_STATUS', this.selectDefault);
        this.context.commit('SET_PHOTO', '');
        this.context.commit('SET_PHOTO_FILE', null);
        this.context.commit('SET_PHOTO_PREVIEW', '');

        this.resetSuggestionsStateByType('country');
        this.resetSuggestionsStateByType('city');

        this.context.commit('SET_VK', '');
        this.context.commit('SET_FACEBOOK', '');
        this.context.commit('SET_INSTAGRAM', '');
        this.context.commit('SET_PINTEREST', '');

        this.context.commit('SET_AVERAGE_COST_OF_SOLD', '');
        this.context.commit('SET_PURCHASED_COUNT', '');
        this.context.commit('SET_SOLD_COUNT', '');

        this.SET_BALANCE(null);
        this.SET_AVG_LOT_VALUE(null);
        this.SET_AVG_RECEIVED_FROM_LOT(null);
        this.SET_CATALOGS_PLACED(null);
        this.SET_LOTS_PLACED(null);
        this.SET_TOTAL_VALUE_LOTS(null);
        this.SET_SOLD_LOTS(null);

        this.RESET_LOCALIZATION_BY_FIELD();
        this.RESET_LOCALIZATION_BY_FIELD('terms');
        this.SET_MODEL_FIELD_VALUE({ field: 'slug', value: '' });

        SiteModule.SET_IS_BLOCK(false);

        this.resetErrorState();
    }

    @Action({ rawError: true })
    resetErrorState() {
        this.context.commit('RESET_ERROR_BY_FIELD', 'name');
        this.context.commit('RESET_ERROR_BY_FIELD', 'city');
    }

    @Action({ rawError: true })
    resetPainterName() {
        this.context.commit('SET_PAINTER_NAME', '');
    }
}

export default getModule(SellersPainterEntityModule);
