/*
 *
 * */

import { Radio, RadioItem } from '@/lib/formFactory/radio.interface';
export default class RadioEntity {
    defaultValue: Radio;
    entityName: string;
    entity: any;

    constructor(name: string) {
        this.defaultValue = {
            key: name,
            name: name,
            type: 'radio',
            title: '',
            value: '',
            list: [],
            required: false,
            disabled: false,
            error: {
                class: '',
                message: '',
            },
            validation: {
                empty: 'Значение не выбрано',
            },
        };
        this.entityName = name;
        this.entity = { [this.entityName]: this.defaultValue };
    }

    setTitle(title: string) {
        this.entity[this.entityName]['title'] = title;
    }

    setList(list: RadioItem[]) {
        this.entity[this.entityName]['list'] = list;
    }

    setRequired(required: boolean) {
        this.entity[this.entityName]['required'] = required;
    }

    setValue(value: string) {
        this.entity[this.entityName]['value'] = value;
    }
}
